import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import PostCommentCard from '../components/dashboard/PostCommentCard'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import DashboardBreadCrumbSkeleton from '../components/skeleton/DashboardBreadCrumbSkeleton'
import Assets from '../constants/images'
import { error } from '../constants/msg'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { GetComments } from '../services/comments/comments'
import { GetSinglePost } from '../services/posts/posts'

const PostDetailPage = () => {
    const [posterDetail, setPosterDetail] = useState(null)

    return (
        <>
            {
                posterDetail ?
                    <DashboardBreadCrumb link1="/home" link1Text="Home" link2={`/user/${posterDetail?.id}`} link2Text={posterDetail?.shopper ? posterDetail?.shopper?.full_name : posterDetail?.brand_owner?.brand_name} link3="#" link3Text="Post Details" link4="" link4Text="" />
                    :
                    <DashboardBreadCrumbSkeleton />
            }

            <div className="pages post-detail-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostDetailCard isScheduled={false} setPosterDetail={setPosterDetail} />
                        </Col>

                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostCommentCard />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PostDetailPage
