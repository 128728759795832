import React from 'react'
import PaymentManagementCard from '../components/dashboard/PaymentManagementCard'

const PaymentManagementPage = () => {
    return (
        <div className="pages payment-management-page h-100">
            <div className='d-flex align-items-center justify-content-center h-100'>
                <PaymentManagementCard />
            </div>
        </div>
    )
}

export default PaymentManagementPage