import React from 'react'
import Assets from '../../constants/images'
import { FaTimesCircle } from "react-icons/fa"
import { Container, Row, Col } from 'react-bootstrap'

const ViewImage = ({ viewImage, setViewImage }) => {
    const onCloseImage = () => {
        document.body.style.overflow = "auto"
        setViewImage({
            state: false,
            src: ""
        })
    }

    return (
        <div onClick={onCloseImage} className={`view-image ${viewImage.state ? "d-block" : "d-none"}`}>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col xs={12} sm={10}>
                        <div>
                            <div className='icon text-end cursor-pointer' onClick={onCloseImage}><FaTimesCircle /></div>
                            <img className='w-100' src={viewImage?.src} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ViewImage