import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import StatsCard from "../components/dashboard/StatsCard"
import { INSTANCE_TYPE, POPUP_TYPE, POSTS_STATUS, REPORT_INSTANCE_TYPE, STATUS } from '../constants/app-constants'
import Assets from '../constants/images'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import PostDetailCardAsList from '../components/dashboard/PostDetailCardAsList'
import { UserData, FilterDateContext } from "../App"
import { AddRemovePostFromFavourite, AddRemovePostReaction, GetSchedulePosts, SharePost } from '../services/posts/posts'
import { error, success } from '../constants/msg'
import moment from 'moment'
import DeletePostPopup from '../components/popup/DeletePostPopup'
import ReportPopup from '../components/popup/ReportPopup'
import PostDetailCardListWithFilter from '../components/dashboard/PostDetailCardListWithFilter'
import { ToastContainer } from 'react-toastify'
import SharePostPopup from '../components/popup/SharePostPopup'

const SchedulePostPage = () => {
  const navigate = useNavigate()
  const { user_slug, post_slug } = useParams()
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState([]);
  const [userData, setUserData] = useContext(UserData)
  const [filterDate, setFilterDate] = useContext(FilterDateContext)
  const [blockUserPopup, setBlockUserPopup] = useState(false)
  const [deletePostPopup, setDeletePostPopup] = useState(false)
  const [selectedPost, setSelectedPost] = useState()
  const [reportPopup, setReportPopup] = useState(false)
  const [moveToTop, setMoveToTop] = useState(false)
  const [filterNow, setFilterNow] = useState(false)
  const [selectedPostUser, setSelectedPostUser] = useState()
  const [sharePostPopup, setSharePostPopup] = useState(false)
  const [shareableLink, setShareableLink] = useState("")

  useEffect(() => {
    const getSchedulePosts = async () => {
      const date_from = filterDate.date_from
      const date_to = filterDate.date_to

      const params = {
        date_from: date_from ? date_from : moment().format('yyyy-MM-DD'),
        date_to: date_to ? date_to : moment().format('yyyy-MM-DD'),
        limit: 10,
        page: currentPage
      }

      if (currentPage > 1) {
        setIsLoadMore(true)
      }
      try {
        const res = await GetSchedulePosts(params)
        setTotalPage(res.data.data.last_page)
        setData(data.concat(res.data.data.data))
        setIsLoading(false)
        setIsLoadMore(false)
      }
      catch (e) {
        setIsLoading(false)
        setIsLoadMore(false)
        error(e.response.data.message)
      }
    }

    getSchedulePosts()

  }, [currentPage, filterNow])

  return (
    <>
      <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Schedule Post" link4="" link4Text="" popupType={POPUP_TYPE.month_filter} setSchedulePostPageData={setData} setSchedulePostPageCurrentPage={setCurrentPage} filterNow={filterNow} setFilterNow={setFilterNow} setIsLoading={setIsLoading} />
      <BlockUserPopup selectedPostUser={selectedPostUser} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
      <DeletePostPopup selectedPost={selectedPost} deletePostPopup={deletePostPopup} setDeletePostPopup={setDeletePostPopup} />
      <ReportPopup data={selectedPost} reportTo={REPORT_INSTANCE_TYPE.post} reportPopup={reportPopup} setReportPopup={setReportPopup} />
      <SharePostPopup sharePostPopup={sharePostPopup} setSharePostPopup={setSharePostPopup} shareableLink={shareableLink} setShareableLink={setShareableLink} />
      <ToastContainer />

      <div className='pages statistics-and-analytics-page'>
        <Container fluid className='mt-5'>
          {/* <PostDetailCardAsList isFromStats={true} isScheduled={true} activeTab={POSTS_STATUS.scheduled} link="/schedule-post" /> */}
          <PostDetailCardListWithFilter
            link="/schedule-post"
            isFromStats={false}
            isLoading={isLoading}
            data={data}
            setData={setData}
            setSelectedPostUser={setSelectedPostUser}
            setSelectedPost={setSelectedPost}
            setReportPopup={setReportPopup}
            setDeletePostPopup={setDeletePostPopup}
            setBlockUserPopup={setBlockUserPopup}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            isScheduled={true}
            isLoadMore={isLoadMore}
            sharePostPopup={sharePostPopup}
            setSharePostPopup={setSharePostPopup}
            shareableLink={shareableLink}
            setShareableLink={setShareableLink}
          />
        </Container>
      </div>
    </>
  )
}

export default SchedulePostPage