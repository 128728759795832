import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroller'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { ACTION_TYPES_POST, ACTION_TYPES_PROFILE } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { error } from '../../constants/msg'
import { Times } from '../../constants/svgs'
import { GetNotifications, MarkNotificationAsRead } from '../../services/notifications/notifications'
import NotificationSkeleton from '../skeleton/NotificationSkeleton'

const NotificationPopup = ({ notificationPopup, setNotificationPopup, setNotificationAlert, count, setCount }) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [notifications, setNotifications] = useState([])

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const getNotifications = async () => {
        // fetch notifications of user
        let counter = 0
        try {
            const params = {
                page: currentPage,
            }
            const res = await GetNotifications(params)
            let data = res.data.data.data
            setCurrentPage(currentPage + 1)
            setNotifications([...notifications, ...data])
            data.forEach((data) => {  // Map thorugh notification array and check if notification is not read than increment counter by 1
                if (!data?.read && data.from_user) {
                    counter += 1
                }
            })
            setCount(count + counter)
            setNotificationAlert(counter == 0 ? false : true)
            if (currentPage == res.data.data.last_page) {
                setHasMore(false)
            }
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const handleNotificationClick = async (id, index) => {
        setNotificationPopup(false)
        try {
            const temp = [...notifications]
            temp[index].read = true
            setNotifications(temp)
            await MarkNotificationAsRead(id)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const handleNavigate = (data) => {
        if (ACTION_TYPES_PROFILE.includes(data?.data?.action_type)) {
            return `/user/${data?.data?.sender_id}`
        }
        else if (ACTION_TYPES_POST.includes(data?.data?.action_type)) {
            return `/post/${data?.data?.ref_id}`
        }
        else {
            return `/user/${data?.data?.sender_id}`
        }
    }


    return (
        <Modal
            className='theme-popup notification-popup'
            show={notificationPopup}
            onHide={() => setNotificationPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex text-center mb-20 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>NOTIFICATIONS <span>({count})</span></h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setNotificationPopup(false)}>
                            <Times />
                        </div>
                    </div>
                    <div className='notification-container'>
                        <div className="notification-wrapper">
                            <InfiniteScroll
                                pageStart={currentPage}
                                threshold={200}
                                useWindow={false}
                                loadMore={getNotifications}
                                hasMore={hasMore}
                                loader={<div className=''><NotificationSkeleton /></div>}
                            >
                                {
                                    (notifications.length <= 0 && !isLoading) ?
                                        <div className='text-center mt-30'>
                                            <img width={50} height={50} src={Assets.NoNotificationImage} />
                                            <h6 className='mt-2'>No notification yet</h6>
                                        </div>
                                        :
                                        notifications?.map((data, index) => (
                                            <div key={index}>
                                                {
                                                    data?.from_user &&
                                                    <div key={index} className="box pb-3 mb-3 border-bottom">
                                                        <div className="d-flex">
                                                            <div className="profile-wrapper me-2">
                                                                <img src={data?.from_user?.brand_owner ? data?.from_user?.brand_owner?.image_url : data?.from_user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                                            </div>

                                                            <div className="custom-position text-end w-100">
                                                                <small>{data.time}</small>
                                                                <div className="text-start">
                                                                    <Link to={handleNavigate(data)} onClick={() => {
                                                                        if (!data?.read) {
                                                                            setCount(count - 1)
                                                                        }
                                                                        handleNotificationClick(data?.id, index)
                                                                    }}>
                                                                        <h6 className=''><span className={`${data?.read && "text-grey"}`}>{data?.data?.message}</span></h6>
                                                                    </Link>
                                                                    <div className={`btn-wrapper ${data.notification_type === "request" ? "d-block" : "d-none"}`}>
                                                                        <Button className="me-2 d-inline-block btn-solid green">CONFIRM</Button>
                                                                        <Button className="d-inline-block btn-solid red">DELETE</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                }
                            </InfiniteScroll>

                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NotificationPopup