import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const DashboardBreadCrumbSkeleton = () => {
    return (
        <div className="dashboard-breadcrumb">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <div style={{height:"40px"}} className="my-3 border-bottom"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DashboardBreadCrumbSkeleton