import React, { useState } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const BusinessHourPopup = ({ setTimeError, businessHoursPopup, setBusinessHoursPopup, startTime, setStartTime, endTime, setEndTime }) => {
    const navigate = useNavigate()

    const isDisabled = (startTime, endTime) => {
        // convert start and end time into timestamp than compare
        // also compare that the start and end time should not be empty

        return ((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime))
    }

    const handleDone = ()=>{
        if((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime)){
            setTimeError(true)
            setBusinessHoursPopup(false)
        }
        else{
            setTimeError(false)
            setBusinessHoursPopup(false)
        }
    }

    return (
        <Modal
            className='theme-popup business-hours-popup'
            show={businessHoursPopup}
            onHide={handleDone}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>BUSINESS HOURS</h3>
                    </div>

                    <div className='content-container d-sm-flex align-items-sm-center justify-content-sm-between'>
                        <div className='mb-20'>
                            <h3 className='mb-2'>OPENING TIME</h3>
                            <DatePicker
                                className='w-100'
                                selected={startTime}
                                onChange={date => setStartTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="OPENING TIME"
                                dateFormat="hh:mm aa"
                                placeholderText='1:00 PM'
                                onKeyDown={(e) => e.preventDefault()}
                                onFocus={e => e.target.blur()}
                            />
                        </div>

                        <div className='mb-20'>
                            <h3 className='mb-2'>CLOSING TIME</h3>
                            <DatePicker
                                className='w-100'
                                selected={endTime}
                                onChange={date => setEndTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="CLOSING TIME"
                                dateFormat="hh:mm aa"
                                placeholderText='2:00 PM'
                                onKeyDown={(e) => e.preventDefault()}
                                onFocus={e => e.target.blur()}
                            />
                        </div>
                    </div>

                    <div className="mt-20">
                        <Button onClick={handleDone} disabled={isDisabled(startTime, endTime)} className="w-100 btn-solid mb-2">Done</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BusinessHourPopup