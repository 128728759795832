import React, { useState, useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import DemographicsCardSkeleton from "../skeleton/DemographicsCardSkeleton";
import NotificationSkeleton from "../skeleton/NotificationSkeleton";
import WithDrawlCardSkeleton from "../skeleton/WithDrawlCardSkeleton";
import { Button, Col, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { UserData, UserRole } from "../../App";
import { roles, transactions } from '../../utils/constants'

import moment from 'moment'
import { GetTopUpTransactions } from "../../services/transaction/transaction";
import { error, success } from "../../constants/msg";

const WithdrawlCard = ({ setCurrentPage, isLoading, isLoadMore, hasMore, totalPage, currentPage, data }) => {
  return (
    <div className="withdrawl-card">
      <div className="wrapper">
        <div className="notification-wrapper">
          {/* <InfiniteScroll
            pageStart={currentPage}
            threshold={200}
            useWindow={false}
            // loadMore={getTopUpTransactions}
            hasMore={hasMore}
            loader={
              <div className="">
                <WithDrawlCardSkeleton />
              </div>
            }
          > */}
          {
            isLoading ?
              <DemographicsCardSkeleton />
              :
              data.length <= 0 ?
                <h6 className="text-center mt-2">No data available</h6>
                :
                data?.map((item, index) => (
                  <div key={index}>
                    <div className="box mb-20 pb-20">
                      {/* <small> Wed, March 1, 2023 in (6:00pm)</small> */}
                      <small>{moment.utc(item?.created_at).local().format("ddd, MMMM DD, YYYY in (hh:mma)")}</small>
                      <h5 className="ms-2">${item?.amount?.toFixed(1)}</h5>
                    </div>
                  </div>
                ))
          }

          {/* </InfiniteScroll> */}
        </div>
      </div>
      <Row>
        <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
          {
            isLoadMore ?
              <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
          }
        </Col>
      </Row>
    </div>
  );
};

export default WithdrawlCard;
