import React from 'react'
import { Form } from "react-bootstrap"
import { BsPlayCircleFill } from 'react-icons/bs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Assets from '../../constants/images'

const PostCard = ({ link, data, asGallery }) => {
    const CustomLinkComponent = () => {
        /*
            A component which return a component based on conditions
            Condition 1: If asGallery is true than we move/redirect user inside application 
            Condition 2: If asGallery is false than we move/redirect user outside our application 
            where original product lies
        */
        if (asGallery) {
            return <InternalLinkComponent />
        }
        else {
            return <ExternalLinkComponent />
        }
    }

    const InternalLinkComponent = () => {
        // Component which move user inside the application
        return (
            <Link to={link} className='w-100'>
                {
                    data?.attachments.length == 1 ?
                        <div className='img-wrapper'>
                            <img src={asGallery ? data?.attachments[0].attachment_url : data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                        </div>
                        :
                        <div className='img-wrapper'>
                            <div className='video-thumbnail w-100 h-100'>
                                <BsPlayCircleFill className="icon" />
                                <img src={asGallery ? data?.attachments[1].attachment_url : data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                            </div>
                        </div>
                }

            </Link>
        )
    }

    const ExternalLinkComponent = () => {
        // Component which move user outside the application. Move user to the original product url

        return (
            <a href={data?.product_url} target="_blank" className='w-100'>
                <div className='img-wrapper'>
                    <img src={asGallery ? data?.attachments[0].attachment_url : data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                </div>
            </a>
        )
    }

    return (
        <div className='post-card'>
            <Form.Check
                type="checkbox"
                label={<CustomLinkComponent />}
            />
        </div>
    )
}

export default PostCard