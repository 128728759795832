import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import UnblockAlertPopup from '../popup/UnblockAlertPopup'
import DemographicsCardSkeleton from '../skeleton/DemographicsCardSkeleton'
import { GetDemographics } from "../../services/demographics/demographics"
import InfiniteScroll from 'react-infinite-scroller'
import { error } from '../../constants/msg'
import { UserData } from '../../App'

const DemographicsCard = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [demographicsData, setDemographicsData] = useState([])
    const [userData, setUserData] = useContext(UserData)

    const getDemographicsData = async () => {
        // fetch demographic data 
        try {
            const params = {
                paginate: 1,
                page: currentPage,
                user_id: userData?.id
            }
            const res = await GetDemographics(params)
            setCurrentPage(currentPage + 1)
            setDemographicsData([...demographicsData, ...res.data.data.data])
            if (currentPage == res.data.data.last_page) {
                setHasMore(false)
            }
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    return (
        <div className="demographics-card mx-auto">
            <div className="box-wrapper">
                <InfiniteScroll
                    pageStart={currentPage}
                    threshold={200}
                    useWindow={false}
                    loadMore={getDemographicsData}
                    hasMore={hasMore}
                    loader={<div className='mt-2'><DemographicsCardSkeleton /></div>}
                >
                    {
                        demographicsData.length <= 0 && !isLoading ?
                            <div className='text-center'>No data found</div>
                            :
                            demographicsData?.map((data, index) => (
                                <div key={index} className="box border-bottom pb-3 mb-3 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="profile-wrapper me-2">
                                            <img src={data?.brand_user?.brand_owner ? data?.brand_user?.brand_owner?.image_url : data?.brand_user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                        </div>
                                        <h6>{data?.brand_user?.brand_owner ? data?.brand_user?.brand_owner?.brand_name : data?.brand_user?.shopper?.full_name}</h6>
                                    </div>

                                    <div>
                                        <span className='text-capitalize'>Est. Comm: ${data?.est_commission}</span>
                                    </div>
                                </div>
                            ))
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default DemographicsCard