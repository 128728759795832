import React, { useState, useContext, useEffect } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link } from 'react-router-dom'
import { AddFolderPopupContext } from '../../App'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader'
import { AddFolder } from '../../services/folders/folder'

const AddFolderPopup = ({ addFolderPopup, setIsFolderLoading, setAddFolderPopup, folders, setFolders, setSelectedFolder }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const { register, handleSubmit, reset, atch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        // Reset input when popup is open or close
        reset({
            name: ""
        })
    }, [addFolderPopup])

    const onSubmit = async (data) => {
        // create folder by passing it name to api
        setIsDisabled(true)
        try {
            data.is_private = 0 // create public folder if 0 else create private folder
            const res = await AddFolder(data)
            const newlyAddedFolder = res.data.data
            setFolders([
                ...folders,
                newlyAddedFolder
            ])
            if (folders.length == 0) {
                setSelectedFolder({
                    id: newlyAddedFolder.id,
                    index: 0
                })
            }
            setIsFolderLoading(false)
            setIsDisabled(false)
            setAddFolderPopup(false)
            success(res.data.message)
        }
        catch (e) {
            setIsFolderLoading(false)
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup add-folder-popup'
                show={addFolderPopup}
                onHide={() => setAddFolderPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='d-flex align-items-center text-center mb-5 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>ADD FOLDER</h3>
                            <div className="text-end times-icon-wrapper" onClick={() => setAddFolderPopup(false)}>
                                <Times />
                            </div>
                        </div>

                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='content-container text-center'>
                                    <div className="text-start">
                                        <label className="mb-1">Folder Title</label>
                                        <Form.Control
                                            className="py-3"
                                            type="text"
                                            placeholder="Folder title"
                                            minLength={VALIDATIONS.FOLDER_TITLE_MIN}
                                            maxLength={VALIDATIONS.FOLDER_TITLE_MAX}

                                            {...register("name",
                                                {
                                                    minLength: {
                                                        value: VALIDATIONS.FOLDER_TITLE_MIN,
                                                        message: VALIDATIONS_TEXT.FOLDER_TITLE_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.FOLDER_TITLE_MAX,
                                                        message: VALIDATIONS_TEXT.FOLDER_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.FOLDER_TITLE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                        {errors.name && <small className='text-start d-block text-danger mt-1'>{errors.name.message}</small>}
                                    </div>

                                    <div className="mt-40">
                                        <Button type="submit" className="w-100 btn-solid mb-2">
                                            {isDisabled ? <Loader /> : "ADD"}
                                        </Button>
                                        <Button className="w-100 btn-transparent" onClick={() => setAddFolderPopup(false)}>CANCEL</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddFolderPopup