import React, { useState, useContext, useEffect } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { AddFolderPopupContext } from '../../App'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader'
import { AddFolder } from '../../services/folders/folder'

const AddBankAccountPopup = ({ addBankAccountPopup, setAddBankAccountPopup }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()
    const { register, handleSubmit, reset, atch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        // Reset input when popup is open or close
        reset({
            iban: ""
        })
    }, [addBankAccountPopup])

    const onSubmit = (data) => {
        setIsDisabled(true)

        setTimeout(() => {
            setIsDisabled(false)
            navigate("/payment-management")
        }, 1500)
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup add-bank-account-popup'
                show={addBankAccountPopup}
                onHide={() => setAddBankAccountPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='d-flex align-items-center text-center mb-5 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>Add bank account</h3>
                            <div className="text-end times-icon-wrapper" onClick={() => setAddBankAccountPopup(false)}>
                                <Times />
                            </div>
                        </div>

                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='content-container text-center'>
                                    <div className="text-start">
                                        <label className="mb-1">IBAN Number *</label>
                                        <Form.Control
                                            className="py-3"
                                            type="text"
                                            inputMode='text'
                                            placeholder="IBAN Number *"
                                            minLength={VALIDATIONS.IBAN_MIN}
                                            maxLength={VALIDATIONS.IBAN_MAX}
                                            {...register("iban",
                                                {
                                                    minLength: {
                                                        value: VALIDATIONS.IBAN_MIN,
                                                        message: VALIDATIONS_TEXT.IBAN_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.IBAN_MAX,
                                                        message: VALIDATIONS_TEXT.IBAN_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.IBAN_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/,
                                                        message: VALIDATIONS_TEXT.INVALID_FORMAT + " (Example: GB82WEST12345698765432)"
                                                    }
                                                })
                                            }
                                        />
                                        {errors.iban && <small className='text-start d-block text-danger mt-1'>{errors.iban.message}</small>}
                                    </div>

                                    <div className="mt-40">
                                        <Button type="submit" className="w-100 btn-solid mb-2">
                                            {isDisabled ? <Loader /> : "ADD"}
                                        </Button>
                                        <Button className="w-100 btn-transparent" onClick={() => setAddBankAccountPopup(false)}>CANCEL</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddBankAccountPopup