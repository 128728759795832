import React, { useContext, useState } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Times } from '../../constants/svgs'

const DomainVerificationCompletePopup = ({ domainVerificationCompletePopup, setDomainVerificationCompletePopup }) => {
    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup domain-verification-complete-popup'
                show={domainVerificationCompletePopup}
                onHide={() => setDomainVerificationCompletePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='d-flex align-items-center text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>Domain verification complete</h3>
                            <div className="text-end times-icon-wrapper" onClick={() => setDomainVerificationCompletePopup(false)}>
                                <Times />
                            </div>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p className="text-black">
                                    The steps to verify the domain has been <br />
                                    emailed you.
                                </p>
                            </div>

                            <div className="mb-30 mt-60">
                                <NavLink to="" className="w-100 btn-solid mb-2">Continue</NavLink>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DomainVerificationCompletePopup