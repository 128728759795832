import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { useLocation, useNavigate } from 'react-router-dom'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success, error } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { LockFill } from '../../constants/svgs'
import Loader from '../Loader/Loader'
import { ResetPassword } from '../../services/auth/auth'

const ResetPasswordForm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const location = useLocation()
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const password = watch("password")

    const onSubmit = async ({ password }) => {
        setIsDisabled(true)

        try {
            const data = {
                email: location.state.data.email,
                verification_code: location.state.data.verification_code,
                password: password
            }
            const res = await ResetPassword(data)
            setIsDisabled(false)
            success(res.data.message)
            setTimeout(() => {
                navigate("/signin")
            }, 1500);
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <div className='auth-card reset-password-form'>
            <ToastContainer />
            <div>
                <h3 className='pb-30 border-bottom mb-30'>RESET PASSWORD</h3>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-80'>
                    <FloatingLabel controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}

                    <FloatingLabel className="mt-20" controlId="confirm-password" label="Confirm Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showConfirmPassword ? "text" : "password"}`}
                            placeholder="Confirm Password *"
                            {...register("password_confirmation",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                    },
                                    validate: (value) =>
                                        value === password || "The Passwords do not match"
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {
                                showConfirmPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password_confirmation && <small className='text-start d-block text-danger mt-1'>{errors.password_confirmation.message}</small>}

                    <Button type="submit" className='btn-solid mt-40 mb-20'>
                        {isDisabled ? <Loader /> : "RESET PASSWORD"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ResetPasswordForm