import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CommentCardSkeleton = () => {
    return (
        <div className="box-wrapper">
            {
                Array(5).fill(null)?.map((data, index) => (
                    <div key={index} className="box pb-3 mt-3">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="info-box">
                                <div className='d-flex'>
                                    <Skeleton width={40} height={40} circle={true} />

                                    <div className='ms-2'>
                                        <Skeleton count={1} width={80} height={10} />
                                        <Skeleton count={1} width={150} height={10} />
                                    </div>
                                </div>
                            </div>

                            <div className="time-box d-flex justify-content-end align-items-center text-end">
                                <Skeleton count={1} width={80} height={15} />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CommentCardSkeleton