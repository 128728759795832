import React, { useState, useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { HeartOutline, HeartFill, FavouriteOutline, FavouriteFill } from '../../constants/svgs'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfiniteScroll from 'react-infinite-scroller'
import BlockedUserCardSkeleton from '../skeleton/BlockedUserCardSkeleton'
import { useEffect } from 'react'
import { AddRemovePostFromFavourite, AddRemovePostReaction } from '../../services/posts/posts'
import { FILE_TYPE, IMAGE_FILE_FORMAT, INSTANCE_TYPE, STATUS } from '../../constants/app-constants'
import { error } from '../../constants/msg'
import MassonaryCardSkeleton from '../skeleton/MassonaryCardSkeleton'
import { UserData } from '../../App'
import { BsPlayCircleFill } from "react-icons/bs"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const MassonaryCard = ({ link1, link2, data, setData }) => {
    const [userData, setUserData] = useContext(UserData)
    const navigate = useNavigate()

    const handleLike = async (index, postId) => {
        /* 
            By default post is not liked 
            OnClick we change liked = true if post is not liked else we change liked = false
        */
        data[index].is_reacted = !data[index].is_reacted
        setData([...data])
        try {
            const reactionData = {
                instance_id: postId,
                instance_type: INSTANCE_TYPE.post,
                reaction: data[index].is_reacted ? STATUS.like : STATUS.dislike
            }
            await AddRemovePostReaction(reactionData)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const handleFavourite = async (index, postId) => {
        data[index].is_favourite = !data[index].is_favourite
        setData([...data])
        try {
            const favouriteData = {
                post_id: postId,
                action: data[index].is_favourite ? STATUS.addToFavourite : STATUS.removeFromFavourite,
            }
            await AddRemovePostFromFavourite(favouriteData)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const handleNavigate = (link1, id) => {
        if (id == userData?.id) {
            return "/my-profile"
        }
        else {
            return `/${link1}/${id}`
        }
    }

    return (
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 576: 3, 900: 4, 1300: 5, 1600: 6 }}>
            <Masonry gutter='30px'>
                {
                    data?.map((data, index) => (
                        <div className={`item massonary-card ${index % 2 == 0 ? 'even' : 'odd'}`} key={index}>
                            <NavLink
                                state={{ data: data }}
                                to={handleNavigate(link1, data?.user?.id)}
                            >
                                <div className='d-flex align-items-center mb-2 cursor-pointer'>
                                    <div className="profile-img-wrapper">
                                        <img src={data?.user?.brand_owner ? data?.user?.brand_owner?.image_url : data?.user?.shopper?.image_url} onError={(e) => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                    </div>
                                    <h6 className='ms-2 text-capitalize'><b>{data?.user?.brand_owner ? data?.user?.brand_owner?.brand_name : data?.user?.shopper?.full_name}</b></h6>
                                </div>
                            </NavLink>

                            <NavLink
                                state={{ data: data }}
                                to={`/${link2}/${data?.id}`}
                            >
                                <div className='post-img'>
                                    {
                                        data?.attachments.length == 1 ?     //  data?.attachments.length = 0 when there is no thumbnail
                                            <img className='w-100' src={data?.attachments[0]?.attachment_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                            :
                                            <div className='video-thumbnail w-100 h-100'>
                                                <BsPlayCircleFill className="icon" />
                                                <img className='w-100' src={data?.attachments[1]?.attachment_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                            </div>
                                    }
                                </div>
                            </NavLink>

                            <div className='d-flex align-items-center ps-2 mt-1'>
                                <div>
                                    <label htmlFor={`heart-${index}`}>
                                        <div className='icon-wrapper me-4'>
                                            <input value={data?.is_reacted} name={`heart-${index}`} onChange={e => handleLike(index, data?.id)} id={`heart-${index}`} className='d-none' type="checkbox" />
                                            {
                                                data.is_reacted ?
                                                    <HeartFill /> :
                                                    <HeartOutline />
                                            }
                                            <span className='ms-2'></span>
                                        </div>
                                    </label>
                                </div>

                                <div className='ms-2'>
                                    <label htmlFor={`favourite${index}`}>
                                        <div className='icon-wrapper me-4'>
                                            <input value={data.is_favourite} name={`favourite-${index}`} onChange={e => handleFavourite(index, data?.id)} id={`favourite${index}`} className='d-none' type="checkbox" />
                                            {
                                                data.is_favourite ?
                                                    <FavouriteFill /> :
                                                    <FavouriteOutline />
                                            }
                                            <span className='ms-2'></span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Masonry>
        </ResponsiveMasonry>
    )
}

export default MassonaryCard