import React from 'react'
import Assets from '../../constants/images'
import { AiOutlinePlus } from "react-icons/ai"
import { Link } from 'react-router-dom'

const CreatePostBox = ({ img, name, text, onClick }) => {
    return (
        <div className="create-post-box text-center" onClick={() => onClick(name)}>
            <div className='img-wrapper'>
                <img className='w-100 h-100' src={img} alt="" />
            </div>
            <div className="mt-20">
                <h2 className={`w-400 mb-80 ${name === "bank_card" ? "d-none" : "d-block"}`}>No post to show</h2>
                <AiOutlinePlus className='icon' />
                <h6 className="mt-20">{text}</h6>
            </div>
        </div>
    )
}

export default CreatePostBox