import React from 'react'
import { Container } from 'react-bootstrap'
import { Row, Col } from "react-bootstrap"
import Assets from '../../constants/images'

const AuthLayout = ({ children }) => {
    return (
        <section className='auth-layout'>
            <div className='d-flex h-100'>
                <div className='left-wrapper'>
                    <div className='w-100 h-100 d-flex align-items-center'>
                        <img src={Assets.AuthBackground} alt="" />
                    </div>
                </div>

                <div className='right-wrapper'>
                    <div className="wrapper-container">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuthLayout