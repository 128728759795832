import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link } from 'react-router-dom'
import { FiltersContext } from '../../App'
import Assets from '../../constants/images'
import { error } from '../../constants/msg'
import { Times } from '../../constants/svgs'
import { GetCategories } from '../../services/brands/brand'

const FilterPopup = ({ filterNow, setFilterNow, filterPopup, setFilterPopup, setBrandData, setBrandCurrentPage, setIsLoading }) => {
    const [categories, setCategories] = useState([])
    const [filters, setFilters] = useContext(FiltersContext)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        setIsLoading(true)
        setFilterNow(!filterNow)
        setBrandData([]);
        setBrandCurrentPage(1)
        setFilterPopup(false)
    }

    const handleFilterChange = (value) => {
        let isAlreadyExist = filters.includes(value)

        if (isAlreadyExist) {
            let index = filters.indexOf(value)
            let temp = [...filters]
            temp.splice(index, 1)
            setFilters(temp)
        }
        else {
            setFilters([
                ...filters,
                value
            ])
        }
    }

    const checked = (value) => {
        let isExist = filters.includes(value)
        return isExist
    }

    useEffect(() => {
        const fetchCategories = async () => {
            /*
                Fetch categories append in array and set their state
            */
            try {
                const all_categories = []
                const { data } = await GetCategories()
                data.data.forEach(category => all_categories.push({
                    label: category.name,
                    value: category.id
                }))
                setCategories(all_categories)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        fetchCategories()
    }, [])

    return (
        <Modal
            className='theme-popup filter-popup'
            show={filterPopup}
            onHide={() => setFilterPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex align-items-center text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>FILTER BY CATEGORIES</h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setFilterPopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='content-container'>
                                <Row>
                                    {
                                        categories.length > 0 &&
                                        categories?.map((data, index) => (
                                            <Col key={index} xs={4} className="mb-2">
                                                <label htmlFor={`filter-${index}`}>
                                                    {data.label}
                                                    <input checked={checked(data?.value)} onChange={() => handleFilterChange(data?.value)} className="d-none" type="checkbox" id={`filter-${index}`} />
                                                </label>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <div className="mt-30">
                                    <Button type="submit" className="w-100 btn-solid">FILTER NOW</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FilterPopup