import React, { useContext, useEffect, useState } from 'react'
import CreatePostBox from "../components/dashboard/CreatePostBox"
import { Container, Row, Col, Button } from "react-bootstrap"
import Assets from '../constants/images'
import MassonaryCard from "../components/dashboard/MassonaryCard"
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { POPUP_TYPE, POST_ROLES } from '../constants/app-constants'
import { FaClosedCaptioning } from 'react-icons/fa'
import { GetPosts } from '../services/posts/posts'
import { ToastContainer } from 'react-toastify'
import MassonaryCardSkeleton from '../components/skeleton/MassonaryCardSkeleton'
import Loader from '../components/Loader/Loader'
import { error } from '../constants/msg'
import { FiltersContext } from '../App'
import SearchPopup from '../components/popup/SearchPopup'

const BrandPage = () => {
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [totalPage, setTotalPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState([]);
    const [filterNow, setFilterNow] = useState(false)
    const [filters, setFilters] = useContext(FiltersContext)
    const [searchPopup, setSearchPopup] = useState(false)

    const handleClick = () => {
        setSearchPopup(true)
    }

    useEffect(() => {
        const getPosts = async () => {
            if (currentPage > 1) {
                setIsLoadMore(true)
            }
            try {
                const params = {
                    orderBy: "id",
                    sortedBy: "desc",
                    is_brand: 1,
                    role: POST_ROLES.brand,
                    page: currentPage,
                }
                if (filters.length > 0) {
                    params.category_ids = filters
                }
                const res = await GetPosts(params)
                setTotalPage(res.data.data.last_page)
                setData(data.concat(res.data.data.data))
                setIsLoading(false)
                setIsLoadMore(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsLoadMore(false)
                error(e.response.data.message)
            }
        }

        getPosts()
    }, [currentPage, filterNow])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Brands" link3="" link3Text="" link4="" link4Text="" popupType={POPUP_TYPE.filter} filterNow={filterNow} setFilterNow={setFilterNow} setBrandData={setData} setBrandCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
            <SearchPopup searchPopup={searchPopup} setSearchPopup={setSearchPopup} />
            <ToastContainer />

            <div className={`pages brand-page ${data.length <= 0 && "h-100"}`}>
                {
                    isLoading ?
                        <div className='py-3'>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>
                                        <div className='massonary-grid'>
                                            <MassonaryCardSkeleton count={6} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        :
                        data.length <= 0 ?
                            <div style={{marginTop:"-60px"}} className='d-flex align-items-center justify-content-center h-100'>
                                <CreatePostBox img={Assets.CreatePostImg} name="create_post" text="Click here to follow others" onClick={handleClick} />
                            </div>
                            :
                            <div className='py-3'>
                                <Container fluid>
                                    <Row>
                                        <Col xs={12}>
                                            <div className='massonary-grid'>
                                                <MassonaryCard link1="brand" link2="post" data={data} setData={setData} />
                                            </div>
                                        </Col>

                                        <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
                                            {
                                                isLoadMore ?
                                                    <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                }
            </div>
        </>
    )
}

export default BrandPage