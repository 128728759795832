import React, { useRef, useEffect, useContext, useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify';
import { error, success } from '../../constants/msg';
import { AgeFill, BioFill, GenderFill, LocationFill, MessageIconFill, PaypalFill, UserFill } from '../../constants/svgs'
import { GetUser } from '../../services/users/users'
import { UserData } from '../../App'
import Loader from '../Loader/Loader'
import { UpdateUser } from "../../services/users/users"
import { SetAuthUserLocalStorage } from "../../services/localStorage/localStorage"
import { UploadFileToS3 } from '../../utils/helper'
import { constant } from '../../utils/constants'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import moment from 'moment'

const ShopperEditProfileForm = () => {
    const imageRef = useRef()
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const [userDetails, setUserDetails] = useState()
    const [age, setAge] = useState(null)
    const [options, setOptions] = useState([
        {
            label: "Male",
            value: "Male"
        },
        {
            label: "Female",
            value: "Female"
        }
    ])
    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const { register, handleSubmit, reset, setValue, control, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const uploadImageToS3 = async (imgFile) => {
        try {
            const { url } = await UploadFileToS3(imgFile, constant.IMAGE_DIR_NAME)
            return url
        }
        catch (e) {
            error(e.message)
        }
    }

    const getAge = (dob) => {
        if (dob === null) {
            return null
        }
        else {
            let diff = moment(dob).diff(moment(), 'milliseconds');
            let duration = moment.duration(diff)
            let myAge = duration._data.years.toString().replace("-", "")
            setValue("age", age)
            return parseInt(myAge)
        }
    }

    const getAgeToDate = (myAge) => {
        // Take myAge as input like '22' and retrun my date of birth year as a date object
        if (myAge || myAge == 0) {
            const date = moment().subtract(myAge, 'years')._d
            setValue("age", date)
            return date
        }
        else {
            return null
        }
    }

    const onSubmit = async (data) => {
        /*
            Before update check if user upload/change his image
            then first upload it on S3 than add url to data and than upadte.
            After update set updated user data in localStorage and userData context
        */
        setIsDisabled(true)
        try {
            data.role = "shopper"
            data.gender = data.gender.value
            data.age = getAge(age)

            if (file.file) {
                const url = await uploadImageToS3(file.file)
                data.profile_picture = url
            }
            const res = await UpdateUser(userData.id, data);
            SetAuthUserLocalStorage(res.data.data.user)
            setUserData(res.data.data.user)
            imageRef.current.value = null
            success(res.data.message)
            setIsDisabled(false)
            setTimeout(() => {
                navigate("/my-profile")
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    useEffect(() => {
        const getProfile = async () => {
            /*
                Fetch user profile and set thier info in input 
                also set user image preview in file.file_url
            */

            try {
                const res = await GetUser(userData.id)
                setFile({
                    ...file,
                    file_url: res?.data?.data?.user?.shopper?.image_url
                })
                reset({
                    full_name: res?.data?.data?.user?.shopper?.full_name,
                    email: res?.data?.data?.user?.email,
                    paypal_email: res?.data?.data?.user?.paypal_email,
                    bio: res?.data?.data?.user?.brand_owner ? res?.data?.data?.user?.brand_owner?.bio : res?.data?.data?.user?.shopper?.bio,
                    ...(res?.data?.data?.user?.shopper?.gender && {     // conditionally reset gender (if we get gender from backend than only set in input)
                        gender: {
                            label: res?.data?.data?.user?.shopper?.gender,
                            value: res?.data?.data?.user?.shopper?.gender,
                        }
                    })
                })
                setAge(getAgeToDate(res.data.data.user.shopper.age))
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getProfile()
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="auth-card edit-profile-form">
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>EDIT PROFILE</h3>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='box text-center mt-4 mb-2'>
                        <label className="post-profile-wrapper" htmlFor="post-profile">
                            <input
                                className='d-none'
                                type="file"
                                accept="image/*"
                                id="post-profile"
                                ref={imageRef}
                                onChange={(e) => {
                                    setFile({
                                        file: e.target.files[0],
                                        file_url: URL.createObjectURL(e.target.files[0])
                                    })
                                }}
                            />
                            <img crossorigin="anonymous" src={file.file_url ? file.file_url : Assets.ProfileUploadPlaceholder} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                        </label>
                    </div>
                    <span className="d-block text-center">Upload/capture profile picture</span>

                    <div className='mt-30 px-40'>
                        <FloatingLabel controlId="name" label="Full name *">
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                maxLength={VALIDATIONS.NAME}
                                type="text"
                                placeholder="Full name *"
                                {...register("full_name",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.FULL_NAME_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <UserFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.full_name && <small className='text-start d-block text-danger mt-1'>{errors.full_name.message}</small>}

                        <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                            <Form.Control
                                readOnly
                                maxLength={VALIDATIONS.EMAIL}
                                type="email"
                                placeholder="Email address *"
                                {...register("email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <MessageIconFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                        <div className="age-picker mt-20">
                            <div className="icon-wrapper">
                                <AgeFill className='icon' />
                            </div>

                            <DatePicker
                                isClearable={false}
                                onKeyDown={(e) => e.preventDefault()}
                                selected={age}
                                onChange={(date) => setAge(date)}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Age"
                                maxDate={new Date()}
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                scrollableMonthYearDropdown
                                dropdownMode="select"
                            />
                            <input
                                className='age-input'
                                placeholder='Age'
                                type="number"
                                readOnly
                                value={getAge(age)}
                            />
                            {errors.age && <small className='text-start d-block text-danger mt-1'>{errors.age.message}</small>}
                        </div>

                        <div className="react-select">
                            <div className="icon-wrapper">
                                <GenderFill className='icon' />
                            </div>

                            <Controller
                                control={control}
                                name="gender"
                                rules={{
                                    requried: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.gender
                                    }
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder="Gender"
                                        className="mt-20 form-control"
                                        classNamePrefix="react-select-container"
                                        isSearchable={false}
                                        options={options}
                                    />
                                )}
                            />
                        </div>
                        {errors.gender && <small className='text-start d-block text-danger mt-1'>{errors.gender.message}</small>}

                        <FloatingLabel className="mt-20" controlId="paypal_email" label="PayPal Email Address">
                            <Form.Control
                                maxLength={VALIDATIONS.EMAIL}
                                type="email"
                                placeholder="PayPal Email Address"
                                {...register("paypal_email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: false,
                                            message: VALIDATIONS_TEXT.PAYPAL_EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <PaypalFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.paypal_email && <small className='text-start d-block text-danger mt-1'>{errors.paypal_email.message}</small>}

                        <div className='bio-input mt-20'>
                            <div className="icon-wrapper">
                                <BioFill className='icon' />
                            </div>

                            <Form.Control
                                className='pb-0'
                                as="textarea"
                                maxLength={VALIDATIONS.BIO_MAX}
                                placeholder="Bio"
                                {...register("bio",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.BIO_MAX,
                                            message: VALIDATIONS_TEXT.BIO_MAX
                                        },
                                        required: {
                                            value: false,
                                            message: VALIDATIONS_TEXT.BIO_REQUIRED
                                        },
                                    })
                                }
                            />
                            {errors.bio && <small className='text-start d-block text-danger mt-1'>{errors.bio.message}</small>}
                        </div>
                        {errors.bio && <small className='text-start d-block text-danger mt-1'>{errors.bio.message}</small>}

                        <Button type="submit" className='btn-solid my-5'>
                            {isDisabled ? <Loader /> : "UPDATE PROFILE"}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default ShopperEditProfileForm