import React, { useContext, useState, } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success, error } from '../../constants/msg'
import { Logout } from '../../services/auth/auth'
import { EmptyLocalStorage } from '../../services/localStorage/localStorage'
import { DeleteAccount } from '../../services/users/users'
import Loader from '../Loader/Loader'
import { UserData, UserRole } from '../../App'

const LogoutPopup = ({ logoutPopup, setLogoutPopup }) => {
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [isDisabled, setIsDisabled] = useState(false)

    const logout = async () => {
        // Logout user and after logout clear localStorage and Contexts
        setIsDisabled(true)

        try {
            const res = await Logout()
            setUserData(null)
            setUserRole(null)
            EmptyLocalStorage()
            setLogoutPopup(false)
            setIsDisabled(false)
            success(res.data.message)

            setTimeout(() => {
                navigate("/signin")
            }, 1500);
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup logout-popup'
                show={logoutPopup}
                onHide={() => setLogoutPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>LOGOUT ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, you want to Log Out <br />
                                    of your The Link Front account?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button onClick={() => logout()} className="w-100 btn-solid mb-2">
                                    {isDisabled ? <Loader /> : "LOGOUT"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setLogoutPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LogoutPopup