import React from 'react'
import { NavLink } from 'react-router-dom'
import Assets from '../../constants/images'

const SplashForm = () => {
    return (
        <div className='auth-card splash-form d-flex align-items-center'>
           <div className='w-100'>
                <div className="text-center logo-wrapper large mb-5">
                    <img src={Assets.LogoVertical} />
                </div>

                <div className=''>
                    <h4 className='my-5'><b>Transforming shopping into <br /> an experience.</b></h4>
                    <NavLink to="/select-role" className='mb-30 btn-solid'>CREATE AND ACCOUNT</NavLink>
                    <span className="d-block">Already have an account? <NavLink className="link" to="/signin">SIGN IN</NavLink></span>
                </div>
           </div>
        </div>
    )
}

export default SplashForm