import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { MdEmail } from "react-icons/md"
import { useNavigate } from 'react-router-dom'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { error, success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { MessageIconFill } from '../../constants/svgs'
import { ForgotPassword } from '../../services/auth/auth'
import Loader from '../Loader/Loader'

const ForgotPasswordForm = () => {
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const res = await ForgotPassword({ email: data.email })
            success(res.data.message)
            setIsDisabled(false)
            setTimeout(() => {
                navigate("/password-verification", { state: { email: data.email, type: "reset-password" } })
            }, 1500);

        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <div className='auth-card forgot-password-form'>
            <ToastContainer />
            <div>
                <h3 className='pb-30 border-bottom mb-30'>FORGOT PASSWORD</h3>
                <p>
                    Enter the email associated with your account and <br className='d-none d-sm-block' />
                    we'll send verification code to reset your password
                </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-5'>
                    <FloatingLabel controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                    <Button type="submit" className='btn-solid mt-40 mb-20'>
                        {isDisabled ? <Loader /> : "SUBMIT"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ForgotPasswordForm