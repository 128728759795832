import React from 'react'
import { Container } from 'react-bootstrap'
import PostDetailCardAsList from '../components/dashboard/PostDetailCardAsList'
import { POSTS_STATUS } from '../constants/app-constants'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ProfilePostListPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Post" link4="" link4Text="" />

            <div className='pages post-list-page'>
                <div className='pb-4'>
                    <Container fluid>
                        <PostDetailCardAsList isFromStats={false} isScheduled={false} activeTab={POSTS_STATUS.published} link="/my-profile/post" />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ProfilePostListPage
