const Assets = {
    Logo: require("../assets/img/logo.png"),
    LogoVertical: require("../assets/img/logo-vertical.png"),
    LogoVerticalXl: require("../assets/img/logo-vertical-xl.png"),
    LogoHorizontal: require("../assets/img/logo-horizontal.png"),
    ProfilePlaceholder: require("../assets/img/profile-placeholder.png"),
    ProfileUploadPlaceholder: require("../assets/img/profile-upload-placeholder.png"),
    PostUploadPlaceholder: require("../assets/img/post-upload-palceholder.png"),
    GeneralPlaceholder: require("../assets/img/general-placeholder.png"),
    AuthBackground: require("../assets/img/auth-bg.png"),
    CreatePostImg: require("../assets/img/create-post-img.png"),
    AddBankCardImg: require("../assets/img/card-add-img.png"),
    BankCardImg: require("../assets/img/bank-card-img.png"),
    MarkImg:require("../assets/img/mark.png"),

    Avatar1: require("../assets/img/avatar-1.png"),
    Avatar2: require("../assets/img/avatar-2.png"),
    Avatar3: require("../assets/img/avatar-3.png"),
    Avatar4: require("../assets/img/avatar-4.png"),
    Avatar5: require("../assets/img/avatar-5.png"),
    Avatar6: require("../assets/img/avatar-6.png"),
    Avatar12: require("../assets/img/avatar-12.png"),
    Avatar7: require("../assets/img/avatar-7.png"),
    Avatar8: require("../assets/img/avatar-8.png"),
    Avatar9: require("../assets/img/avatar-9.png"),
    Avatar10: require("../assets/img/avatar-10.png"),
    Avatar11: require("../assets/img/avatar-11.png"),
    AvatarLarge1: require("../assets/img/avatar-lg-1.png"),

    BrandAvatar1: require("../assets/img/brand-avatar-1.png"),
    BrandAvatar2: require("../assets/img/brand-avatar-2.png"),
    BrandAvatar3: require("../assets/img/brand-avatar-3.png"),
    BrandAvatar4: require("../assets/img/brand-avatar-4.png"),
    BrandAvatar5: require("../assets/img/brand-avatar-5.png"),
    BrandAvatar6: require("../assets/img/brand-avatar-6.png"),
    BrandAvatarLarge1: require("../assets/img/brand-avatar-large.png"),
    BrandAvatarLarge2: require("../assets/img/brand-profile.png"),
    BrandImageLarge3: require("../assets/img/brand-img-large3.png"),
    BrandImageLarge4: require("../assets/img/brand-img-large4.png"),

    BrandImgSm1: require("../assets/img/brand-image-sm-1.png"),
    BrandImgSm2: require("../assets/img/brand-image-sm-2.png"),
    BrandImgSm3: require("../assets/img/brand-image-sm-3.png"),
    BrandImgSm4: require("../assets/img/brand-image-sm-4.png"),
    BrandImgSm5: require("../assets/img/brand-image-sm-5.png"),


    PostImage1: require("../assets/img/post-img-1.png"),
    PostImage2: require("../assets/img/post-img-2.png"),
    PostImage3: require("../assets/img/post-img-3.png"),
    PostImage4: require("../assets/img/post-img-4.png"),
    PostImage5: require("../assets/img/post-img-5.png"),
    PostImage6: require("../assets/img/post-img-6.png"),
    PostImage7: require("../assets/img/post-img-7.png"),
    PostImage9: require("../assets/img/post-img-9.png"),
    PostImage10: require("../assets/img/post-img-10.png"),
    PostImage11: require("../assets/img/post-img-11.png"),
    PostImage12: require("../assets/img/post-img-12.png"),
    PostImage13: require("../assets/img/post-img-13.png"),
    PostImage15: require("../assets/img/post-img-15.png"),
    PostImage16: require("../assets/img/post-img-16.png"),
    PostImage17: require("../assets/img/post-img-17.png"),
    PostImage18: require("../assets/img/post-img-18.png"),
    PostImage19: require("../assets/img/post-img-19.png"),
    PostImage20: require("../assets/img/post-img-20.png"),
    PostImage21: require("../assets/img/post-img-21.png"),
    PostImage22: require("../assets/img/post-img-22.png"),
    PostImage23: require("../assets/img/post-img-23.png"),
    PostImage24: require("../assets/img/post-img-24.png"),
    PostImage26: require("../assets/img/post-img-26.png"),
    PostImage27: require("../assets/img/post-img-27.png"),
    PostImage28: require("../assets/img/post-image-28.jpg"),
    PostImage29: require("../assets/img/post-image-29.jpg"),
    PostImage30: require("../assets/img/post-image-30.jpg"),
    PostImage31: require("../assets/img/post-image-31.jpg"),
    PostImage32:require("../assets/img/post-img-29.png"),
    PostImage33:require("../assets/img/post-img-30.png"),

    FavPostImg1:require("../assets/img/fav-post-img-1.png"),
    FavPostImg2:require("../assets/img/fav-post-img-2.png"),
    FavPostImg3:require("../assets/img/fav-post-img-3.png"),
    FavPostImg4:require("../assets/img/fav-post-img-4.png"),
    FavPostImg5:require("../assets/img/fav-post-img-5.png"),
    FavPostImg6:require("../assets/img/fav-post-img-6.png"),
    FavPostImg7:require("../assets/img/fav-post-img-7.png"),
    FavPostImg8:require("../assets/img/fav-post-img-8.png"),
    FavPostImg9:require("../assets/img/fav-post-img-9.png"),
    FavPostImg10:require("../assets/img/fav-post-img-10.png"),
    FavPostImg11:require("../assets/img/fav-post-img-11.png"),
    FavPostImg12:require("../assets/img/fav-post-img-12.png"),
    
    PostImageLarge1: require("../assets/img/post-img-lg-1.png"),
    PostImageLarge2: require("../assets/img/post-img-lg-2.png"),


    PostImageSm1: require("../assets/img/post-img-sm-4.png"),
    PostImageSm2: require("../assets/img/post-img-sm-5.png"),
    PostImageSm3: require("../assets/img/post-img-sm-2.png"),
    PostImageSm4: require("../assets/img/post-img-sm-1.png"),
    PostImageSm5: require("../assets/img/post-img-sm-3.png"),


    PostImageXs1: require("../assets/img/post-img-xs-1.png"),
    PostImageXs2: require("../assets/img/post-img-xs-2.png"),
    PostImageXs3: require("../assets/img/post-img-xs-3.png"),
    PostImageXs3: require("../assets/img/post-img-xs-4.png"),
    PostImageXs5: require("../assets/img/post-img-xs-5.png"),
    PostImageXs6: require("../assets/img/post-img-xs-6.png"),
    PostImageXs7: require("../assets/img/post-img-xs-7.png"),
    PostImageXs9: require("../assets/img/post-img-xs-9.png"),
    PostImageXs10: require("../assets/img/post-img-xs-10.png"),
    PostImageXs11: require("../assets/img/post-img-xs-11.png"),
    PostImageXs12: require("../assets/img/post-img-xs-12.png"),


    BrandImage1: require("../assets/img/brand-img-1.png"),
    BrandImage2: require("../assets/img/brand-img-2.png"),
    BrandImage3: require("../assets/img/brand-img-3.png"),
    BrandImage4: require("../assets/img/brand-img-4.png"),
    BrandImage5: require("../assets/img/brand-img-5.png"),
    BrandImage6: require("../assets/img/brand-img-6.png"),
    BrandImage7: require("../assets/img/brand-img-7.png"),
    BrandImage8: require("../assets/img/brand-img-8.png"),
    BrandImage9: require("../assets/img/brand-img-9.png"),
    BrandImage10: require("../assets/img/brand-img-10.png"),
    BrandImage11: require("../assets/img/brand-img-11.png"),
    BrandImage12: require("../assets/img/brand-img-12.png"),
    BrandImage13: require("../assets/img/brand-img-13.png"),
    BrandImage14: require("../assets/img/brand-img-14.png"),

    BrandImage16: require("../assets/img/brand-img-16.png"),
    BrandImage17: require("../assets/img/brand-img-17.png"),
    BrandImage18: require("../assets/img/brand-img-18.png"),
    BrandImage19: require("../assets/img/brand-img-19.png"),
    BrandImage20: require("../assets/img/brand-img-20.png"),
    BrandImage21: require("../assets/img/brand-img-21.png"),

    NoBlockedUserImage: require("../assets/img/block-user-icon.jpg"),
    NoProductImage: require("../assets/img/no-product.png"),
    NoNotificationImage : require("../assets/img/no-notification.png"),
    PrivateAccountImage : require("../assets/img/private-account.png"),

    AddProductImage: require("../assets/img/add-product.png"),
    PostImageHorizontal: require("../assets/img/post-img-horizontal.png"),
    PostImagePlaceholder: require("../assets/img/post-loading.gif"),

    MainRollUpCardImg: require("../assets/img/main-rollup-card.png"),
    RollUpAvatar: require("../assets/img/rollup-avatar.png"),

    AppImage1: require("../assets/img/appimage1.png"),
    AppImage2: require("../assets/img/appimage2.png"),
}

export default Assets