import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ChangePasswordForm from '../components/forms/ChangePasswordForm'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ChangePasswordPage = ({ children }) => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/settings/change-password" link2Text="Settings" link3="#" link3Text="Change Password" link4="" link4Text="" />

            <div className='pages change-password-page'>
                <div className='pb-4'>
                    <Container fluid>
                        <Row>
                            <Col md={5} lg={4} xl={3} className="d-none d-md-block">
                                {children}
                            </Col>

                            <Col md={6} lg={5} xl={4}>
                                <ChangePasswordForm />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ChangePasswordPage