import React, { useContext, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { PrivacyFill, TermsAndConditionFill, LockFill, EyeFill, AddFolderFill } from "../../constants/svgs"
import { UserData } from '../../App'
import { PRIVACY } from '../../constants/app-constants'
import { GetUser, UpdatePrivacy, UpdateUser, UpdateUserPrivacy } from '../../services/users/users'
import { success, error } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { HiFolder } from "react-icons/hi"

const SettingSidebar = () => {
    const [userData, setUserData] = useContext(UserData)
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        // setChecked to true when account is private else false when page load
        let status = userData?.privacy === PRIVACY.private ? true : false
        setChecked(status)
    }, [])

    const handlePrivacyOnChange = async (e) => {
        // As state is not update suddenly therefore we revert the logic of checking the button      
        try {
            setChecked(!checked)
            const privacy = !checked ? PRIVACY.private : PRIVACY.public
            await UpdateUserPrivacy({
                privacy: privacy, ...(userData?.brand_owner !== null) && { website_url: userData?.brand_owner?.website_url }, // if login user is brand_owner than only add this param
            })
            const temp = userData
            temp.privacy = privacy
            SetAuthUserLocalStorage(temp)
            setUserData(temp)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div className='setting-sidebar h-100'>
            <ToastContainer />
            <div className="wrapper">
                <div className='title-wrapper border-bottom pb-30 mb-2 text-center'>
                    <h3>SETTINGS</h3>
                </div>

                <div className='link-container'>
                    <ul>
                        <li className="border-bottom pb-2 mb-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <EyeFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Privacy <span className='w-300'>(Public/Private)</span></span>
                                    </div>
                                </div>

                                <div className='text-end'>
                                    <Form.Check
                                        checked={checked}
                                        onChange={(e) => handlePrivacyOnChange(e)}
                                        className="p-0 switch-button"
                                        type="switch"
                                        id="custom-switch"
                                    />
                                </div>
                            </div>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/collection-privacy">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <HiFolder className='folder-icon' />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Collection Privacy</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/change-password">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <LockFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Change Password</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/terms-and-conditions">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <TermsAndConditionFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Terms And Conditions</span>

                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/privacy-policy">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <PrivacyFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Privacy Policy</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SettingSidebar