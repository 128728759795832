import React from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { ErrorPageSvg } from '../constants/svgs'
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate()
    return (
        <div className="pages error-page">
            <Container>
                <Row className="justify-content-center text-center">
                    <Col xs={12} sm={6}>
                        <div>
                            <ErrorPageSvg />
                            <Button onClick={()=>navigate("/home")} className="btn-solid">GO BACK</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ErrorPage