import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const MassonaryCardSkeleton = ({ count }) => {
    return (
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 576: 3, 900: 4, 1300: 5, 1600: 6 }}>
            <Masonry gutter='30px'>
                {
                    Array(count).fill(null)?.map((data, index) => (
                        <div className={`item massonary-card ${index % 2 == 0 ? 'even' : 'odd'}`} key={index}>
                            <div className='d-flex align-items-center mb-2'>
                                <Skeleton circle width={35} height={35} />
                                <Skeleton className='ms-2' width={80} />
                            </div>

                            <div className='post-img'>
                                <Skeleton style={{ borderRadius: "18px", width: "100%", height: "100%" }} />
                            </div>

                            <div className='mt-1'>
                                <Skeleton width={80} height={15} />
                            </div>
                        </div>
                    ))
                }
            </Masonry>
        </ResponsiveMasonry>
    )
}

export default MassonaryCardSkeleton