import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FloatingLabel, Button } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { AiFillLock } from 'react-icons/ai'
import { FaUserAlt } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { VscEye } from 'react-icons/vsc'
import Loader from '../components/Loader/Loader'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../constants/app-constants'
import Assets from '../constants/images'
import { error, success } from '../constants/msg'
import { FacebookFill, InstagramFill, LinkedinFill, LocationFill, MessageFill, MessageIconFill, PhoneFill, TwitterFill } from '../constants/svgs'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { GetContactData, SendContactData } from '../services/pages/pages'

const ContactPage = () => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [data, setData] = useState("")
    const [loading, setLoading] = useState(true)

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const getPageData = async () => {
            // Fetch contact page data 
            try {
                const res = await GetContactData()
                setData(res.data.data.content)
                setLoading(false)
            }
            catch (e) {
                error(e.response.data.message)
                setLoading(false)
            }
        }

        getPageData()
    }, [])

    const onSubmit = async (data) => {
        // send name, email, message to api
        setIsDisabled(true)
        try {
            const res = await SendContactData(data)
            reset({
                name:"", email:"", subject:"", message:""
            })
            success(res.data.message)
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }

    }

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Contact Us" link3="" link3Text="" link4="" link4Text="" />

            <div className="pages contact-page">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={6} xl={5} className="mb-4">
                            <div className="left-wrapper p-4 p-sm-5">
                                <div>
                                    <div className="mb-5">
                                        <h2><b>Contact Us</b></h2>
                                        <h1 className='mt-2'><b>How can we help you!</b></h1>
                                        <p className="mt-2">
                                            Fill the form and drop the email
                                        </p>
                                    </div>

                                    <div className="content-container">
                                        <div className="mb-20">
                                            <div className='box p-2'>
                                                <div className='icon-wrapper'>
                                                    <PhoneFill />
                                                </div>
                                                <p className='d-inline-block ps-3 pe-2'>+1255481453</p>
                                            </div>
                                        </div>

                                        <div className="mb-20">
                                            <div className='box p-2'>
                                                <div className='icon-wrapper'>
                                                    <MessageIconFill />
                                                </div>
                                                <p className='d-inline-block ps-3 pe-2'>support@linkfront.com</p>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className='box p-2'>
                                                <div className='icon-wrapper'>
                                                    <LocationFill />
                                                </div>
                                                <p className='d-inline-block ps-3 pe-2'>102 street 27129 Down Town Hill Station, Texas.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="logo-container d-flex align-items-center flex-wrap">
                                        <div className="box mt-20 me-2 me-sm-3">
                                            <FacebookFill />
                                        </div>

                                        <div className="box mt-20 me-2 me-sm-3">
                                            <TwitterFill />
                                        </div>

                                        <div className="box mt-20 me-2 me-sm-3">
                                            <InstagramFill />
                                        </div>

                                        <div className="box mt-20 me-2 me-sm-3">
                                            <LinkedinFill />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} xl={5} className="mb-4">
                            <div className='right-wrapper'>
                                <div className="w-100">
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="box p-4 p-sm-5">
                                            <FloatingLabel className="mt-2" controlId="name" label="Name *">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Name *"
                                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                                    maxLength={VALIDATIONS.NAME}
                                                    {...register("name",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.NAME_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                            </FloatingLabel>
                                            {errors.name && <small className='text-start d-block text-danger mt-1'>{errors.name.message}</small>}

                                            <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email address *"
                                                    maxLength={VALIDATIONS.EMAIL}
                                                    {...register("email",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.EMAIL,
                                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                            },
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                            }
                                                        })
                                                    }
                                                />
                                            </FloatingLabel>
                                            {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                                            <FloatingLabel className="mt-20" controlId="subject" label="Subject *">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Subject *"
                                                    maxLength={VALIDATIONS.SUBJECT}
                                                    {...register("subject",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.SUBJECT,
                                                                message: VALIDATIONS_TEXT.SUBJECT_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.SUBJECT_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                            </FloatingLabel>
                                            {errors.subject && <small className='text-start d-block text-danger mt-1'>{errors.subject.message}</small>}

                                            <div className="mt-20">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Message *"
                                                    maxLength={VALIDATIONS.MESSAGE_MAX}
                                                    {...register("message",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.MESSAGE_MAX,
                                                                message: VALIDATIONS_TEXT.MESSAGE_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.MESSAGE_REQURIED
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.message && <small className='text-start d-block text-danger mt-1'>{errors.message.message}</small>}
                                            </div>

                                            <Button type="submit" disabled={isDisabled} className="mt-40 w-100 btn-solid">
                                                {isDisabled ? <Loader /> : "SUBMIT"}
                                            </Button>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ContactPage