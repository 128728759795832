import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { GetPageBySlug } from '../services/pages/pages'
import { error, success } from '../constants/msg'
import { ToastContainer } from 'react-toastify';
import parse from 'html-react-parser';
import Loader from '../components/Loader/Loader'
import { PAGES } from '../constants/app-constants'

const TermsAndConditionsPage = ({ showBreadcrumb, children }) => {
    const [data, setData] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getPageData = async () => {
            try {
                const res = await GetPageBySlug(PAGES.terms_and_conditions)
                setData(res.data.data.content)
                setLoading(false)
            }
            catch (e) {
                error(e.response.data.message)
                setLoading(false)
            }
        }
        getPageData()
    }, [])

    return (
        <>
            {showBreadcrumb && <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/settings/terms-and-conditions" link2Text="Settings" link3="#" link3Text="Terms and Conditions " link4="" link4Text="" />}
            <ToastContainer />

            <div className='pages term-and-condition-page'>
                <Container fluid>
                    <Row>
                        <Col md={5} lg={4} xl={3} className={showBreadcrumb ? "d-none d-md-block" : "d-none"}>
                            {children}
                        </Col>

                        <Col className='p-0' md={showBreadcrumb ? 7 : 12} lg={showBreadcrumb ? 8 : 12} xl={showBreadcrumb ? 9 : 12}>
                            <div className="page-content-wrapper mb-4 mb-md-0 ">
                                <div className='title-wrapper border-bottom pb-30 mb-30 text-center'>
                                    <h3>Terms And Condition</h3>
                                </div>
                                <div className='page-content'>
                                    {
                                        loading ?
                                            <div className="text-center"><Loader style="black" /></div>
                                            :
                                            data && parse(data)
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default TermsAndConditionsPage