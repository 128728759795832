import React, { useEffect, useState } from 'react'
import PostCard from './PostCard'
import Assets from '../../constants/images'
import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap'
import PostCardSkeleton from '../skeleton/PostCardSkeleton'
import { GetPosts } from '../../services/posts/posts'
import { error } from '../../constants/msg'
import Loader from '../Loader/Loader'
import TabsSkeleton from '../skeleton/TabsSkeleton'
import { GetFolders } from '../../services/folders/folder'
import { GetProductsByFolder } from '../../services/products/products'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const ShopperFolderTabs = ({ link, userId, folderParams }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isProductByFolderLoading, setIsProductByFolderLoading] = useState(true)
    const [productsByFolder, setProductsByFolder] = useState([])
    const [isProductLoadMore, setIsProductLoadMore] = useState(false)
    const [currentPageForProducts, setCurrentPageForProducts] = useState(1)
    const [totalPageForProducts, setTotalPageForProducts] = useState(1)

    const [isPostLoadMore, setIsPostLoadMore] = useState(false)
    const [isPostLoading, setIsPostLoading] = useState(true)
    const [totalPageForPosts, setTotalPageForPosts] = useState(1)
    const [currentPageForPosts, setCurrentPageForPosts] = useState(1)
    const [posts, setPosts] = useState([]);

    const [isFolderLoading, setIsFolderLoading] = useState(true)
    const [folders, setFolders] = useState([])
    const [selectedFolder, setSelectedFolder] = useState({
        id: "",
        index: 0
    });

    useEffect(() => {
        const getFolders = async () => {
            // get folders of user
            try {
                const res = await GetFolders(folderParams)
                setFolders(res.data.data.data)
                setSelectedFolder({
                    id: res.data.data.data[0].id,
                    index: 0
                })
                setIsFolderLoading(false)
            }
            catch (e) {
                setIsFolderLoading(false)
                error(e.response.data.message)
            }
        }
        getFolders()
    }, [])

    useEffect(() => {
        const getPosts = async () => {
            const params = {
                orderBy: "id",
                sortedBy: "desc",
                // is_brand: 0,
                page: currentPageForPosts
            }
            if (currentPageForPosts > 1) {
                setIsPostLoadMore(true)
            }
            try {
                if (userId) {
                    params.user_id = userId
                }
                const res = await GetPosts(params)
                setTotalPageForPosts(res.data.data.last_page)
                setPosts([...posts, ...res.data.data.data])
                setIsPostLoading(false)
                setIsPostLoadMore(false)
            }
            catch (e) {
                setIsPostLoading(false)
                setIsPostLoadMore(false)
                error(e.response.data.message)
            }
        }

        getPosts()
    }, [currentPageForPosts])

    useEffect(() => {
        const getProductsByFolder = async () => {
            /*  Send folder id in api and get products by folder 
                Automatically call every every time when folder is switched 
            */
            if (currentPageForProducts > 1) {
                setIsProductLoadMore(true)
            }
            try {
                const params = {
                    page: currentPageForProducts
                }
                const res = await GetProductsByFolder(selectedFolder.id, params)
                setTotalPageForProducts(res.data.data.last_page)
                setProductsByFolder(res.data.data.data)
                setIsProductByFolderLoading(false)
                setIsProductLoadMore(false)
            }
            catch (e) {
                setIsProductByFolderLoading(false)
                setIsProductLoadMore(false)
                error(e.response.data.message)
            }
        }

        if (selectedFolder.id) {
            getProductsByFolder()
        }
    }, [selectedFolder.id, currentPageForProducts])

    return (
        <Tabs
            defaultActiveKey="Gallery"
            id="uncontrolled-tab-example"
            className="theme-tabs"
        >
            <Tab eventKey="Gallery" title="Gallery">
                <Row>
                    {
                        isPostLoading ?
                            <PostCardSkeleton />
                            :
                            posts.length <= 0 ?
                                <div className='text-center mt-4'><h6>No Post Available</h6></div>
                                :
                                posts?.map((data, index) => (
                                    <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                        <PostCard asGallery={true} data={data} link={`${link ? `${link}/user/${data?.user?.id}/posts/${data?.id}` : `/user/${data?.user?.id}/posts/${data?.id}`}`} />
                                    </Col>
                                ))
                    }

                    <Col xs={12} className={`${currentPageForPosts == totalPageForPosts ? "d-none" : "d-block"}`}>
                        {
                            isPostLoadMore ?
                                <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPageForPosts(currentPageForPosts + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                        }
                    </Col>
                </Row>
            </Tab>

            <Tab eventKey="Collection" title="Collection">
                <div className='folder-tabs'>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        {
                            isFolderLoading ?
                                <TabsSkeleton />
                                :
                                folders.length <= 0 ?
                                    <div className='mt-4'><h6>No Folder Available</h6></div>
                                    :
                                    folders?.map((data, index) => (
                                        <li key={index} className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${index == selectedFolder.index && "active"}`}
                                                id={`pills-${index}`}
                                                data-bs-toggle="pill"
                                                data-bs-target={`#pills-${index}`}
                                                type="button"
                                                role="tab"
                                                aria-controls={`pills-${index}`}
                                                aria-selected="true"
                                                onClick={() => {
                                                    setSelectedFolder({
                                                        index: index,
                                                        id: data?.id
                                                    })
                                                }}
                                            >
                                                {data?.name}
                                            </button>
                                        </li>
                                    ))
                        }
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        {
                            folders.length > 0 &&
                            folders?.map((folder, index) => (
                                <div key={index} className={`tab-pane fade show ${index == selectedFolder.index ? 'active' : 'fade'}`} id={`pills-${index}`} role="tabpanel" aria-labelledby={`pills-${index}-tab`}>
                                    <Row>
                                        {
                                            isProductByFolderLoading ?
                                                <PostCardSkeleton />
                                                :
                                                productsByFolder.length <= 0 ?
                                                    <div className='text-center mt-4'><h6>No Products Available</h6></div>
                                                    :
                                                    productsByFolder?.map((data, index) => (
                                                        <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                                            <PostCard asGallery={false} data={data} link={"/my-profile/posts"} />
                                                        </Col>
                                                    ))
                                        }

                                        <Col xs={12} className={`${currentPageForProducts == totalPageForProducts ? "d-none" : "d-block"}`}>
                                            {
                                                isProductLoadMore ?
                                                    <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPageForProducts(currentPageForProducts + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Tab>
        </Tabs>
    )
}

export default ShopperFolderTabs