import React, { useContext, useState } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader';
import { STATUS } from '../../constants/app-constants';
import { BlockUnblockUser } from '../../services/users/users';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserData } from '../../App';

const UnblockUserChatPopup = ({ unblockUserChatPopup, setUnblockUserChatPopup, selectedChatUser }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)

    const unBlockedChatUser = async () => {
        const channel_id = selectedChatUser?.channel_id
        const chatDocRef = doc(db, 'chats', channel_id)

        setIsDisabled(true)
        try {
            const chatDoc = await getDoc(chatDocRef)

            if (chatDoc.exists()) {
                const data = chatDoc.data()
                const index = data?.blocked_by.indexOf(userData?.id);
                data?.blocked_by?.splice(index, 1)

                await updateDoc(chatDocRef, {
                    is_blocked: false,
                    blocked_by: data?.blocked_by
                })
                const res = await BlockUnblockUser({ user_id: selectedChatUser?.id, status: STATUS.unblock })
                setIsDisabled(false)
                setUnblockUserChatPopup(false)
                success(res.data.message)
            }
        }
        catch (e) {
            setIsDisabled(false)
            error("Something went wrong")
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup unblock-alert-popup'
                show={unblockUserChatPopup}
                onHide={() => setUnblockUserChatPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>UNBLOCK ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Do you want to unblock <br />
                                    {selectedChatUser?.channel_user_name} ?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={unBlockedChatUser}>
                                    {isDisabled ? <Loader /> : "UNBLOCK"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setUnblockUserChatPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UnblockUserChatPopup