import React, { useState, useEffect, useContext } from 'react'
import { Button, Dropdown, Row } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Assets from '../../constants/images'
import { error, success } from '../../constants/msg'
import { MessageFill, DotsHorizontal } from '../../constants/svgs'
import { FollowRequestUpdate, FollowUser, GetUser, UnFollowUser } from '../../services/users/users'
import BlockUserPopup from '../popup/BlockUserPopup'
import ReportPopup from '../popup/ReportPopup'
import { ToastContainer } from 'react-toastify'
import { PRIVACY, REPORT_INSTANCE_TYPE, ROLES, STATUS } from '../../constants/app-constants'
import { UserData, UserRole } from '../../App'
import ProfileCardSkeleton from '../skeleton/ProfileCardSkeleton'
import Loader from '../Loader/Loader'
import ShopperFolderTabs from './ShopperFolderTabs'
import BrandFolderTabs from './BrandFolderTabs'
import TabsSkeleton from '../skeleton/TabsSkeleton'
import PostCardSkeleton from '../skeleton/PostCardSkeleton'
import { BiLockAlt } from "react-icons/bi"
import GalleryWithNoTabs from './GalleryWithNoTabs'
import { roles } from '../../utils/constants'
import { db } from '../../firebase'
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'

const ViewProfileCard = ({ setUserProfile }) => {
    const { slug } = useParams()
    const [userRole, setUserRole] = useContext(UserRole)
    const [userData, setUserData] = useContext(UserData)
    const [isLoading, setIsLoading] = useState(true)
    const [blockUserPopup, setBlockUserPopup] = useState(false)
    const [reportPopup, setReportPopup] = useState(false)
    const [user, setUser] = useState()
    const [selectedPostUser, setSelectedPostUser] = useState()
    const [followUnFollowLoading, setFollowUnFollowLoading] = useState(false)
    const [isAcceptLoading, setIsAcceptLoading] = useState(false)
    const [isRejectLoading, setIsRejectLoading] = useState(false)
    const [clicked, setClicked] = useState(0)
    const navigate = useNavigate()

    const userTabs = {
        [ROLES.shopper]: <ShopperFolderTabs folderParams={{ user_id: slug }} userId={slug} />,
        [ROLES.brand]: <BrandFolderTabs folderParams={{ user_id: slug }} userId={slug} />,
    }

    useEffect(() => {
        const getUser = async () => {
            // get user by id
            setIsLoading(true)
            try {
                const res = await GetUser(slug)
                setUser(res.data.data.user)
                setUserProfile(res.data.data.user)
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                setUser()
            }
        }

        getUser()
    }, [clicked, slug])

    const followUnFollowUser = async () => {
        /*
            First we check that we already follow the profile we opened
            if yes than hit unfollow api because we already follewed him 
            else hit follow api to follow the user.
            
            If we are rejecting our own request than send your own id as user_id 
        */

        if (user?.is_followed) {
            setFollowUnFollowLoading(true)
            try {
                const data = {
                    follower_id: user?.brand_owner ? user?.brand_owner?.user_id : user?.shopper?.user_id,
                    user_id: userData?.id,
                    status: STATUS.reject
                }
                await FollowRequestUpdate(data)
                setUser({
                    ...user,
                    is_followed: false
                })
                setFollowUnFollowLoading(false)
            }
            catch (e) {
                setFollowUnFollowLoading(false)
                error(e.response.data.message)
            }
        }
        else {
            setFollowUnFollowLoading(true)
            try {
                await FollowUser({ follower_id: user?.id })
                setUser({
                    ...user,
                    is_followed: true
                })
                setFollowUnFollowLoading(false)
            }
            catch (e) {
                setFollowUnFollowLoading(false)
                error(e.response.data.message)
            }
        }
    }

    const acceptRejectRequest = async (status) => {
        // Accept and reject request by sending id of user with status whom request you want to accept and reject 
        if (status == STATUS.accept) {
            setIsAcceptLoading(true)
        }
        else {
            setIsRejectLoading(true)
        }

        try {
            const data = {
                follower_id: user?.brand_owner ? user?.brand_owner?.user_id : user?.shopper?.user_id,
                status: status
            }
            await FollowRequestUpdate(data)
            setIsAcceptLoading(false)
            setIsRejectLoading(false)
            setClicked(clicked + 1)
            success(status === STATUS.accept ? "Request accepted" : "Request rejected")
        }
        catch (e) {
            setIsAcceptLoading(false)
            setIsRejectLoading(false)
            error(e.response.data.message)
        }
    }

    const isUserAlreadyChatWithMe = async () => {
        const docId = userData?.id + "-" + user?.id
        const docRef = doc(db, "chats", docId)
        const chatDoc = await getDoc(docRef)
        const chat = chatDoc.data()

        if (chat) {
            navigate(`/view-all-messages/${docId}`)
        }
        else {
            try {
                const res = await GetUser(user?.id)

                const userRef1 = doc(db, 'users', userData?.id?.toString());
                await setDoc(userRef1, {
                    email: userData?.email,
                    id: userData?.id,
                    name: userData?.name,
                    image: userData?.shopper ? userData?.shopper?.profile_picture : userData?.brand_owner?.profile_picture || Assets.ProfilePlaceholder,
                    is_active: true
                })

                const userRef2 = doc(db, 'users', res?.data?.data?.user?.id?.toString());
                await setDoc(userRef2, {
                    email: res.data.data.user?.email,
                    id: res.data.data.user?.id,
                    name: res.data.data.user?.name,
                    image: res?.data?.data?.user?.shopper ? res?.data?.data?.user?.shopper?.profile_picture : res?.data?.data?.user?.brand_owner?.profile_picture || Assets.ProfilePlaceholder,
                    is_active: true
                })

                let initiateChat = {
                    blocked_by: [],
                    created_at: new Date(),
                    deleted_statuses: [
                        {
                            id: user?.id,
                            is_deleted: false
                        },
                        {
                            id: userData?.id,
                            is_deleted: false
                        },
                    ],
                    deleted_messages_ids: [
                        {
                            id: user?.id,
                            deleted_messages_ids: ""
                        },
                        {
                            id: userData?.id,
                            deleted_messages_ids: ""
                        }
                    ],
                    is_blocked: false,
                    last_message: "No message yet",
                    message_count: 0,
                    updated_at: new Date(),
                    users: [userData?.id, user?.id]
                }

                const chatsRef = collection(db, "chats")
                const newDocRef = doc(chatsRef, docId)
                await setDoc(newDocRef, initiateChat)
                navigate(`/view-all-messages/${docId}`)
            }
            catch (e) {
                console.log("error", e)
            }
        }
    }

    return (
        <>
            <BlockUserPopup selectedPostUser={selectedPostUser} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
            <ReportPopup data={selectedPostUser} reportTo={REPORT_INSTANCE_TYPE.user} reportPopup={reportPopup} setReportPopup={setReportPopup} />

            {
                isLoading ?
                    <>
                        <ProfileCardSkeleton />
                        <Row>
                            <div className='mt-4 d-flex align-items-center justify-content-center'>
                                <TabsSkeleton />
                            </div>
                            <PostCardSkeleton />
                        </Row>
                    </>
                    :
                    user ?
                        user?.is_blocked ?
                            <div className='mt-5 text-center'>
                                <img width={100} height={100} src={Assets.MarkImg} />
                                <h2 className='text-grey mt-4'><b>SORRY, THIS PAGE IS NOT AVAILABLE</b></h2>
                            </div>
                            :
                            <>
                                <div className='view-profile-card'>
                                    <div className={`my-3 comming-request-wrapper ${(userData?.privacy == PRIVACY.private && user?.my_request?.current_status == 10) ? "d-block" : "d-none"}`}>
                                        <div className='new-box d-flex align-items-center justify-content-between'>
                                            <div className="d-flex align-items-center">
                                                <div className="profile-wrapper">
                                                    <img src={user?.brand_owner ? user?.brand_owner?.image_url : user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                                </div>
                                                <h6 className='ms-2'><b className='text-capitalize'>{user?.brand_owner ? user?.brand_owner?.brand_name : user?.shopper?.full_name}</b> want to follow you</h6>
                                            </div>

                                            <div className='btn-wrapper'>
                                                <Button disabled={isAcceptLoading} className="me-2 d-inline-block btn-solid green" onClick={() => acceptRejectRequest(STATUS.accept)}>
                                                    {isAcceptLoading ? <Loader /> : "CONFIRM"}
                                                </Button>
                                                <Button disabled={isRejectLoading} className="d-inline-block btn-solid red" onClick={() => acceptRejectRequest(STATUS.reject)}>
                                                    {isRejectLoading ? <Loader /> : "DELETE"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="wrapper p-4 text-center">
                                        <div className="profile-wrapper mx-auto">
                                            <img src={user?.brand_owner ? user?.brand_owner?.image_url : user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                        </div>
                                        <div className="mt-2">
                                            <h2 className='text-capitalize'>{user?.brand_owner ? user?.brand_owner?.brand_name : user?.shopper?.full_name}</h2>
                                            <span>{user?.brand_owner ? user?.brand_owner?.address : user?.shopper?.address}</span>
                                            <span>{user?.brand_owner?.category?.name}</span>
                                            <span>{user?.email}</span>
                                        </div>
                                        <div className="mt-2 d-flex justify-content-center align-items-center">
                                            {
                                                <Button disabled={followUnFollowLoading} onClick={followUnFollowUser} className={`btn-solid follow-btn me-2 ${userData?.id == user?.id ? "d-none" : "d-block"} `}>
                                                    {
                                                        followUnFollowLoading ?
                                                            <Loader />
                                                            :
                                                            user?.privacy == PRIVACY.public ?    // check if user is public 
                                                                !user?.is_followed ? "FOLLOW" : "UNFOLLOW"  // if user is public 
                                                                :
                                                                !user?.is_followed ? "FOLLOW" :
                                                                    user?.requested?.current_status == STATUS.accept ? "UNFOLLOW" : "CANCEL REQUEST"  // if user is private 
                                                    }
                                                </Button>
                                            }

                                            <Link to="#" onClick={isUserAlreadyChatWithMe}>
                                                <div className='icon-wrapper bg-deep-grey me-2'>
                                                    <MessageFill />
                                                </div>
                                            </Link>

                                            <div>
                                                <Dropdown className='icon-wrapper bg-white'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <DotsHorizontal />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="text-center">
                                                        <Dropdown.Item
                                                            className={`border-bottom ${userData?.id == user?.id ? "d-none" : "d-block"}`}
                                                            onClick={() => {
                                                                setReportPopup(true)
                                                                setSelectedPostUser(user)
                                                            }}
                                                        >
                                                            Report User
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className={`border-bottom ${userData?.id == user?.id ? "d-none" : "d-block"}`}
                                                            onClick={() => {
                                                                setBlockUserPopup(true)
                                                                setSelectedPostUser(user)
                                                            }}
                                                        >
                                                            Block User
                                                        </Dropdown.Item>

                                                        <Dropdown.Item className="">Cancel</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="private-account-wrapper">
                                        {
                                            user?.privacy == PRIVACY.public ?
                                                // user?.shopper ?
                                                userTabs[user?.brand_owner ? roles.brand : roles.shopper]
                                                // :<GalleryWithNoTabs userId={slug} />
                                                :
                                                (user?.requested !== null && user?.requested?.current_status == STATUS.accept) ?
                                                    // user?.shopper ?
                                                    userTabs[user?.brand_owner ? roles.brand : roles.shopper]
                                                    // :<GalleryWithNoTabs userId={slug} />
                                                    :
                                                    <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                                                        <div className='d-flex align-items-center justify-content-center lock-icon-wrappper'>
                                                            <BiLockAlt className='icon' />
                                                        </div>
                                                        <h6 className='mt-3 p-0 m-0'>This account is private</h6>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </>
                        :
                        <div className='mt-4 text-center'>No user found</div>
            }
        </>
    )
}

export default ViewProfileCard