import { GetTokenLocalStorage } from "../services/localStorage/localStorage"

export const roles = {
    brand: 1,
    shopper: 2,
}

export const transactions = {
    CREDIT: 10,
    DEBIT: 20,
    PAY_OUTS: "payouts"
}


export const constant = {
    DEVICE_TYPE: "web",
    Paypal_CLIENT_ID: "AY7-6g8nXjPrYohqqLGOU5xMrqffC1JLNwStw2MZKl4nv28aOgib07Ytr6eKjT6CjDz7WD4HzFvBqXSq",
    DEVICE_TOKEN: "",
    LOCAL_STORAGE_TOKEN: "LINK_FRONT_AUTH_ACCESS_TOKEN",
    LOCAL_STORAGE_USER: "LINK_FRONT_USER_DATA",
    LOCAL_STORAGE_ROLE: "LINK_FRONT_USER_ROLE",
    BASE_URL: "https://api.thelinkfront.com/api/v1",
    IMAGE_DIR_NAME: "images",
    VIDEO_DIR_NAME: "videos"
}

export const apiUrl = {
    login: '/login',
    register: '/register',
    verify_otp: "/verify-otp",
    verify_reset_code: "/verify-reset-code",
    resend_otp: "/resend-code",
    change_password: "/change-password",
    forgot_password: "/forget-password",
    reset_password: "/reset-password",
    code_verify: "/code-verify",
    domain_verify: "/domainverify",
    top_up: "/top-up",
    pay_outs: "/payouts",
    brand_code: "/brandCode",
    logout: '/logout',
    categories: "/categories",
    posts: "/posts",
    users: "/users",
    block_user: "/user/block",
    folders: "/folders",
    pages: "/pages",
    user_privacy: "/user/privacy",
    products_by_folder: "/product/folder",
    reactions: "/reactions",
    about: "/about",
    contact_us: "/contactus",
    comments: "/comments",
    post_favorite_add_remove: "/post/favorite-add-remove",
    report: "/user/reports",
    report_types: "/user/report-types",
    recent_activity: "/user/activity",
    favourite_post: "/post/favorite",
    follow_user: "/user/follow",
    follow_request_update: "/follow-request-update",
    follow_request: "/follow-request-update",
    user_searches: "/user-searches",
    user_search: "/user-search",
    search: "/search",
    schedule_posts: "/schedule-posts",
    notifications: "/notifications",
    mark_read: "/mark-read",
    demographics: "/demographics",
    transactions: "/transactions",
    social_login: "/social_login",
    folder_privacy_update: "/folder-privacy-update",
    share_post: "/post/share",
    is_user_registered: "/is-user-registered",
    get_followed_brands: "/get-followed-brands",
    tagged_posts: "/user/tagged-posts",
    create_firebase_token: "/create-firebase-token",
    extension_link: "/extension-link",
    update_website_url: "/updatewebsiteUrl",
    user:"/user"
}

export const s3Credential = {
    bucketName: "link-front",
    region: "us-east-1",
    accessKeyId: "AKIASEETTKSEDSPM6UWP",
    secretAccessKey: "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",
    s3EndPoint: "https://link-front.s3.us-east-1.amazonaws.com/",
    fileSize: "30000000",
    dirName: "",
}

export const firebaseCredential = {
    apiKey: "AIzaSyDcyni8XdZFbqSUecplgLbXbHb5W1pKxG0",
    authDomain: "linkfrontapp.firebaseapp.com",
    databaseURL: "https://linkfrontapp-default-rtdb.firebaseio.com",
    projectId: "linkfrontapp",
    storageBucket: "linkfrontapp.appspot.com",
    messagingSenderId: "53015513841",
    appId: "1:53015513841:web:7906964d9d499e5e993750",
    measurementId: "G-5V981H38T4"
}

export const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
}
