import React from 'react'
import Skeleton from 'react-loading-skeleton'

const RollupCardSkeleton = () => {
    return (
        <>
            {
                Array(3).fill(null)?.map((data, index) => (
                    <div className="mb-20">
                        <div key={index} className="rollup-card ">
                            <div className='text-center border-bottom mb-30 pb-30'>
                                <Skeleton count={1} width={120} />
                            </div>

                            <div className="sub-name-status-div">
                                <div className="d-flex align-items-center">
                                    <Skeleton width={60} height={60} circle={true} />
                                    <Skeleton className='ms-3' count={1} width={100} />
                                </div>
                                <div className="status-div" style={{ marginTop: "-5px" }}>
                                    <Skeleton count={1} width={50} />
                                </div>
                            </div>

                            <div className="rollup-card-details">
                                <div className="main-rollup-card-img">
                                    <Skeleton count={1} className="w-100 h-100" borderRadius={15} />
                                </div>

                                <div className="rollup-card-content ms-0 mt-3 mt-sm-0 ms-sm-4">
                                    <h4 className="mb-1">
                                        <Skeleton count={2.5} width={100} />
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default RollupCardSkeleton