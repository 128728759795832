import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetPosts = (params) => api.get(apiUrl.posts, { params })
export const GetSinglePost = (id) => api.get(`${apiUrl.posts}/${id}`)
export const AddPost = (data) => api.post(apiUrl.posts, data)
export const UpdatePost = (id, data) => api.put(`${apiUrl.posts}/${id}`, data)
export const DeletePost = (id) => api.delete(`${apiUrl.posts}/${id}`)
export const AddRemovePostReaction = (data) => api.post(apiUrl.reactions, data)
export const AddRemovePostFromFavourite = (data) => api.put(apiUrl.post_favorite_add_remove, data)
export const GetFavouritePosts = (params) => api.get(apiUrl.favourite_post, { params })
export const GetSchedulePosts = (params) => api.get(apiUrl.schedule_posts, { params })
export const SharePost = (data) => api.post(apiUrl.share_post, data)

