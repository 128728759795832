import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink, Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { error } from '../../constants/msg'
import { SearchOutlineLg, Times } from '../../constants/svgs'
import { AddUserSearch, ClearUserSearch, GetSearchHistory, GetSuggestions } from '../../services/search/search'
import RecentSearchList from '../dashboard/RecentSearchList'
import SearchSuggesionList from '../dashboard/SearchSuggesionList'
import { useDebouncedCallback } from 'use-debounce';

const SearchPopup = ({ searchPopup, setSearchPopup }) => {
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [isLoading, setIsLoading] = useState(false)
    const [recentSearchData, setRecentSearchData] = useState([])
    const [suggestionData, setSuggestionData] = useState(null)
    const [searchText, setSearchText] = useState("")

    const getSearchHistory = async () => {
        // Fetch user recently searches
        setIsLoading(true)
        try {
            const params = {
                orderBy: "id",
                sortedBy: "desc",
                page: 1,
                me: 1,
                limit: 5,

            }
            const res = await GetSearchHistory(params)
            setRecentSearchData(res.data.data.data)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const clearUserSearch = async (id, index) => {
        // Remove user recent search and also remove it from array
        try {
            const temp = [...recentSearchData]
            temp.splice(index, 1)
            setRecentSearchData(temp)
            await ClearUserSearch(id)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const addUserSearch = async () => {
        try {
            await AddUserSearch({ keywords: searchText })
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const handleSearch = useDebouncedCallback(async (keyword) => {
        // Check if search input is empty than fetch recent search data else fetch search suggestions
        if (keyword.length == 0) {
            setSuggestionData()
            getSearchHistory()
        }
        else {
            setIsLoading(true)
            try {
                const resBrand = await GetSuggestions(keyword, "brand-owner")
                const resShopper = await GetSuggestions(keyword, "shopper")
                addUserSearch()

                setSuggestionData({
                    brands: resBrand.data.data.data,
                    shoppers: resShopper.data.data.data
                })
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                error(e.response.data.message)
            }
        }
    }, 1000);

    const resetInputAndSuggestionsList = () => {
        setSearchText("")
        setSuggestionData(null)
    }

    useEffect(() => {
        /*  First time when run searchText = "" it run recent search api 
            When we are closing popup we set searchText = "" and also empty suggestion array so that
            we can show recent searches
        */

        handleSearch(searchText)
    }, [searchText])

    return (
        <>
            <ToastContainer />
            <Modal
                className='search-popup'
                show={searchPopup}
                onHide={() => {
                    if (!isLoading) {
                        setSearchPopup(false)
                        resetInputAndSuggestionsList()
                    }
                }}
                size="lg"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="search-wrapper">
                        <div className="search-box">
                            <div className='d-flex align-items-center justify-content-between h-100'>
                                <div className='w-100 h-100'>
                                    <Form.Control
                                        value={searchText}
                                        disabled={isLoading}
                                        className='w-100'
                                        type="text"
                                        placeholder="Search"
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>

                                <div className='d-flex align-items-center'>
                                    <Button
                                        disabled={isLoading}
                                        className='time-icon-button'
                                        onClick={() => resetInputAndSuggestionsList()}
                                    >
                                        <Times />
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {
                            (recentSearchData.length > 0 && !suggestionData || (recentSearchData.length == 0 && !suggestionData)) ?
                                <RecentSearchList isLoading={isLoading} recentSearchData={recentSearchData} clearUserSearch={clearUserSearch} setSearchText={setSearchText} setSearchPopup={setSearchPopup} />
                                :
                                <SearchSuggesionList searchText={searchText} isLoading={isLoading} suggestionData={suggestionData} resetInputAndSuggestionsList={resetInputAndSuggestionsList} setSearchPopup={setSearchPopup} />
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SearchPopup
