import React, { useContext } from 'react'
import { Form } from "react-bootstrap"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AddProductContext } from '../../App'
import Assets from '../../constants/images'

const SelectImageCard = ({ data }) => {
    const [addProduct, setAddProduct] = useContext(AddProductContext)

    const handleAddOrRemoveProduct = (data) => {
        const index = addProduct.findIndex(product => product.id === data.id);    // return -1 if product is not already selected
        if (index === -1) {
            /* 
                If product is not selected than add in array
                Restrict user to add max 10 products only
            */
            if (addProduct.length <= 10) { 
                setAddProduct([
                    ...addProduct,
                    data
                ])
            }
        }
        else {
            // remove product from array on unselect the post
            addProduct.splice(index, 1)
            setAddProduct([...addProduct])
        }
    }

    const returnCheckedStatus = (data) => {
        // return false is product is not checked and true on checked 
        const index = addProduct.findIndex(product => product.id === data.id)
        if (index === -1) {
            return false
        }
        else {
            return true
        }
    }

    return (
        <div className='select-img-card'>
            <label htmlFor={data?.id}>
                <Form.Check readOnly id={data?.id} type="checkbox" checked={returnCheckedStatus(data)} />
                <div onClick={() => handleAddOrRemoveProduct(data)}>
                    <img className='img-fluid' src={data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                </div>
            </label>
        </div>
    )
}

export default SelectImageCard