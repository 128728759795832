import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const PostCardSkeleton = () => {
    return (
        <>
            {
                Array(6).fill(null)?.map((data, index) => (
                    <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                        <div className='post-card'>
                            <Skeleton style={{ borderRadius: "10px" }} height={250} />
                        </div>
                    </Col>
                ))
            }
        </>
    )
}

export default PostCardSkeleton