import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Row, Col } from "react-bootstrap"

const EditPostSkeleton = () => {
    return (
        <div className={`create-edit-post-form h-auto`}>
            <div className='text-center mb-30 pb-30'>
                <h3><Skeleton width={120} /></h3>
            </div>

            <div>
                <Skeleton style={{ width: "100%", borderRadius: "10px" }} height={180} />

                <div className='my-4'>
                    <Skeleton width={100} />
                    <Skeleton style={{ width: "100%", borderRadius: "10px" }} height={100} />
                </div>

                <div className="added-product-wrapper">
                    <div className='pb-3'>
                        <h5><Skeleton width={100} /></h5>
                    </div>

                    <div className="wrapper d-flex align-items-center flex-wrap">
                        {
                            Array(3).fill(null)?.map((data, index) => (
                                <Skeleton key={index} style={{ borderRadius: "5px" }} className='me-3' width={50} height={50} />
                            ))
                        }
                    </div>

                    <div className="mt-4">
                        <Row>
                            <Col xs={12} className="mb-3 mb-sm-0">
                                <Skeleton style={{ width: "100%", borderRadius: "10px" }} height={60} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPostSkeleton