import api from "../index";
import { apiUrl } from "../../utils/constants";
import axios from "axios";

export const GetFirebaseToken = (token) => axios.get(apiUrl.create_firebase_token, {
    headers: {
        Authorization: 'Bearer ' + token
    }
})
export const SocialLogin = (data) => api.post(apiUrl.social_login, data)
export const Login = (data) => api.post(apiUrl.login, data)
export const Register = (data) => api.post(apiUrl.register, data)
export const VerifyOtp = (data) => api.post(apiUrl.verify_otp, data)
export const VerifyResetOtp = (data) => api.post(apiUrl.verify_reset_code, data)
export const ResendOtp = (email) => api.post(apiUrl.resend_otp, email)
export const ChangePassword = (data) => api.post(apiUrl.change_password, data)
export const ForgotPassword = (email) => api.post(apiUrl.forgot_password, email)
export const ResetPassword = (data) => api.post(apiUrl.reset_password, data)
export const Logout = () => api.post(apiUrl.logout)

