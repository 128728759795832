import React, { useState, useContext, useEffect } from 'react'
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { AddFolderPopupContext, UserData } from '../../App'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { GlobeFill, Times } from '../../constants/svgs'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader'
import { AddFolder } from '../../services/folders/folder'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { GetUser, UpdateUser, UpdateWebsiteUrl } from '../../services/users/users'

const ChangeDomainPopup = ({ changeDomainPopup, setChangeDomainPopup, asLink, redirectLink }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const { register, handleSubmit, reset, atch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const navigate = useNavigate()

    useEffect(() => {
        // Reset input when popup is open or close
        reset({
            website_url: userData?.brand_owner?.website_url || ""
        })
    }, [changeDomainPopup])

    const onSubmit = async (data) => {
        if (asLink) {
            navigate("/generate-token")
        }
        else {
            setIsDisabled(true)
            try {
                const res = await UpdateWebsiteUrl(data)
                const response = await GetUser(userData?.id)
                setUserData(response?.data?.data?.user)
                SetAuthUserLocalStorage(response?.data?.data?.user)
                setIsDisabled(false)
                success(res.data.message)
                setTimeout(() => {
                    navigate(redirectLink)
                }, 1200)
            }
            catch (e) {
                setIsDisabled(false)
                error(e.response.data.message)
            }
            setChangeDomainPopup(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup change-domain-popup'
                show={changeDomainPopup}
                onHide={() => setChangeDomainPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="wrapper">
                            <div className='d-flex align-items-center text-center mb-5 pb-30 border-bottom'>
                                <h3 className='w-100 d-inline-block'>DOMAIN CHANGE</h3>
                                <div className="text-end times-icon-wrapper" onClick={() => setChangeDomainPopup(false)}>
                                    <Times />
                                </div>
                            </div>

                            <div className='content-container text-center'>
                                <div className="text-start">
                                    <label className="mb-1">Website URL</label>
                                    <FloatingLabel className="mt-1" controlId="websiteUrl">
                                        <Form.Control
                                            type="text"
                                            {...register("website_url",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.WEBSITE_URL_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                                        message: VALIDATIONS_TEXT.INVALID_WEBSITE_URL
                                                    }
                                                })
                                            }
                                        />
                                        <div className="icon-wrapper">
                                            <GlobeFill className='icon' />
                                        </div>
                                    </FloatingLabel>
                                    {errors.website_url && <small className='text-start d-block text-danger mt-1'>{errors.website_url.message}</small>}
                                </div>

                                <div className="mt-40">
                                    <Button type="submit" className="w-100 btn-solid mb-2">
                                        {
                                            isDisabled ? <Loader /> : 'Change domain'
                                        }

                                    </Button>
                                    <Button className="w-100 btn-transparent" onClick={() => setChangeDomainPopup(false)}>CANCEL</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeDomainPopup