import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { UserData, UserRole } from '../App'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import BrandFolderTabs from '../components/dashboard/BrandFolderTabs'
import ShopperFolderTabs from '../components/dashboard/ShopperFolderTabs'
import ViewMyProfileCard from '../components/dashboard/ViewMyProfileCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import PostCard from '../components/dashboard/PostCard'
import { ROLES } from '../constants/app-constants'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ViewMyProfilePage = () => {
    const location = useLocation()
    const [userRole, setUserRole] = useContext(UserRole)
    const [userData, setUserData] = useContext(UserData)
    const folderParams = {}

    const userTabs = {
        [ROLES.shopper]: <ShopperFolderTabs folderParams={folderParams} link="/my-profile" userId={userData?.id} />,
        [ROLES.brand]: <BrandFolderTabs folderParams={folderParams} link="/my-profile" userId={userData?.id} />,
    }

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="" link3Text="" link4="" link4Text="" />
            <div className='pages view-profile-page'>
                <div className="pb-4">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <ViewMyProfileCard />
                            </Col>

                            <Col xs={12}>
                                <div className="profile">
                                    {userTabs[userRole]}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ViewMyProfilePage