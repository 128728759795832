import React, { useContext, useState, useEffect } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { UserRole, UserData } from '../App'
import RollUpCard from '../components/dashboard/RollUpCard'
import StatsCard from '../components/dashboard/StatsCard'
import WithdrawlCard from '../components/dashboard/WithdrawlCard'
import WithdrawlPopup from '../components/popup/WithdrawlPopup'
import { WALLET_TABS } from '../constants/app-constants'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { roles, transactions } from '../utils/constants'
import { GetUser } from "../services/users/users";
import { error, success } from '../constants/msg';
import { SetAuthUserLocalStorage } from '../services/localStorage/localStorage'
import { GetTopUpTransactions } from '../services/transaction/transaction'

const WalletPage = () => {
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [activeTab, setActiveTab] = useState(WALLET_TABS.withdrawl_history)
    const [user, setUser] = useState([])

    const [hasMore, setHasMore] = useState(true);
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [totalPage, setTotalPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState([])

    const getTopUpTransactions = async () => {
        if (currentPage > 1) {
            setIsLoadMore(true);
        }
        try {
            const params = {
                me: 1,
                type: userRole === roles.shopper ? transactions.DEBIT : transactions.CREDIT,
                page: currentPage,
                // user_id: userData?.id
            }
            const res = await GetTopUpTransactions(params)
            setTotalPage(res.data.data.last_page)
            setData(data.concat(res.data.data.data))
            setIsLoading(false)
            setIsLoadMore(false)
            if (currentPage == res.data.data.last_page) {
                setHasMore(false)
            }
        }
        catch (e) {
            setIsLoading(false)
            setIsLoadMore(false)
            error(e.response.data.message)
        }
    }

    const getTopUpTransactionsWithoutPagination = async () => {
        if (currentPage > 1) {
            setIsLoadMore(true);
        }
        try {
            const params = {
                me: 1,
                type: userRole === roles.shopper ? transactions.DEBIT : transactions.CREDIT,
                page: currentPage,
                // user_id: userData?.id
            }
            const res = await GetTopUpTransactions(params)
            setTotalPage(res.data.data.last_page)
            setData(res.data.data.data)
            setIsLoading(false)
            setIsLoadMore(false)
            if (currentPage == res.data.data.last_page) {
                setHasMore(false)
            }
        }
        catch (e) {
            setIsLoading(false)
            setIsLoadMore(false)
            error(e.response.data.message)
        }
    }

    const getProfile = async () => {
        try {
            const res = await GetUser(userData.id)
            const user = res.data.data.user
            setUser(user)
            setUserData(res.data.data.user)
            SetAuthUserLocalStorage(res.data.data.user)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        getTopUpTransactions()
        getProfile()
    }, [])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Wallet" link4="" link4Text="" />

            <div className='pages wallet-page'>
                <div className='pb-4'>
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <StatsCard fetchData={getTopUpTransactionsWithoutPagination} dollarData={userData?.wallet} showBtn={true} text1="Available" text2="Credit:" lineBreak={false} />
                            </Col>

                            <Col xs={12}>
                                <Tabs
                                    defaultActiveKey={WALLET_TABS.withdrawl_history}
                                    className="theme-tabs"
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                >
                                    <Tab className='mt-40' eventKey={WALLET_TABS.withdrawl_history} title={userRole === roles.shopper ? "WITHDRAWL HISTORY" : "TOP UP HISTORY"}>
                                        <Row className='justify-content-center'>
                                            <Col xs={12} sm={10} md={8} lg={6} xl={6}>
                                                <WithdrawlCard setCurrentPage={setCurrentPage} isLoading={isLoading} isLoadMore={isLoadMore} hasMore={hasMore} totalPage={totalPage} currentPage={currentPage} data={data} />
                                            </Col>
                                        </Row>
                                    </Tab>

                                    {/* <Tab className='mt-40' eventKey={WALLET_TABS.commission_history} title={userRole === roles.shopper ? "COMMISSION HISTORY" : "TRANSACTION HISTORY"}>
                                        <RollUpCard />
                                    </Tab> */}
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default WalletPage