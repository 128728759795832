import React from 'react'
import { Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Assets from '../../constants/images'
import { BankCardFill, ChevronRight, DeleteOutline } from '../../constants/svgs'

const PaymentManagementCard = () => {
    return (
        <div className='payment-management-card'>
            <div>
                <h5 className='pb-30 mb-20 text-center'>payment management</h5>
                <p className="pb-20 mb-20 text-center">
                    Add your payment method to get your commission
                    amout transfer into
                </p>
            </div>

            <div className='box pb-20 mb-20 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <span><BankCardFill /></span>
                    <h5 className='title border-0 ms-2'>Add Bank Account info</h5>
                </div>

                <span className='icon'><ChevronRight /></span>
            </div>

            <h6>Available cards</h6>

            <div className='wrapper mt-20'>
                <div className="overflow-wrapper">
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className="img-wrapper">
                                <img src={Assets.BankCardImg} alt="" />
                            </div>

                            <div className='ms-2'>
                                <span>Abc Bank Limitied</span>
                                <span className='text-grey'>•••• 3456</span>
                            </div>
                        </div>

                        <div className='icon'>
                            <DeleteOutline />
                        </div>
                    </div>
                </div>
            </div>

            <NavLink to="/home" className="text-center w-100 btn-solid mt-4 mb-40">Continue</NavLink>
        </div>
    )
}

export default PaymentManagementCard