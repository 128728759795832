import React, { useContext, useState } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader';
import { STATUS } from '../../constants/app-constants';
import { BlockUnblockUser } from '../../services/users/users';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserData } from '../../App';

const UnblockAlertPopup = ({ unblockPopup, setUnblockPopup, blockedUserData, setBlockedUserData, selectedUser }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)

    const unBlockUser = async () => {
        /* 
            Send id of user whom you want to unblock and status = 0  
            After success remove user from array through index
        */
        setIsDisabled(true)
        try {
            const data = {
                user_id: selectedUser.id,
                status: STATUS.unblock   // 0 for unblock and 1 for block
            }
            const res = await BlockUnblockUser(data)
            const docId = userData?.id + "-" + selectedUser?.id
            const chatRef = doc(db, 'chats', docId);
            await updateDoc(chatRef, {
                is_blocked: false,
                blocked_by: []
            })
            setUnblockPopup(false)
            removeUserFromList(selectedUser.index)
            success("User unblocked successfully")
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    const removeUserFromList = (index) => {
        // Function to remove user from array after unblock
        const temp = [...blockedUserData]
        temp.splice(index, 1)
        setBlockedUserData(temp)
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup unblock-alert-popup'
                show={unblockPopup}
                onHide={() => setUnblockPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>UNBLOCK ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Do you want to unblock <br />
                                    {selectedUser?.name} ?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={unBlockUser}>
                                    {isDisabled ? <Loader /> : "UNBLOCK"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setUnblockPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UnblockAlertPopup