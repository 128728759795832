import React from 'react'
import Skeleton from 'react-loading-skeleton'

const NotificationSkeleton = () => {
    return (
        <>
            {
                Array(8).fill(null)?.map((data, index) => (
                    <div key={index} className="box border-bottom py-3 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="me-2">
                                <Skeleton width={50} height={50} circle={true} />
                            </div>
                            <Skeleton className='mt-1' count={1} width={120} />
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default NotificationSkeleton