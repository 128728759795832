import React, { useEffect, useState } from 'react'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { ToastContainer } from 'react-toastify'
import { Col, Container, Row } from 'react-bootstrap'
import { error } from '../constants/msg'
import axios from 'axios'
import { GetExtensionLink } from '../services/users/users'
import parse from 'html-react-parser';

const ExtensionPage = () => {
    const [data, setData] = useState(null)
    useEffect(() => {
        const getExtensionLink = async () => {
            try {
                const res = await GetExtensionLink()
                setData(res.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getExtensionLink()
    }, [])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Extension" link3="" link3Text="" link4="" link4Text="" />
            <ToastContainer />

            <div className="pages about-page">
                <Container fluid>
                    <Row className='mb-5'>
                        <Col xs={12}>
                            <h1>Creator onboarding resources</h1>
                            <p className='mt-2'>
                                Thank you for choosing the LinkFront. With our extension, you can unlock a world of shopping possibilities
                                and enhance your online shopping experience. Discover the best deals, save money, and shop smarter. Let's
                                get started!
                            </p>
                        </Col>

                        <Col xs={12} className='mt-4 text-center'>
                            {data && parse(data)}
                        </Col>
                    </Row>

                    <Row className='mb-5'>
                        <Col xs={12} xl={6} className='mb-2'>
                            <img className='w-100' src={Assets.AppImage1} />
                        </Col>

                        <Col xs={12} xl={6} className='mb-2'>
                            <h2>Getting Started - Web</h2>
                            <p className='mt-2'> <strong>Step 1:</strong> Install LINKFRONT into your bookmarks bar </p>
                            <p className='mt-2'> <strong>Step 2:</strong> Pick your favorite product and create a link using LINKFRONT</p>
                            <p className='mt-2'> <strong>Step 3:</strong> Share the link wherever you’d like! In an IG story, YouTube description box, yourblog, or more!</p>
                        </Col>
                    </Row>

                    <Row className='mb-5'>
                        <Col xs={12} xl={6} className='mb-2 order-2 order-xl-1'>
                            <h2>LINKFRONT WEB - basics</h2>
                            <p className='mt-2'> <strong>Step 1:</strong> Install LINKFRONT into your bookmarks bar </p>
                            <p className='mt-2'> <strong>Step 2:</strong> Pick your favorite product and create a link using LINKFRONT</p>
                            <p className='mt-2'> <strong>Step 3:</strong> Share the link wherever you’d like! In an IG story, YouTube description box, your blog, or more!</p>
                        </Col>

                        <Col xs={12} xl={6} className='mb-2 order-1 order-xl-2'>
                            <img className='w-100' src={Assets.AppImage2} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ExtensionPage