import React, { useContext, useRef, useState } from 'react'
import { UserData } from '../../App';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Assets from '../../constants/images';
import { Button, Form } from 'react-bootstrap';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { TopUp } from '../../services/transaction/transaction';
import { constant } from '../../utils/constants';
import { VALIDATIONS_TEXT } from '../../constants/app-constants';
import StatsCard from './StatsCard';
import { success } from '../../constants/msg';
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage';
import { GetUser } from '../../services/users/users';

const TopupCard = ({ handleSkip, setTransactionData, fetchData }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [userData, setUserData] = useContext(UserData)
    const [amount, setAmount] = useState(0);
    const navigate = useNavigate();
    const { register, handleSubmit, reset, atch, formState: { errors }, } = useForm({ mode: "onChange" });
    const amountRef = useRef(null)

    const onSubmit = async (data) => {

    }

    return (
        <div className="wrapper">
            <div className="d-flex align-items-center text-center mb-20 pb-30 border-bottom">
                <h3 className="w-100 d-inline-block">WALLET</h3>
            </div>

            <div>
                <div>
                    <div className="mb-20 d-flex align-items-center ">
                        <div className="img-wrapper">
                            <img src={userData?.brand_owner?.image_url ?? Assets.BrandAvatarLarge1} alt="" />
                        </div>
                        <span className="ms-2 title">{userData?.brand_owner?.brand_name}</span>
                    </div>
                    <StatsCard
                        showBtn={false}
                        text1="Available"
                        text2="Credit:"
                        dollarData={userData?.wallet}
                        lineBreak={false}
                    />
                </div>

                <div className="content-container text-center mt-20">
                    <div className="text-start">
                        <label className="mb-1">Enter Top Up Amount *</label>
                        <Form.Control
                            ref={amountRef}
                            className="py-3"
                            type="number"
                            inputMode="number"
                            placeholder="Enter Top Up Amount *"
                            onChange={(e) => {
                                setAmount(e.target.value)
                            }}
                        />
                        {errors.amount && (
                            <small className="text-start d-block text-danger mt-1">
                                {errors.amount.message}
                            </small>
                        )}
                    </div>

                    <div className="mt-40">
                        {
                            amount > 0 ?
                                <PayPalScriptProvider options={{ "client-id": constant.Paypal_CLIENT_ID }}>
                                    <PayPalButtons
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: amountRef.current.value,
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then(async (details) => {
                                                const new_data = {
                                                    data: JSON.stringify(details),
                                                    type: "top-up"
                                                }
                                                const res = await TopUp(new_data);
                                                const reponse = await GetUser(userData?.id)
                                                SetAuthUserLocalStorage(reponse?.data?.data?.user)
                                                setUserData(reponse?.data?.data?.user)
                                                setIsDisabled(false)
                                                setAmount(0)
                                                handleSkip()
                                                fetchData()
                                                amountRef.current.value = null
                                                success("Transaction saved successfully")
                                            });
                                        }}
                                    />
                                </PayPalScriptProvider> : null
                        }
                        <Button className="w-100 btn-transparent" onClick={handleSkip}> Skip </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopupCard