import React, { useEffect, useState } from 'react'
import PostCard from './PostCard'
import Assets from '../../constants/images'
import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap'
import PostCardSkeleton from '../skeleton/PostCardSkeleton'
import { GetPosts } from '../../services/posts/posts'
import { error } from '../../constants/msg'
import Loader from '../Loader/Loader'
import TabsSkeleton from '../skeleton/TabsSkeleton'
import { GetFolders } from '../../services/folders/folder'
import { GetProductsByFolder } from '../../services/products/products'

const GalleryWithNoTabs = ({ userId }) => {
    const [isPostLoadMore, setIsPostLoadMore] = useState(false)
    const [isPostLoading, setIsPostLoading] = useState(true)
    const [totalPageForPosts, setTotalPageForPosts] = useState(1)
    const [currentPageForPosts, setCurrentPageForPosts] = useState(1)
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const params = {
                orderBy: "id",
                sortedBy: "desc",
                // is_brand: 0,
                page: currentPageForPosts
            }
            if (currentPageForPosts > 1) {
                setIsPostLoadMore(true)
            }
            try {
                if (userId) {
                    params.user_id = userId
                }
                const res = await GetPosts(params)
                setTotalPageForPosts(res.data.data.last_page)
                setPosts([...posts, ...res.data.data.data])
                setIsPostLoading(false)
                setIsPostLoadMore(false)
            }
            catch (e) {
                setIsPostLoading(false)
                setIsPostLoadMore(false)
                error(e.response.data.message)
            }
        }

        getPosts()
    }, [currentPageForPosts])

    return (
        <>
            <Row>
                {
                    isPostLoading ?
                        <PostCardSkeleton />
                        :
                        posts.length <= 0 ?
                            <div className='text-center mt-4'><h6>No Post Available</h6></div>
                            :
                            posts?.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <PostCard asGallery={true} data={data} link={`/brand/${data?.user?.id}/posts/${data?.id}`} />
                                </Col>
                            ))
                }

                <Col xs={12} className={`${currentPageForPosts == totalPageForPosts ? "d-none" : "d-block"}`}>
                    {
                        isPostLoadMore ?
                            <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPageForPosts(currentPageForPosts + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                    }
                </Col>
            </Row>
        </>
    )
}

export default GalleryWithNoTabs
