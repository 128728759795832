import React, { useState, useEffect, useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { UserData, UserRole } from '../../App';
import { ROLES } from '../../constants/app-constants';
import { error, success } from '../../constants/msg';
import { ResendOtp, VerifyOtp } from '../../services/auth/auth';
import { SetAuthUserLocalStorage, SetTokenLocalStorage, SetUserRoleLocalStorage } from '../../services/localStorage/localStorage';
import Loader from '../Loader/Loader';

const VerificationCodeForm = () => {
    const [counter, setCounter] = useState(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        let timer = counter > 0 && setInterval(() => {
            setCounter(counter - 1)
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [counter])

    const handleOtpChange = (otp) => {
        setOtp(otp)
        if (otp.length === 0) {
            setOtpError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setOtpError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setOtpError(false)
        }
    }

    const handleSetUserRole = (user) => {
        if (user.is_brand == 0) {
            SetUserRoleLocalStorage(ROLES.shopper)
            setUserRole(ROLES.shopper)
        }
        else if (user.is_brand == 1) {
            SetUserRoleLocalStorage(ROLES.brand)
            setUserRole(ROLES.brand)
        }
    }

    const onSubmit = async () => {
        if (otp.length === 0) {
            setOtpError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setOtpError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            /*
                Logic for sending OTP CODE goes here
                Send email, otp and verification-type (register/reset-password) to api
            */
            setIsDisabled(true)
            try {
                const data = {
                    email: location.state.email,
                    type: location.state.type,
                    otp_code: otp,
                }
                const res = await VerifyOtp(data)
                success(res.data.message)
                setIsDisabled(false)
                setTimeout(() => {
                    if (location.state.user) {
                        SetTokenLocalStorage(location.state.user.access_token)
                        SetAuthUserLocalStorage(location.state.user)
                        setUserData(location.state.user)
                        handleSetUserRole(location.state.user)
                        navigate("/home")
                    }
                    else {
                        navigate("/signin")
                    }
                }, 1500)
            }
            catch (e) {
                error(e.response.data.message)
                setIsDisabled(false)
            }
        }
    }

    const resendCode = async () => {
        /* 
            Logic for resending OTP CODE goes here  
            Resend OTP after 30sec on click on resend button
        */
        setCounter(30)
        try {
            const res = await ResendOtp({ email: location.state.email })
            success(res.data.message)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div className='auth-card account-verification-form'>
            <ToastContainer />
            <div>
                <h3 className='pb-30 border-bottom mb-30'>ACCOUNT VERIFICATION</h3>
                <p>
                    Enter the 4 digit code you received <br />
                    on Email address.
                </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-40'>
                    <OtpInput
                        className="otp-input"
                        value={otp}
                        onChange={(otp) => handleOtpChange(otp)}
                        numInputs={4}
                        isInputNum={true}
                        placeholder="----"
                        hasErrored={true}
                        focusStyle="focus"
                    />
                    {otpError && <small className='text-start d-block text-danger mt-1'>{errorMessage}</small>}

                    <Button disabled={isDisabled} type="submit" className='mt-40 mb-30 btn-solid'>
                        {isDisabled ? <Loader /> : "VERIFY ACCOUNT"}
                    </Button>
                    {
                        counter > 0 ?
                            <span className='mb-20'>Resend in: &nbsp; <b className='timer'> 00:{counter < 10 && '0'}{counter}</b></span>
                            :
                            <span className='mb-20' onClick={() => resendCode()}><b className='timer cursor'>Resend Code</b></span>
                    }
                </div>
            </Form>
        </div>
    )
}

export default VerificationCodeForm