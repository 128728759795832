import React, { useEffect, useState, useContext } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import DashboardBreadCrumb from '../../layouts/dashboard/DashboardBreadCrumb';
import Loader from '../Loader/Loader';
import DomainVerificationCompletePopup from '../popup/DomainVerificationCompletePopup';
import { BrandCode } from '../../services/brands/brand'
import { error, success } from '../../constants/msg'
import { UserData } from '../../App'
import ChangeDomainPopup from '../popup/ChangeDomainPopup';
import { AiOutlinePoweroff } from 'react-icons/ai';
import LogoutPopup from '../popup/LogoutPopup';


const DomainInformationForm = ({ withBreadcrumb }) => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({ mode: "onChange" });
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserData)
    const location = useLocation();
    const [isDisabled, setIsDisabled] = useState(false)
    const [domainVerificationCompletePopup, setDomainVerificationCompletePopup] = useState(false)
    const [changeDomainPopup, setChangeDomainPopup] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)

    const onSubmit = async (data) => {
        data['website_url'] = userData?.brand_owner?.website_url
        setIsDisabled(true)
        try {
            const res = await BrandCode(data);
            success(res?.data?.message)
            setTimeout(() => {
                navigate("/verify-code")
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
        // setDomainVerificationCompletePopup(true)
    }

    return (
        <>
            <DomainVerificationCompletePopup domainVerificationCompletePopup={domainVerificationCompletePopup} setDomainVerificationCompletePopup={setDomainVerificationCompletePopup} />
            <ChangeDomainPopup changeDomainPopup={changeDomainPopup} setChangeDomainPopup={setChangeDomainPopup} asLink={false} redirectLink="/generate-token" />
            <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />

            {withBreadcrumb && <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Domain Verification" link3="" link3Text="" link4="" link4Text="" />}

            <div className="auth-card domain-information-form">
                <div>
                    <div className='d-flex align-items-center pb-30 border-bottom mb-30'>
                        <h3 className='mx-auto'>Domain Verification</h3>
                        {
                            userData && userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 0 &&
                            <span onClick={() => setLogoutPopup(true)}><AiOutlinePoweroff className='icon-off' /></span>
                        }
                    </div>

                    <p className="text-black">
                        Please add page name for verification
                    </p>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mt-30'>
                        <Form.Control
                            type="text"
                            inputMode='text'
                            placeholder="Product Param Index"
                            {...register("product_slug_index",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: `${"Product Param"} ${VALIDATIONS_TEXT.INDEX_REQUIRED}`
                                    },
                                })
                            }
                        />
                        {errors.product_slug_index && <small className='text-start d-block text-danger mt-1'>{errors.product_slug_index.message}</small>}
                    </div>

                    <div className='mt-20'>
                        <Form.Control
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="Cart Page Name"
                            {...register("cart_page",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: `${'Cart Page'} ${VALIDATIONS_TEXT.NAME_REQUIRED}`
                                    },
                                })
                            }
                        />
                        {errors.cart_page && <small className='text-start d-block text-danger mt-1'>{errors.cart_page.message}</small>}
                    </div>

                    <div className='mt-20'>
                        <Form.Control
                            type="text"
                            inputMode='text'
                            placeholder="Cart Page Param"
                            {...register("cart_page_index",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: `${'Cart Page'} ${VALIDATIONS_TEXT.INDEX_REQUIRED}`
                                    },
                                })
                            }
                        />
                        {errors.cart_page_index && <small className='text-start d-block text-danger mt-1'>{errors.cart_page_index.message}</small>}
                    </div>

                    <div className='mt-20'>
                        <Form.Control
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="Thank you Page Name"
                            {...register("thank_you_page",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: `Thank you Page ${VALIDATIONS_TEXT.NAME_REQUIRED}`
                                    },
                                })
                            }
                        />
                        {errors.thank_you_page && <small className='text-start d-block text-danger mt-1'>{errors.thank_you_page.message}</small>}
                    </div>

                    <div className='mt-20'>
                        <Form.Control
                            type="text"
                            inputMode='text'
                            placeholder="Thank you Page Param Index"
                            {...register("thank_you_page_index",
                                {
                                    required: {
                                        value: true,
                                        message: `Thank you Page Param ${VALIDATIONS_TEXT.INDEX_REQUIRED}`
                                    },
                                })
                            }
                        />
                        {errors.thank_you_page_index && <small className='text-start d-block text-danger mt-1'>{errors.thank_you_page_index.message}</small>}
                    </div>

                    <Button type="submit" disabled={isDisabled} className='mt-40 mb-2 btn-solid'>
                        {isDisabled ? <Loader /> : "Verify Domain"}
                    </Button>

                    <Button onClick={() => setChangeDomainPopup(true)} className="btn-transparent">Change Domain</Button>
                </Form>
            </div>
        </>
    )
}

export default DomainInformationForm