import React from 'react'
import { Link } from 'react-router-dom'
import { error } from '../../constants/msg'
import { Times } from '../../constants/svgs'
import { AddUserSearch } from '../../services/search/search'
import Loader from '../Loader/Loader'

const SearchSuggesionList = ({ searchText, isLoading, suggestionData, resetInputAndSuggestionsList, setSearchPopup }) => {

    const addUserSearch = async () => {
        try {
            await AddUserSearch({ keywords: searchText })
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div className='suggestion-box'>
            <div className='title-div'>
                <h6>Search Suggestions</h6>
                <hr />
            </div>
            <div className="suggest-list pt-2">
                <ul>
                    {
                        isLoading ?
                            <li className='text-center'><Loader /></li>
                            :
                            suggestionData?.brands?.length <= 0 && suggestionData?.shoppers?.length <= 0 ?
                                <li className='text-center'>No result found</li>
                                :
                                <>
                                    {
                                        suggestionData?.brands?.length > 0 &&
                                        <>
                                            <li><b>Brands</b></li>
                                            {
                                                suggestionData?.brands?.map((brand, index) => (
                                                    !brand?.is_blocked &&
                                                    <li key={index}>
                                                        <Link
                                                            to={`/brand/${brand?.brand_owner?.user_id}`} className='d-flex align-items-center justify-content-between'
                                                            onClick={() => {
                                                                // addUserSearch()
                                                                setSearchPopup(false)
                                                                resetInputAndSuggestionsList()
                                                            }}
                                                        >
                                                            <h6 className='d-block w-100 text-capitalize'><b>{index + 1})</b> &nbsp;&nbsp; {brand?.brand_owner?.brand_name}</h6>
                                                        </Link>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    }

                                    {
                                        suggestionData?.shoppers?.length > 0 &&
                                        <>
                                            <li><b>Shoppers</b></li>
                                            {
                                                suggestionData?.shoppers?.map((shopper, index) => (
                                                    !shopper?.is_blocked &&
                                                    <li key={index}>
                                                        <Link
                                                            to={`/user/${shopper?.shopper?.user_id}`} className='d-flex align-items-center justify-content-between'
                                                            onClick={() => {
                                                                addUserSearch(shopper?.full_name)
                                                                setSearchPopup(false)
                                                                resetInputAndSuggestionsList()
                                                            }}
                                                        >
                                                            <h6 className='d-block w-100 text-capitalize'><b>{index + 1})</b> &nbsp;&nbsp; {shopper?.shopper?.full_name}</h6>
                                                        </Link>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    }
                                </>
                    }
                </ul>
            </div>
        </div>
    )
}

export default SearchSuggesionList