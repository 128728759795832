import React, { useContext, useRef, useEffect, useState } from 'react'
import Assets from '../../constants/images'
import { CameraFill, DotsHorizontal } from "../../constants/svgs"
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap"
import { IoMdSend } from "react-icons/io"
import { useForm } from 'react-hook-form'
import { BsThreeDotsVertical } from "react-icons/bs"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import InfiniteScroll from 'react-infinite-scroller'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetComments, PostComment, UpdateComment } from '../../services/comments/comments'
import { success, error } from '../../constants/msg'
import moment from 'moment'
import CommentCardSkeleton from '../skeleton/CommentCardSkeleton'
import { TbMessageCircle } from "react-icons/tb"
import { ToastContainer } from 'react-toastify';
import { INSTANCE_TYPE } from '../../constants/app-constants'
import Loader from '../Loader/Loader'
import Skeleton from 'react-loading-skeleton'
import DeleteCommentPopup from '../popup/DeleteCommentPopup'
import { UserData } from '../../App'
import { insertLineBreak } from '../../utils/helper'
import { GetSinglePost } from '../../services/posts/posts'

const PostCommentCard = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserData)
    const [commentText, setCommentText] = useState("")
    const [comments, setComments] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [deleteCommentPopup, setDeleteCommentPopup] = useState(false)
    const [editComment, setEditComment] = useState(false)
    const [selectedComment, setSelectedComment] = useState({ id: "", index: "" })
    const [post, setPost] = useState()

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const getComments = async () => {
        // fetch comments of the post
        try {
            const params = {
                instance_id: slug,
                page: currentPage,
            }
            const res = await GetComments(params)
            setCurrentPage(currentPage + 1)
            setComments([...comments, ...res.data.data.data])
            if (currentPage == res.data.data.last_page) {
                setHasMore(false)
            }
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const getPublishedDateTime = (created_at) => {
        const timestamp = moment.utc(created_at).fromNow()  // return time like 1 hours ago     
        return timestamp
    }

    const resetCommentInput = () => {
        setCommentText("")
        reset({ comment: "" })
    }

    const handleClickOnEdit = (data, index) => {
        setEditComment(true)
        setCommentText(data?.comment)
        reset({
            comment: data?.comment
        })
        setSelectedComment({
            id: data?.id,
            index: index
        })
    }

    const sendComment = async (comment) => {
        try {
            let commentData = {
                instance_id: slug, // post id
                instance_type: INSTANCE_TYPE.post,
                comment: comment,
            }

            resetCommentInput()
            const res = await PostComment(commentData)
            const newlyPostComment = res.data.data
            setComments([
                newlyPostComment,
                ...comments,
            ])
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const updateComment = async (comment) => {
        try {
            let commentData = {
                instance_id: slug, // post id
                instance_type: INSTANCE_TYPE.post,
                comment: comment,
            }

            resetCommentInput()
            const res = await UpdateComment(selectedComment.id, commentData)
            const commentAfterEdit = res.data.data
            const temp = [...comments]
            temp[selectedComment.index].comment = commentAfterEdit.comment
            setComments(temp)
            setEditComment(false)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const handleNavigate = (user) => {
        if (user?.is_blocked) {
            navigate("/not-found")
        }
        else {
            if (user?.brand_owner) {
                if (user?.brand_owner?.user_id == userData?.id) {
                    navigate("/my-profile")
                }
                else {
                    navigate(`/brand/${user?.brand_owner?.user_id}`)
                }
            }
            else {
                if (user?.shopper?.user_id == userData?.id) {
                    navigate("/my-profile")
                }
                else {
                    navigate(`/user/${user?.shopper?.user_id}`)
                }
            }
        }
    }

    const getCommentCount = () => {
        let count = 0
        comments?.map((data, index) => {
            if (data?.user !== null && !data?.user?.is_blocked) {
                count++
            }
        })

        return count
    }

    const onSubmit = async (data) => {
        /* 
            Check if editComment is true than hit edit api else use post api
            After success reset input and hook-form  
        */
        const comment = data.comment
        if (editComment) {
            await updateComment(comment)
        }
        else {
            await sendComment(comment)
        }

    }

    useEffect(() => {
        const getPost = async () => {
            // fetch post by id
            try {
                const res = await GetSinglePost(slug)
                const data = res.data.data
                setPost(data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getPost()
    }, [slug])

    return (
        <>
            <DeleteCommentPopup selectedComment={selectedComment} comments={comments} setComments={setComments} deleteCommentPopup={deleteCommentPopup} setDeleteCommentPopup={setDeleteCommentPopup} />
            <ToastContainer />
            <div className='post-comment-card'>
                <div className='text-center border-bottom mb-2 pb-30'>
                    {
                        hasMore && isLoading ?
                            <Skeleton width={200} /> :
                            <h3>Comment <span>({getCommentCount()})</span></h3>
                    }
                </div>

                <div className="box-wrapper">
                    <InfiniteScroll
                        pageStart={currentPage}
                        threshold={500}
                        useWindow={false}
                        loadMore={getComments}
                        hasMore={hasMore}
                        loader={<div className='mt-2'><CommentCardSkeleton /></div>}
                    >
                        {
                            (comments.length <= 0 || getCommentCount() <= 0) && !isLoading ?
                                <div className="mt-4 no-comment-wrapper text-center">
                                    <TbMessageCircle className='icon-no-comment' />
                                    <h6 className='no-comment mt-2'>0 comments yet</h6>
                                </div>
                                :
                                comments?.map((data, index) => (
                                    (data?.user !== null && !data?.user?.is_blocked) &&
                                    <div key={index} className="box pb-3 mt-3">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div className="info-box">
                                                <div className='d-flex'>
                                                    <div className="profile-wrapper cursor-pointer">
                                                        <img onClick={() => handleNavigate(data?.user)} src={data?.user?.brand_owner ? data?.user?.brand_owner?.image_url : data?.user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                                    </div>
                                                    <div className='ms-2'>
                                                        <div>
                                                            <h6 className='text-capitalize cursor-pointer' onClick={() => handleNavigate(data?.user)}>{data?.user?.brand_owner ? data?.user?.brand_owner?.brand_name : data?.user?.shopper?.full_name}</h6>
                                                            <p dangerouslySetInnerHTML={{ __html: insertLineBreak(data?.comment) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="time-box d-flex justify-content-end align-items-center text-end">
                                                <small className='me-1'>{getPublishedDateTime(data?.created_at)}</small>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <BsThreeDotsVertical className='icon' />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            className={`border-bottom ${data?.user?.id == userData.id ? 'd-block' : 'd-none'}`}
                                                            onClick={() => handleClickOnEdit(data, index)}
                                                        >
                                                            Edit Comment
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className={`border-bottom ${(post?.user?.id == userData?.id || data?.user?.id == userData?.id) ? "d-block" : "d-none"}`}
                                                            onClick={() => {
                                                                setDeleteCommentPopup(true)
                                                                setSelectedComment({
                                                                    id: data?.id,
                                                                    index: index
                                                                })
                                                            }}
                                                        >
                                                            Delete Comment
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>Cancel</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </InfiniteScroll>
                </div>

                <div className="message-container">
                    <div className="message-wrapper mt-3">
                        <div className="profile-wrapper">
                            <img src={userData?.brand_owner ? userData?.brand_owner?.image_url : userData?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <InputGroup className="p-2">
                                <Form.Control
                                    as="textarea"
                                    rows={0}
                                    placeholder="Write a comment..."
                                    {...register('comment', {
                                        onChange: (e) => {
                                            setCommentText(e.target.value)
                                            if (e.target.value.length <= 0) {
                                                setEditComment(false)
                                            }
                                        }
                                    })}
                                />
                                <InputGroup.Text>
                                    <Button disabled={(commentText.length <= 0) ? true : false} type="submit" className="btn-send">
                                        <IoMdSend className='icon' />
                                    </Button>
                                    {/* <label className="icon-wrapper" htmlFor="camera">
                                    <input accept='image/*' type="file" id="camera" className='d-none' />
                                    <CameraFill />
                                </label> */}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default PostCommentCard