import React, { useEffect, useState, useContext } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { VALIDATIONS_TEXT } from '../../constants/app-constants';
import DashboardBreadCrumb from '../../layouts/dashboard/DashboardBreadCrumb';
import Loader from '../Loader/Loader';
import { CodeVerify } from '../../services/brands/brand'
import { error, success } from '../../constants/msg'
import { UserData } from '../../App'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage';
import { GetUser } from '../../services/users/users';
import LogoutPopup from '../popup/LogoutPopup';
import { AiOutlinePoweroff } from 'react-icons/ai';

const CodeVerifyForm = ({ withBreadcrumb }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useContext(UserData)
    const brand_id = userData?.brand_owner?.id;
    const website_url = userData?.brand_owner?.website_url;
    const [isDisabled, setIsDisabled] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)

    const onSubmit = async () => {
        setIsDisabled(true)
        try {
            const res = await CodeVerify()
            const response = await GetUser(userData?.id)
            SetAuthUserLocalStorage(response.data.data.user)
            setUserData(response.data.data.user)
            setIsDisabled(false)
            success(res?.data?.message)

            setTimeout(() => {
                navigate("/topup")
            }, 1500)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    return (
        <>
            {withBreadcrumb && <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Domain Verification" link3="" link3Text="" link4="" link4Text="" />}

            <div className={`auth-card domain-verification-form ${withBreadcrumb === true && 'mt-5'} `}>
                <ToastContainer />
                <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />

                <div>
                    <div className='d-flex align-items-center pb-30 border-bottom mb-30'>
                        <h3 className='mx-auto'>Code Verification</h3>
                        {
                            userData && userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 0 &&
                            <span onClick={() => setLogoutPopup(true)}><AiOutlinePoweroff className='icon-off' /></span>
                        }
                    </div>

                    <p className="text-black">
                        Your domain is not verified. Please follow the steps that have been emailed.
                    </p>
                </div>

                <div className='mt-30'>
                    <Button onClick={onSubmit} disabled={isDisabled} className='mt-40 mb-20 btn-solid'>
                        {isDisabled ? <Loader /> : "Continue"}
                    </Button>

                    <Link to="/change-domain" className="mt-20 mb-20 btn-solid">Change Domain</Link>
                </div>
            </div>
        </>
    )
}

export default CodeVerifyForm