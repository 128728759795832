import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tabs, Tab, Button } from 'react-bootstrap'
import Assets from '../constants/images'
import PostCard from '../components/dashboard/PostCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { GetUserRecentActivity } from '../services/users/users'
import { error } from '../constants/msg'
import Loader from '../components/Loader/Loader'
import PostCardSkeleton from '../components/skeleton/PostCardSkeleton'

const RecentActivityPage = () => {
    const [recentActivities, setRecentActivities] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)

    useEffect(() => {
        const getUserRecentActivity = async () => {
            // fetch user activites which he made recently

            if (currentPage > 1) {
                setIsLoadMore(true)
            }
            try {
                const params = {
                    page: currentPage
                }
                const res = await GetUserRecentActivity(params)
                setTotalPage(res.data.data.last_page)
                setRecentActivities([...recentActivities, ...res.data.data.data])
                setIsLoading(false)
                setIsLoadMore(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsLoadMore(false)
                error(e.response.data.message)
            }
        }

        getUserRecentActivity()
    }, [currentPage])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Recent Activity" link3="" link3Text="" link4="" link4Text="" />
            <div className='pages recent-activity-page'>
                <div className='pb-4'>
                    <Container>
                        <Row>
                            {
                                isLoading ?
                                    <PostCardSkeleton />
                                    :
                                    recentActivities.length <= 0 ?
                                        <div className='text-center mt-4'><h6>No Recent Activity</h6></div>
                                        :
                                        recentActivities?.map((data, index) => (
                                            <Col key={index} xs={6} md={4} lg={3} xl={2} className="mt-4">
                                                <PostCard asGallery={true} link={`/recent-activity/post/${data?.posts?.id}`} data={data?.posts} />
                                            </Col>
                                        ))
                            }

                            <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
                                {
                                    isLoadMore ?
                                        <div className='mt-4'><Loader /></div> :
                                        <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default RecentActivityPage