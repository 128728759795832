import React, { useState, useRef } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success, error } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { AgeFill, GenderFill, LocationFill, LockFill, MessageIconFill, PaypalFill, UserFill } from '../../constants/svgs'
import { Register } from "../../services/auth/auth"
import { GetUserRoleLocalStorage } from '../../services/localStorage/localStorage'
import { constant } from '../../utils/constants'
import { UploadFileToS3 } from '../../utils/helper'
import Loader from '../Loader/Loader'
import Select from 'react-select'
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const SignupShopperForm = () => {
    const { register, handleSubmit, setValue, clearErrors, control, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [options, setOptions] = useState([
        {
            label: "Male",
            value: "Male"
        },
        {
            label: "Female",
            value: "Female"
        }
    ])
    const imageRef = useRef()
    const navigate = useNavigate()
    const [age, setAge] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const password = watch("password")

    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const uploadImageToS3 = async (imgFile) => {
        try {
            const { url } = await UploadFileToS3(imgFile, constant.IMAGE_DIR_NAME)
            return url
        }
        catch (e) {
            error(e.message)
        }
    }

    const getAge = (dob) => {
        if (dob === null) {
            return null
        }
        else {
            let diff = moment(dob).diff(moment(), 'milliseconds');
            let duration = moment.duration(diff)
            let myAge = duration._data.years.toString().replace("-", "")
            setValue("age", age)
            return parseInt(myAge)
        }
    }

    const onSubmit = async (data) => {
        /*
            Business logic for Signup 
            First upload image to aws then append url in data and send it to api for signup 
            After successfull signup send email with useNavigate to account verification page
        */
        setIsDisabled(true)
        try {
            data.role = "shopper"
            data.gender = data.gender.value
            data.device_type = constant.DEVICE_TYPE
            data.age = getAge(age)

            if (file.file) {
                const url = await uploadImageToS3(file.file)
                data.profile_picture = url
            }

            const res = await Register(data);
            imageRef.current.value = null
            success(res.data.message)
            setIsDisabled(false)
            setTimeout(() => {
                navigate("/email-verification", { state: { email: data.email, type: "register", callOn: null } })
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <div className='auth-card signup-form'>
            <ToastContainer />
            <div>
                <h3 className='pb-30 border-bottom mb-30'>SIGN UP</h3>
                <p className='text-black'>Transforming shopping into an experience.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    <label className='profile-img-wrapper' htmlFor="profile">
                        <input
                            type="file"
                            id="profile"
                            name="profile"
                            accept="image/*"
                            className="d-none"
                            ref={imageRef}
                            onChange={(e) => {
                                setFile({
                                    file: e.target.files[0],
                                    file_url: URL.createObjectURL(e.target.files[0])
                                })
                            }}
                        />
                        <img src={file.file_url ? file.file_url : Assets.ProfileUploadPlaceholder} alt="" />
                    </label>
                    <p className='mt-2'>Upload profile picture</p>
                </div>

                <div className='mt-30'>
                    <FloatingLabel controlId="name" label="Full name *">
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="Full name *"
                            {...register("full_name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.FULL_NAME_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <UserFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.full_name && <small className='text-start d-block text-danger mt-1'>{errors.full_name.message}</small>}

                    <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                    <div className={`age-picker mt-20 signup ${age && 'filled'}`}>
                        <div className="icon-wrapper">
                            <AgeFill className='icon' />
                        </div>

                        <Controller
                            control={control}
                            name="age"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.AGE_REQUIRED
                                },
                            }}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    isClearable={false}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={(date) => {
                                        field.onChange(date)
                                        setAge(date)
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Age *"
                                    maxDate={new Date()}
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                    scrollableMonthYearDropdown
                                    dropdownMode="select"
                                />
                            )}
                        />
                        <input
                            className='age-input'
                            placeholder='Age *'
                            type="number"
                            readOnly
                            value={getAge(age)}
                        />
                    </div>
                    {errors.age && <small className='text-start d-block text-danger mt-1'>{errors.age.message}</small>}

                    <div className="react-select ">
                        <div className="icon-wrapper">
                            <GenderFill className='icon' />
                        </div>

                        <Controller
                            control={control}
                            name="gender"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.GENDER_REQUIRED
                                },
                            }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder="Gender *"
                                    className="mt-20 form-control"
                                    classNamePrefix="react-select-container"
                                    isSearchable={false}
                                    options={options}
                                />
                            )}
                        />
                    </div>
                    {errors.gender && <small className='text-start d-block text-danger mt-1'>{errors.gender.message}</small>}

                    <FloatingLabel className="mt-20" controlId="paypal_email" label="PayPal Email Address">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="PayPal Email Address"
                            {...register("paypal_email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: false,
                                        message: VALIDATIONS_TEXT.PAYPAL_EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <PaypalFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.paypal_email && <small className='text-start d-block text-danger mt-1'>{errors.paypal_email.message}</small>}

                    <FloatingLabel className="mt-20" controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}

                    <FloatingLabel className="mt-20" controlId="confirm-password" label="Confirm Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showConfirmPassword ? "text" : "password"}`}
                            placeholder="Confirm Password *"
                            {...register("password_confirmation",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                    },
                                    validate: (value) =>
                                        value === password || "The Passwords do not match"
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {
                                showConfirmPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password_confirmation && <small className='text-start d-block text-danger mt-1'>{errors.password_confirmation.message}</small>}

                    <Form.Check className='mt-20 d-flex align-items-center' type="checkbox" label={
                         <div className='small-title'>
                         <span>I agree with
                             <NavLink to="/terms-and-conditions"> Terms and Conditions </NavLink>
                             and
                             <NavLink to="/privacy-policy"> Privacy Policy</NavLink>
                         </span>
                     </div>
                    }
                    />

                    <Button disbaled={isDisabled} type="submit" className='mt-40 mb-30 btn-solid'>
                        {isDisabled ? <Loader /> : "SIGN UP"}
                    </Button>
                    <span className="mb-20 d-block">Already have an account? <NavLink className="link" to="/signin">SIGN IN</NavLink></span>
                </div>
            </Form>
        </div>
    )
}

export default SignupShopperForm