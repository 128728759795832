import React from 'react'
import TopupCard from '../dashboard/TopupCard';
import { useNavigate } from 'react-router-dom';

const TopupForm = () => {
    const navigate = useNavigate()

    const handleSkip = () => {
        navigate("/home")
    }

    return (
        <div className='auth-card topup-card'>
            <TopupCard handleSkip={handleSkip} />
        </div>
    )
}

export default TopupForm