import React, { useContext, useState } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { STATUS } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { error, success } from '../../constants/msg'
import { BlockUnblockUser } from '../../services/users/users'
import Loader from '../Loader/Loader'
import { UserData } from '../../App'
import { db } from '../../firebase'
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore'

const BlockUserPopup = ({ selectedPostUser, blockUserPopup, setBlockUserPopup }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)

    const blockUserOnFirebase = async (chatRef) => {
        await updateDoc(chatRef, {
            is_blocked: true,
            blocked_by: arrayUnion(userData?.id)
        })
    }

    const blockUser = async () => {
        /* 
            Send id of user whom you want to block and status = 1 
        */
        setIsDisabled(true)
        try {
            const data = {
                user_id: selectedPostUser?.id,
                status: STATUS.block   //  1 for block and 0 for unblock
            }
            const res = await BlockUnblockUser(data)

            const docId1 = userData?.id + "-" + selectedPostUser?.id
            const chatRef1 = doc(db, 'chats', docId1);
            const docSnapshot1 = await getDoc(chatRef1);

            if (docSnapshot1.exists()) {
                blockUserOnFirebase(chatRef1)
            } else {
                const docId2 = selectedPostUser?.id + "-" + userData?.id
                const chatRef2 = doc(db, 'chats', docId2);
                blockUserOnFirebase(chatRef2)
            }

            setBlockUserPopup(false)
            success(res.data.message)
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup block-alert-popup'
                show={blockUserPopup}
                onHide={() => setBlockUserPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>BLOCK USER</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, that you want to block <br />
                                    this user?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button disabled={isDisabled} className="w-100 btn-solid mb-2" onClick={() => blockUser()}>
                                    {isDisabled ? <Loader /> : "BLOCK"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setBlockUserPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BlockUserPopup