import React, { useState, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Form, Modal } from 'react-bootstrap'
import Assets from '../../constants/images'
import { useForm } from 'react-hook-form'
import { ROLES, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { SetAuthUserLocalStorage, SetTokenLocalStorage, SetUserRoleLocalStorage } from '../../services/localStorage/localStorage'
import { UserRole, UserData } from '../../App'
import { Times } from '../../constants/svgs'
import { error } from '../../constants/msg'
import { ToastContainer } from 'react-toastify'
import { SocialLogin } from '../../services/auth/auth'
import Loader from '../Loader/Loader'
import { UpdateUser } from '../../services/users/users'

const SelectRolePopup = ({ selectRolePopup, setSelectRolePopup }) => {
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const [userRole, setUserRole] = useContext(UserRole)
    const [userData, setUserData] = useContext(UserData)

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const handleSetUserRole = (user) => {
        if (user.is_brand == 0) {
            SetUserRoleLocalStorage(ROLES.shopper)
            setUserRole(ROLES.shopper)
        }
        else if (user.is_brand == 1) {
            SetUserRoleLocalStorage(ROLES.brand)
            setUserRole(ROLES.brand)
        }
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const { role } = data
        selectRolePopup.data.role = role == ROLES.shopper ? "shopper" : "brand_owner"
        try {
            const res = await SocialLogin(selectRolePopup.data)
            const resData = res.data.data
            SetTokenLocalStorage(resData.user.access_token)
            SetAuthUserLocalStorage(resData.user)
            setUserData(resData.user)
            handleSetUserRole(resData.user)
            setIsDisabled(false)
            setSelectRolePopup({ data: null, isShow: false })
            // navigate("/home")
            if (selectRolePopup?.data?.role == "brand_owner") {
                navigate("/profile-setup", { state: { user: resData.user } })
            }
            else {
                navigate("/edit-profile")
            }
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup select-role-popup'
                show={selectRolePopup.isShow}
                onHide={() => setSelectRolePopup({
                    ...selectRolePopup,
                    isShow: false
                })}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='d-flex text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>SELECT ROLE</h3>
                            <div className="text-end times-icon-wrapper" onClick={() => setSelectRolePopup({ ...selectRolePopup, isShow: false })}>
                                <Times />
                            </div>
                        </div>

                        <div className='auth-card select-role-form p-0'>
                            <div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <div className="wrapper mb-20">
                                            <Form.Check
                                                value={ROLES.shopper}
                                                name="role"
                                                type="radio"
                                                id="shopping"
                                                {...register("role",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.ROLE_REQUIRED
                                                        },
                                                    })
                                                }
                                            />
                                            <label htmlFor="shopping">SHOPPING AND <br /> CREATING CONTENT</label>
                                        </div>

                                        <div className="wrapper">
                                            <Form.Check
                                                value={ROLES.brand}
                                                name="role"
                                                type="radio"
                                                id="brand-owner"
                                                {...register("role",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.ROLE_REQUIRED
                                                        },
                                                    })
                                                }
                                            />
                                            <label htmlFor="brand-owner">Brand Owner</label>
                                        </div>
                                        {errors.role && <small className='text-start d-block text-danger mt-1'>{errors.role.message}</small>}

                                        <Button type="submit" className='mb-30 mt-40 btn-solid'>
                                            {
                                                isDisabled ?
                                                    <Loader />
                                                    :
                                                    "CONTINUE"
                                            }
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectRolePopup