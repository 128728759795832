import React, { useState, useContext } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { DotsHorizontal } from '../../constants/svgs'
import Assets from "../../constants/images"
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { ShareOutline, MessageOutline, HeartOutline, HeartFill, FavouriteOutline, FavouriteFill } from '../../constants/svgs'
import ShowMore from 'react-show-more';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { IMAGE_FILE_FORMAT, INSTANCE_TYPE, REPORT_INSTANCE_TYPE, STATUS } from '../../constants/app-constants';
import { AddRemovePostFromFavourite, AddRemovePostReaction, GetSinglePost } from '../../services/posts/posts';
import { error, success } from '../../constants/msg';
import { useEffect } from 'react';
import PostDetailCardSkeleton from '../skeleton/PostDetailCardSkeleton';
import { ToastContainer } from 'react-toastify'
import BlockUserPopup from '../popup/BlockUserPopup';
import { UserData } from '../../App';
import DeletePostPopup from '../popup/DeletePostPopup';
import ReportPopup from '../popup/ReportPopup';
import { SharePost } from '../../services/posts/posts';
import ViewImage from './ViewImage';
import { handleLineBreakAndTagHighlghts } from '../../utils/helper';
import SharePostPopup from '../popup/SharePostPopup';

const PostDetailCard = ({ link, isScheduled, setPosterDetail, productListView }) => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const location = useLocation()
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [userData, setUserData] = useContext(UserData)
  const [blockUserPopup, setBlockUserPopup] = useState(false)
  const [deletePostPopup, setDeletePostPopup] = useState(false)
  const [post, setPost] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isLiked, setIsLiked] = useState()
  const [isFavourite, setIsFavourite] = useState()
  const [selectedPostUser, setSelectedPostUser] = useState()
  const [selectedPost, setSelectedPost] = useState()
  const [reportPopup, setReportPopup] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [sharePostPopup, setSharePostPopup] = useState(false)
  const [shareableLink, setShareableLink] = useState("")
  const [viewImage, setViewImage] = useState({
    src: "",
    state: false
  })

  useEffect(() => {
    const getSinglePost = async () => {
      // fetch post by id.... fetch a single post
      setIsLoading(true)
      try {
        const res = await GetSinglePost(slug)
        setPost(res.data.data)
        setPosterDetail(res.data.data.user)
        setIsLoading(false)
      }
      catch (e) {
        setIsLoading(false)
        error(e.response.data.message)
      }
    }

    getSinglePost()
  }, [slug])

  useEffect(() => {
    if (post) {
      setIsLiked(post?.is_reacted)
      setIsFavourite(post?.is_favourite)
    }
  }, [post])

  const handleLike = async (postId) => {
    /* 
      By default post is not liked 
      OnClick we change liked = true if post is not liked else we change liked = false
    */
    post.is_reacted = !post.is_reacted
    setIsLiked(!isLiked)
    try {
      const reactionData = {
        instance_id: postId,
        instance_type: INSTANCE_TYPE.post,
        reaction: post?.is_reacted ? STATUS.like : STATUS.dislike
      }
      await AddRemovePostReaction(reactionData)
    }
    catch (e) {
      error(e.response.data.message)
    }
  }

  const handleFavourite = async (postId) => {
    /* 
      By default post is not saved 
      OnClick we change saved = true if post is not saved else we change saved = false
    */

    post.is_favourite = !post.is_favourite
    setIsFavourite(!isFavourite)
    try {
      const favouriteData = {
        post_id: postId,
        action: post.is_favourite ? STATUS.addToFavourite : STATUS.removeFromFavourite,
      }
      await AddRemovePostFromFavourite(favouriteData)
    }
    catch (e) {
      error(e.response.data.message)
    }
  }

  const returnFormatedTime = (date) => {
    if (date == "an hour ago") {
      return "1 hour ago"
    }
    else if (date == "a day ago") {
      return "1 day ago"
    }
    else if (date == "a minute ago") {
      return "1 minute ago"
    }
    else {
      return date
    }
  }

  const getPublishedDateTime = (post) => {
    if (isScheduled || post?.is_published === "NO") {
      const dateTime = moment(post?.converted_date).format('[Schedule for] ddd, DD MMM YYYY [at] hh:mm A')
      return dateTime
    }
    else {
      const date = moment.utc(post?.created_at).fromNow()
      return returnFormatedTime(date)
    }
  }

  const hideMenuForOwnPost = () => {
    if (post?.user?.id == userData?.id) {
      return "d-none"
    }
    else {
      return "d-block"
    }
  }

  const showMenuForOwnPost = () => {
    if (post?.user?.id == userData?.id) {
      return "d-block"
    }
    else {
      return "d-none"
    }
  }

  const sharePost = async () => {
    try {
      const data = {
        post_id: slug,
      }
      const res = await SharePost(data)
      success(res.data.message)
    }
    catch (e) {
      error(e.response.data.message)
    }
  }

  const handleNavigate = (user) => {
    if (user?.brand_owner) {
      if (user?.brand_owner?.user_id == userData?.id) {
        navigate("/my-profile")
      }
      else {
        navigate(`/brand/${user?.brand_owner?.user_id}`)
      }
    }
    else {
      if (user?.shopper?.user_id == userData?.id) {
        navigate("/my-profile")
      }
      else {
        navigate(`/user/${user?.shopper?.user_id}`)
      }
    }
  }

  const handleViewImage = (img) => {
    window.scrollTo(0, 0);
    setViewImage({ src: img, state: true })
    document.body.style.overflow = "hidden"
  }

  const HandleProductView = (post) => {
    if (productListView) {
      return (
        <>
          <div className="mt-20 mb-20">
            <span className="shop-now">EXACT PRODUCTS</span>
          </div>

          <div className='img-list-view-wrapper'>
            <div className="list-wrapper">
              {
                post?.products?.map((data, index) => (
                  <div key={index} className="wrap d-flex border-bottom pb-2 mb-3">
                    <div className='img-wrapper'>
                      <img src={data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                    </div>

                    <div className="ps-4">
                      <h4>{data?.title}</h4>
                      <ul>
                        <li>Amazon(US)</li>
                        <li>Est. Comm: $1.60</li>
                        <li>{data?.price?.split(".")[0]}</li>
                      </ul>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </>
      )
    }
    else {
      return (
        <>
          <div className="mt-20 mb-20">
            <span className="shop-now">SHOP NOW</span>
          </div>

          <Swiper
            onSwiper={setThumbsSwiper}
            loop={post?.products.length >= 5 ? true : false}
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              350: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 4,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 4,
              },
              1300: {
                slidesPerView: 5,
              },
            }}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper-bottom"
          >
            {
              post?.products?.map((data, index) => (
                <SwiperSlide key={index}>
                  {
                    userData?.brand_owner ?
                      <img src={data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                      :
                      <a href={data?.product_url + `?post_id=${slug}`} target="_blank" className="d-block h-100 w-100">
                        <img src={data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                      </a>
                  }

                </SwiperSlide>
              ))
            }
          </Swiper>
        </>
      )
    }
  }

  return (
    <>
      <BlockUserPopup selectedPostUser={selectedPostUser} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
      <DeletePostPopup selectedPost={selectedPost} deletePostPopup={deletePostPopup} setDeletePostPopup={setDeletePostPopup} />
      <ReportPopup data={selectedPost} reportTo={REPORT_INSTANCE_TYPE.post} reportPopup={reportPopup} setReportPopup={setReportPopup} />
      <ViewImage viewImage={viewImage} setViewImage={setViewImage} />
      <SharePostPopup sharePostPopup={sharePostPopup} setSharePostPopup={setSharePostPopup} shareableLink={shareableLink} setShareableLink={setShareableLink} />
      <ToastContainer />
      {
        isLoading ?
          <PostDetailCardSkeleton />
          :
          <div className='post-detail-card'>
            <div className='text-center border-bottom mb-30 pb-30'>
              <h3>{post?.user?.brand_owner ? post?.user?.brand_owner?.brand_name : post?.user?.shopper?.full_name}</h3>
            </div>

            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <div className="profile-wrapper cursor-pointer">
                  <img onClick={() => handleNavigate(post?.user)} src={post?.user?.brand_owner ? post?.user?.brand_owner?.image_url : post?.user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                </div>

                <div className="ms-2 mt-0-2">
                  <span className="name d-block cursor-pointer" onClick={() => handleNavigate(post?.user)}><b>{post?.user?.brand_owner ? post?.user?.brand_owner?.brand_name : post?.user?.shopper?.full_name}</b></span>
                  <small className="time">{getPublishedDateTime(post)}</small>
                  <div className="show-more-less">
                    {
                      post?.description?.length > 80 ?
                        <>
                          {
                            showMore ?
                              <p dangerouslySetInnerHTML={{ __html: handleLineBreakAndTagHighlghts(post?.description, post?.mentions) }} />
                              :
                              <p className='d-inline' dangerouslySetInnerHTML={{ __html: handleLineBreakAndTagHighlghts(post?.description?.substring(0, 80), post?.mentions) }} />
                          }
                          <span onClick={() => setShowMore(!showMore)}>{showMore ? " show less" : "...show more"}</span>
                        </>
                        :
                        <p dangerouslySetInnerHTML={{ __html: handleLineBreakAndTagHighlghts(post?.description, post?.mentions) }} />
                    }
                    <span className="price">{post?.price}</span>
                  </div>
                </div>
              </div>

              <div>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <DotsHorizontal />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="text-center">
                    <Dropdown.Item
                      className={`border-bottom ${hideMenuForOwnPost()}`}
                      onClick={() => {
                        setSelectedPost(post)
                        setReportPopup(true)
                      }}
                    >
                      Report Post
                    </Dropdown.Item>

                    <Dropdown.Item
                      className={`border-bottom ${showMenuForOwnPost()}`}
                      onClick={() => navigate(`/edit-post/${slug}`)}
                    >
                      Edit Post
                    </Dropdown.Item>

                    <Dropdown.Item
                      className={`border-bottom ${showMenuForOwnPost()}`}
                      onClick={() => {
                        setSelectedPost(post)
                        setDeletePostPopup(true)
                      }}
                    >
                      Delete Post
                    </Dropdown.Item>

                    <Dropdown.Item
                      className={`border-bottom ${hideMenuForOwnPost()}`}
                      onClick={() => {
                        setSelectedPostUser(post?.user)
                        setBlockUserPopup(true)
                      }}
                    >
                      Block User
                    </Dropdown.Item>

                    <Dropdown.Item className="">Cancel</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className='post-img-wrapper mt-20'>
              <Link to={link}>
                {
                  IMAGE_FILE_FORMAT.includes(post?.attachments[0]?.mime_type) ?
                    <div className='post-img'>
                      <img onClick={() => handleViewImage(post?.attachments[0]?.attachment_url)} src={post?.attachments[0]?.attachment_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                    </div>
                    :
                    <div className='post-img video-wrapper'>
                      <video className='w-100 h-100' src={post?.attachments[0]?.attachment_url} controls></video>
                    </div>
                }
              </Link>
              {/* Handle product view, if productListView == true than show list view else show swiper view */}
              {HandleProductView(post)}
            </div>

            <div className="mt-30 border-top d-flex align-items-center justify-content-between">
              <div className="mt-20 d-flex align-items-center">
                <label htmlFor="heart">
                  <div className='icon-wrapper me-4'>
                    <input value={isLiked} onChange={e => handleLike(post?.id)} id="heart" className='d-none' type="checkbox" />
                    {
                      isLiked ?
                        <HeartFill /> :
                        <HeartOutline />
                    }
                    <span className='ms-2'></span>
                  </div>
                </label>

                <div className='icon-wrapper ms-2 me-4'>
                  <MessageOutline />
                  <span className='ms-2'></span>
                </div>

                <div
                  className='icon-wrapper ms-2'
                  onClick={() => {
                    setSharePostPopup(true)
                    setShareableLink(window.location.href)
                  }}>
                  <ShareOutline />
                </div>
              </div>

              <div className="mt-3">
                <label htmlFor="wishlist">
                  <div className='icon-wrapper'>
                    <input value={isFavourite} onChange={e => handleFavourite(post?.id)} id="wishlist" className='d-none' type="checkbox" />
                    {
                      isFavourite ?
                        <FavouriteFill /> :
                        <FavouriteOutline />
                    }
                    <span className=''></span>
                  </div>
                </label>
              </div>
            </div>
          </div>
      }
    </>

  )
}

export default PostDetailCard