import React, { useContext } from 'react'
import ShopperEditProfileForm from '../components/forms/ShopperEditProfileForm'
import BrandEditProfileForm from '../components/forms/BrandEditProfileForm'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { UserRole } from '../App'
import { ROLES } from '../constants/app-constants'

const EditProfilePage = () => {
    const [userRole, setUserRole] = useContext(UserRole)

    const editProfileForm = {
        [ROLES.brand]: <BrandEditProfileForm />,
        [ROLES.shopper]: <ShopperEditProfileForm />,
    }

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Edit Profile" link4="" link4Text="" />
            <div className="pages edit-profile-page">
                <div className="d-flex align-items-center justify-content-center h-100 pb-4">
                    {editProfileForm[userRole]}
                </div>
            </div>
        </>
    )
}

export default EditProfilePage