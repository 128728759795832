import React, { useContext, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { VALIDATIONS_TEXT } from '../../constants/app-constants';
import DashboardBreadCrumb from '../../layouts/dashboard/DashboardBreadCrumb';
import Loader from '../Loader/Loader';
import { DomainVerify } from '../../services/brands/brand'
import { error, success } from '../../constants/msg'
import { UserData } from '../../App'
import ChangeDomainPopup from '../popup/ChangeDomainPopup';
import { AiOutlinePoweroff } from 'react-icons/ai';
import LogoutPopup from '../popup/LogoutPopup';

const VerifyDomainForm = ({ withBreadcrumb }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useContext(UserData)
    const brand_id = userData?.brand_owner?.id;
    const website_url = userData?.brand_owner?.website_url;
    const [isDisabled, setIsDisabled] = useState(false)
    const [changeDomainPopup, setChangeDomainPopup] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)

    const onSubmit = async () => {
        setIsDisabled(true)
        try {
            const data = {
                brandId: brand_id,
                website_url: website_url
            }
            const res = await DomainVerify(data)
            setIsDisabled(false)
            success(res?.data?.message)
            setTimeout(() => {
                navigate("/domain-information", {
                    state: {
                        brand_id: brand_id,
                        website_url: website_url
                    }
                })
            }, 1500)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    return (
        <>
            {withBreadcrumb && <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Domain Verification" link3="" link3Text="" link4="" link4Text="" />}

            <div className={`auth-card domain-verification-form ${withBreadcrumb === true && 'mt-5'}`}>
                <ToastContainer />
                <ChangeDomainPopup changeDomainPopup={changeDomainPopup} setChangeDomainPopup={setChangeDomainPopup} asLink={false} redirectLink="/generate-token" />
                <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />


                <div>
                    <div className='d-flex align-items-center pb-30 border-bottom mb-30'>
                        <h3 className='mx-auto'>Domain Verification</h3>
                        {
                            userData && userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 0 &&
                            <span onClick={() => setLogoutPopup(true)}><AiOutlinePoweroff className='icon-off' /></span>
                        }
                    </div>

                    <p className="text-black">
                        Please verify your domain
                    </p>
                </div>

                <div className='mt-20'>
                    <p className="text-deep-grey">Domain verification for {website_url}</p>

                    <Button onClick={onSubmit} disabled={isDisabled} className='mt-60 mb-2 btn-solid'>
                        {isDisabled ? <Loader /> : "Verify Domain"}
                    </Button>
                    <Button onClick={() => setChangeDomainPopup(true)} className="btn-transparent">Change Domain</Button>

                </div>
            </div>
        </>
    )
}

export default VerifyDomainForm