import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetUser = (id) => api.get(`${apiUrl.users}/${id}`)
export const IsUserAlreadyRegistered = (client_id) => api.get(`${apiUrl.is_user_registered}?client_id=${client_id}`)
export const UpdateUser = (id, data) => api.put(`${apiUrl.users}/${id}`, data)
export const UpdateUserPrivacy = (data) => api.post(apiUrl.user_privacy, data)
export const DeleteAccount = (id) => api.delete(`${apiUrl.users}/${id}`)
export const GetBlockedUsers = (params) => api.get(apiUrl.block_user, { params })
export const BlockUnblockUser = (data) => api.post(apiUrl.block_user, data)
export const GetUserRecentActivity = (params) => api.get(apiUrl.recent_activity, { params })
export const FollowUser = (id) => api.post(apiUrl.follow_user, id)
export const FollowRequest = (data) => api.post(apiUrl.follow_request, data)
export const FollowRequestUpdate = (data) => api.post(apiUrl.follow_request_update, data)
export const GetUserFollowedBrands = (params) => api.get(apiUrl.get_followed_brands, { params })
export const GetTaggedPosts = (params) => api.get(apiUrl.tagged_posts, { params })
export const GetExtensionLink = () => api.get(apiUrl.extension_link)
export const UpdateWebsiteUrl = (data) => api.post(`${apiUrl.user}${apiUrl.update_website_url}`, data)


