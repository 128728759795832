import React, { useContext, useEffect, useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success, error } from "../../constants/msg"
import { LockFill } from '../../constants/svgs'
import { ChangePassword } from '../../services/auth/auth'
import Loader from '../Loader/Loader'
import { UserData } from '../../App'
import { GetFolders, UpdateFolderPrivacy } from '../../services/folders/folder'
import { ToastContainer } from 'react-toastify'

const CollectionPrivacyForm = () => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [userData, setUserData] = useContext(UserData)
    const [folders, setFolders] = useState([])
    const [isLoading, setIsLoading] = useState([])

    useEffect(() => {
        const getFolders = async () => {
            // get folders of user
            try {
                const res = await GetFolders()
                setFolders(res.data.data.data)
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                error(e.response.data.message)
            }
        }
        getFolders()
    }, [])

    const handlePrivacyOnChange = async (id, is_private, index) => {
        const temp = [...folders]
        temp[index].is_private = is_private == 0 ? 1 : 0
        setFolders(temp)

        try {
            const data = {
                is_private: is_private == 0 ? 1 : 0
            }
            await UpdateFolderPrivacy(id, data)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="collection-privacy-form h-100">
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>COLLECTION PRIVACY</h3>
                </div>

                <div className='collection-switch-wrapper'>
                    <span className="d-block text-center">
                        Setup privacy for your collections
                    </span>
                    <div className='wapper mt-20'>
                        {
                            isLoading ?
                                <div className='text-center'>
                                    <Loader />
                                </div>
                                :
                                folders.length <= 0 ?
                                    <span><b>No collection available</b></span>
                                    :
                                    folders?.map((data, index) => (
                                        <div key={index} className='d-flex align-items-center justify-content-between border-bottom mb-3 pb-3'>
                                            <span>{data?.name}</span>
                                            <Form.Check
                                                checked={data?.is_private == 1 ? true : false}
                                                onChange={(e) => handlePrivacyOnChange(data?.id, data?.is_private, index)}
                                                className="p-0 switch-button"
                                                type="switch"
                                                id={`custom-switch-${index}`}
                                            />
                                        </div>
                                    ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionPrivacyForm