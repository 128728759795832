import React, { useContext, useEffect, useState } from 'react'
import CreatePostBox from "../components/dashboard/CreatePostBox"
import { Container, Row, Col, Button } from "react-bootstrap"
import Assets from '../constants/images'
import MassonaryCard from "../components/dashboard/MassonaryCard"
import { error, success } from '../constants/msg'
import { ToastContainer } from 'react-toastify';
import { GetPosts } from "../services/posts/posts"
import MassonaryCardSkeleton from '../components/skeleton/MassonaryCardSkeleton'
import Loader from '../components/Loader/Loader'
import SearchPopup from '../components/popup/SearchPopup'
import AddBankAccountPopup from '../components/popup/AddBankAccountPopup'
import { UserData } from '../App'
import { redirect, useNavigate } from 'react-router-dom'

const HomePage = () => {
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [totalPage, setTotalPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState([])
    const [searchPopup, setSearchPopup] = useState(false)
    const [addBankAccountPopup, setAddBankAccountPopup] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const navigate = useNavigate()

    const handleClick = (name) => {
        if (name === "bank_card") {
            setAddBankAccountPopup(true)
        }
        else {
            setSearchPopup(true)
        }
    }

    useEffect(() => {
        const getPosts = async () => {
            const params = {
                orderBy: "id",
                sortedBy: "desc",
                is_brand: 0,
                page: currentPage
            }
            if (currentPage > 1) {
                setIsLoadMore(true)
            }
            try {
                const res = await GetPosts(params)
                setTotalPage(res.data.data.last_page)
                setData([...data, ...res.data.data.data])
                setIsLoading(false)
                setIsLoadMore(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsLoadMore(false)
                error(e.response.data.message)
            }
        }

        getPosts()
    }, [currentPage])

    useEffect(() => {
        if (userData && userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 0) {
            navigate("/generate-token")
        }
    }, [])

    return (
        <>
            <SearchPopup searchPopup={searchPopup} setSearchPopup={setSearchPopup} />
            <AddBankAccountPopup addBankAccountPopup={addBankAccountPopup} setAddBankAccountPopup={setAddBankAccountPopup} />

            <div className={`pages home-page ${data.length <= 0 && "h-100"}`}>
                <ToastContainer />
                {
                    isLoading ?
                        <div className='py-3'>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>
                                        <div className='massonary-grid'>
                                            <MassonaryCardSkeleton count={6} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        :
                        data.length === 0 ?
                            <div className='d-flex align-items-center justify-content-center h-100'>
                                <CreatePostBox img={Assets.CreatePostImg} name="create_post" text="Click here to follow others" onClick={handleClick} />
                            </div>
                            :
                            <div className='py-3'>
                                <Container fluid>
                                    <Row>
                                        <Col xs={12}>
                                            <div className='massonary-grid'>
                                                <MassonaryCard link1="user" link2="post" data={data} setData={setData} />
                                            </div>
                                        </Col>

                                        <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
                                            {
                                                isLoadMore ?
                                                    <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                }
            </div>
        </>
    )
}

export default HomePage