import React, { createContext, useEffect, useState } from 'react'
import Navigation from "./navigation/Navigation"
import { BrowserRouter } from "react-router-dom";
import moment from "moment"
import { GetAuthUserLocalStorage, GetUserRoleLocalStorage } from './services/localStorage/localStorage';
import { gapi } from 'gapi-script';
import { GOOGLE_AUTH } from './constants/app-constants';

const FilterDateContext = createContext()
const AddProductContext = createContext()
const UserData = createContext()
const UserRole = createContext()
const FiltersContext = createContext()

const App = () => {
    const [filterDate, setFilterDate] = useState({
        displayDate: moment(new Date()).format("MMM YYYY").toString(),
        displayDateWithDay: moment(new Date()).format("DD MMM YYYY").toString(),
        actualDate: moment(new Date()).format("YYYY-MM-DD").toString(),
        isSet: false
    })
    const [addProduct, setAddProduct] = useState([])
    const [userData, setUserData] = useState(GetAuthUserLocalStorage())
    const [userRole, setUserRole] = useState(GetUserRoleLocalStorage())
    const [filters, setFilters] = useState([])

    useEffect(() => {
        // prevenet text selection on double click 
        document.body.addEventListener('mousedown', function (event) {
            if (event.detail > 1) {
                event.preventDefault();
            }
        }, false);
    }, [])

    return (
        <BrowserRouter>
            <FilterDateContext.Provider value={[filterDate, setFilterDate]}>
                <AddProductContext.Provider value={[addProduct, setAddProduct]}>
                    <UserRole.Provider value={[userRole, setUserRole]}>
                        <UserData.Provider value={[userData, setUserData]}>
                            <FiltersContext.Provider value={[filters, setFilters]}>
                                <Navigation />
                            </FiltersContext.Provider>
                        </UserData.Provider>
                    </UserRole.Provider>
                </AddProductContext.Provider>
            </FilterDateContext.Provider>
        </BrowserRouter>
    )
}

export default App
export { UserData, UserRole, FilterDateContext, AddProductContext, FiltersContext }