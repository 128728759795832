import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import PagesSkeleton from "../components/skeleton/PagesSkeleton"
import { GetPageBySlug } from '../services/pages/pages'
import parse from 'html-react-parser';
import { error, success } from '../constants/msg'
import { ToastContainer } from 'react-toastify';
import { PAGES } from '../constants/app-constants'

const AboutPage = () => {
    const [data, setData] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getPageData = async () => {
            try {
                const res = await GetPageBySlug(PAGES.about)
                setData(res.data.data.content)
                setLoading(false)
            }
            catch (e) {
                setLoading(false)
                error(e.response.data.message)
            }
        }

        getPageData()
    }, [])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="About Us" link3="" link3Text="" link4="" link4Text="" />
            <ToastContainer />

            <div className="pages about-page">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <div className="wrapper">
                                <h1>About Linkfront</h1>
                                <p className="mt-2">
                                    {
                                        loading ?
                                            <div className=""><PagesSkeleton /></div>
                                            :
                                            data && parse(data)
                                    }
                                </p>
                            </div>
                        </Col>

                        <Col className="my-4 mt-lg-0" lg={{ span: 5, offset: 1 }}>
                            <div className="img-wrapper text-center p-5 text-center d-flex align-items-center justify-contnet-center h-100">
                                <img className="mx-auto img-fluid" src={Assets.LogoVerticalXl} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AboutPage