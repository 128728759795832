import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const ProfileCardSkeleton = () => {
    return (
        <div className='view-profile-card'>
            <div className="wrapper p-4 d-flex flex-column align-items-center justify-content-center">
                <Skeleton width={125} height={125} circle={true} />
                <div className="mt-2">
                    <h2><Skeleton count={1.7} width={200} /></h2>
                </div>
                <div className="mt-2 d-flex justify-content-center align-items-center">
                    <Skeleton style={{ borderRadius: "10px" }} className='me-3' count={1} width={135} height={40} />
                    <Skeleton style={{ borderRadius: "10px" }} className='me-3' count={1} width={40} height={40} />
                    <Skeleton style={{ borderRadius: "10px" }} count={1} width={40} height={40} />
                </div>
            </div>
        </div>
    )
}

export default ProfileCardSkeleton