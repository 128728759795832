import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCropPopup = ({ file, setFile, imgCropPopup, setImgCropPopup, base64UrlToFile }) => {
    const cropperRef = useRef(null);

    useEffect(() => {
        if (imgCropPopup) {
            setFile({
                ...file,
                original_file: file.original_file,
                file_url: file.original_file_url,
            })
        }
    }, [imgCropPopup])

    const onCropDone = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        const base64ImageURL = cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.5)
        const croppedImageFile = base64UrlToFile(base64ImageURL, file.file.name)
        
        if (file.file_type === "video") {
            setFile({
                ...file,
                file_url: base64ImageURL
            })
        }
        else {
            setFile({
                ...file,
                file: croppedImageFile,
                file_url: base64ImageURL
            })
        }
        setImgCropPopup(false)
    }

    return (
        <Modal
            className='theme-popup img-crop-popup'
            show={imgCropPopup}
            onHide={() => setImgCropPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>CROP IMAGE</h3>
                    </div>

                    <div className='content-container'>
                        <Cropper
                            src={file.file_url}
                            initialAspectRatio={16 / 9}
                            guides={false}
                            ref={cropperRef}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            responsive={true}
                            autoCropArea={1}
                            aspectRatio={4 / 3}
                            checkOrientation={false}
                            cropBoxResizable={true}
                        />
                    </div>

                    <div className="mt-20">
                        <Button onClick={() => onCropDone()} className="w-100 btn-solid mb-2">CROP</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImageCropPopup