import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import PostCard from '../components/dashboard/PostCard'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import DashboardBreadCrumbSkeleton from '../components/skeleton/DashboardBreadCrumbSkeleton'

const ViewProfilePage = () => {
    const [userProfile, setUserProfile] = useState(null)

    return (
        <>
            {
                userProfile ?
                    <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/brands" link2Text="Brands" link3="#" link3Text={userProfile?.shopper ? userProfile?.shopper?.full_name : userProfile?.brand_owner?.brand_name} link4="" link4Text="" />
                    :
                    <DashboardBreadCrumbSkeleton />
            }
            <div className='pages view-profile-page'>
                <div className="pb-4">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <ViewProfileCard setUserProfile={setUserProfile} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ViewProfilePage