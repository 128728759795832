import React, { useContext, useState } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader';
import { STATUS } from '../../constants/app-constants';
import { BlockUnblockUser } from '../../services/users/users';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserData } from '../../App';

const BlockUserChatPopup = ({ blockUserChatPopup, setBlockUserChatPopup, selectedChatUser }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)

    const blockedChatUser = async () => {
        const channel_id = selectedChatUser?.channel_id
        const chatDocRef = doc(db, 'chats', channel_id)

        setIsDisabled(true)
        try {
            const chatDoc = await getDoc(chatDocRef)

            if (chatDoc.exists()) {
                const data = chatDoc.data()
                if (!data?.blocked_by?.includes(userData?.id)) {
                    data?.blocked_by?.push(userData?.id)
                }

                await updateDoc(chatDocRef, {
                    is_blocked: true,
                    blocked_by: data?.blocked_by
                })
                const res = await BlockUnblockUser({ user_id: selectedChatUser?.id, status: STATUS.block })
                setIsDisabled(false)
                setBlockUserChatPopup(false)
                success(res.data.message)
            }
        }
        catch (e) {
            setIsDisabled(false)
            error("Something went wrong")
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup unblock-alert-popup'
                show={blockUserChatPopup}
                onHide={() => setBlockUserChatPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>BLOCK USER</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure you want to block this chat?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={blockedChatUser}>
                                    {isDisabled ? <Loader /> : "BLOCK"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setBlockUserChatPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BlockUserChatPopup