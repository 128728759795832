import React, { useRef, useContext, useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { CHARACTER_ARRAY_FOR_COMMISSION_PAYOUT, CHARACTER_ARRAY_FOR_NUMBER_INPUT, SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify';
import { error, success } from '../../constants/msg';
import { useEffect } from 'react'
import { BioFill, BrandFill, ClockFill, DollarFill, GlobeFill, LocationFill, MessageIconFill, PaypalFill, UserFill } from '../../constants/svgs'
import moment from 'moment'
import BusinessHourPopup from '../popup/BusinessHourPopup'
import Select from 'react-select';
import { GetCategories } from '../../services/brands/brand'
import { GetUser, UpdateUser } from '../../services/users/users'
import { UserData } from '../../App'
import { UploadFileToS3 } from '../../utils/helper'
import { constant } from '../../utils/constants'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import Loader from '../Loader/Loader'

const BrandEditProfileForm = () => {
    const imageRef = useRef()
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserData)
    const [isDisabled, setIsDisabled] = useState(false)
    const [businessHoursPopup, setBusinessHoursPopup] = useState(false);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [timeError, setTimeError] = useState(false)
    const [options, setOptions] = useState([])
    const [file, setFile] = useState({
        file: null,
        file_url: null
    })
    const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const uploadImageToS3 = async (imgFile) => {
        try {
            const { url } = await UploadFileToS3(imgFile, constant.IMAGE_DIR_NAME)
            return url
        }
        catch (e) {
            error(e.message)
        }
    }

    const getFormattedTime = (startTime, endTime) => {
        //  convert and return date object like 12:00pm to 10:00pm
        const start_time = moment(startTime).format("hh:mm a")
        const end_time = moment(endTime).format("hh:mm a")
        return start_time + " to " + end_time
    }

    const onSubmit = async (data) => {
        if (((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime))) {
            setTimeError(true)  // if error goes here
        }
        else {
            /*
                Before update check if user upload/change his image
                then first upload it on S3 than add url to data and than upadte.
                After update set updated user data in localStorage and userData context
            */
            setIsDisabled(true)
            try {
                data.role = "brand_owner"
                data.category_id = data.brand_category.id
                data.business_hours = getFormattedTime(startTime, endTime)

                if (file.file) {
                    const url = await uploadImageToS3(file.file)
                    data.profile_picture = url
                }

                const res = await UpdateUser(userData.id, data);
                SetAuthUserLocalStorage(res.data.data.user)
                setUserData(res.data.data.user)
                imageRef.current.value = null
                success(res.data.message)
                setIsDisabled(false)
                setTimeout(() => {
                    navigate("/my-profile")
                }, 1500)
            }
            catch (e) {
                setIsDisabled(false)
                error(e.response.data.message)
            }
        }
    }

    useEffect(() => {
        const fetchCategories = async () => {
            /*
                Fetch categories append in array and set their state
            */
            try {
                const categories = []
                const { data } = await GetCategories()
                data.data.forEach(category => categories.push({
                    label: category.name,
                    value: category.id
                }))
                setOptions(categories)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        const getProfile = async () => {
            /*
                Fetch user profile and set thier info in input 
                also set user image preview in file.file_url
            */

            try {
                const res = await GetUser(userData.id)
                const user = res.data.data.user
                let start_time = user?.brand_owner?.business_hours ? user?.brand_owner?.business_hours.split("to")[0] : "12:00 pm"
                let end_time = user?.brand_owner?.business_hours ? user?.brand_owner?.business_hours.split("to")[1] : "12:30 pm,"

                const todayDateWithStartTime = moment(new Date()).format("YYYY-MM-DD") + " " + start_time
                const todayDateWithEndTime = moment(new Date()).format("YYYY-MM-DD") + " " + end_time
                setStartTime(new Date(todayDateWithStartTime))  // convert date 2022-12-20 to Fri Dec 2022 20:30:00 (Pakistan Standard Time)
                setEndTime(new Date(todayDateWithEndTime))      // convert date 2022-12-20 to Fri Dec 2022 20:30:00 (Pakistan Standard Time)
                setFile({
                    ...file,
                    file_url: user?.brand_owner?.image_url
                })
                reset({
                    username: user?.brand_owner?.username,
                    brand_name: user?.brand_owner?.brand_name,
                    address: user?.brand_owner?.address,
                    website_url: user?.brand_owner?.website_url,
                    email: user?.email,
                    commission_payout: user?.brand_owner?.commission_payout || 0,
                    bio: user?.brand_owner ? user?.brand_owner?.bio : user?.shopper?.bio,
                    ...(user?.brand_owner?.category ? {
                        brand_category: {
                            label: user?.brand_owner?.category?.name,
                            id: user?.brand_owner?.category?.id
                        }
                    } : null)
                })
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        fetchCategories()
        getProfile()
    }, [])

    return (
        <>
            <ToastContainer />
            <BusinessHourPopup setTimeError={setTimeError} businessHoursPopup={businessHoursPopup} setBusinessHoursPopup={setBusinessHoursPopup} startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} />

            <div className="auth-card edit-profile-form">
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>EDIT PROFILE</h3>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='box text-center mt-4 mb-2'>
                        <label className="post-profile-wrapper" htmlFor="post-profile">
                            <input
                                ref={imageRef}
                                className='d-none'
                                type="file"
                                accept="image/*"
                                id="post-profile"
                                onChange={(e) => (
                                    setFile({
                                        file: e.target.files[0],
                                        file_url: URL.createObjectURL(e.target.files[0])
                                    })
                                )}
                            />
                            <img crossorigin="anonymous" src={file.file_url ? file.file_url : Assets.BrandAvatarLarge2} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                        </label>
                    </div>
                    <span className="d-block text-center">Upload/capture profile picture</span>

                    <div className='mt-30 px-40'>
                        <FloatingLabel controlId="username" label="User Name *">
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                maxLength={VALIDATIONS.NAME}
                                type="text"
                                placeholder="User Name *"
                                {...register("username",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.USER_NAME_REQUIRED
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9]*$/,
                                            message: VALIDATIONS_TEXT.USER_NAME_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <UserFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.username && <small className='text-start d-block text-danger mt-1'>{errors.username.message}</small>}

                        <FloatingLabel className="mt-20" controlId="name" label="Brand Name *">
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                maxLength={VALIDATIONS.NAME}
                                type="text"
                                placeholder="Brand name *"
                                {...register("brand_name",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.BRAND_NAME_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <UserFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.brand_name && <small className='text-start d-block text-danger mt-1'>{errors.brand_name.message}</small>}

                        <div className="react-select">
                            <div className="icon-wrapper">
                                <BrandFill className='icon' />
                            </div>

                            <Controller
                                control={control}
                                name="brand_category"
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                    },
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder="Brand Category *"
                                        className="mt-20 form-control"
                                        classNamePrefix="react-select-container"
                                        isSearchable={false}
                                        options={options}
                                    />
                                )}
                            />
                        </div>
                        {errors.brand_category && <small className='text-start d-block text-danger mt-1'>{errors.brand_category.message}</small>}

                        <FloatingLabel className="mt-20" controlId="businessHours" label="Business Hours *" onClick={() => setBusinessHoursPopup(true)}>
                            <Form.Control
                                value={(startTime && endTime) && `${moment(startTime).format("hh:mm a")} - ${moment(endTime).format("hh:mm a")}`}
                                type="text"
                                placeholder="Business Hours *"
                                readOnly
                            />
                            <div className="icon-wrapper">
                                <ClockFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {timeError && <small className='text-start d-block text-danger mt-1'>{(!startTime || !endTime) ? "Business hours is required" : "Opening time should be less than closing time"}</small>}

                        {/* <FloatingLabel className="mt-20" controlId="websiteUrl" label="Website URL *">
                            <Form.Control
                                type="text"
                                placeholder="Website URL *"
                                {...register("website_url",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.WEBSITE_URL_REQUIRED
                                        },
                                        pattern: {
                                            value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                            message: VALIDATIONS_TEXT.INVALID_WEBSITE_URL
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <GlobeFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.website_url && <small className='text-start d-block text-danger mt-1'>{errors.website_url.message}</small>} */}

                        {/* <FloatingLabel className="mt-20" controlId="address" label="Address *">
                            <Form.Control
                                maxLength={VALIDATIONS.ADDRESS_MAX}
                                type="text"
                                placeholder="Address *"
                                {...register("address",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.ADDRESS_MAX,
                                            message: VALIDATIONS_TEXT.ADDRESS_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <LocationFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.address && <small className='text-start d-block text-danger mt-1'>{errors.address.message}</small>} */}

                        <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                            <Form.Control
                                maxLength={VALIDATIONS.EMAIL}
                                type="email"
                                readOnly
                                placeholder="Email address *"
                                {...register("email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <MessageIconFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                        {/* <FloatingLabel className="mt-20" controlId="paypal_email" label="PayPal Email Address">
                            <Form.Control
                                maxLength={VALIDATIONS.EMAIL}
                                type="email"
                                placeholder="PayPal Email Address"
                                {...register("paypal_email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: false,
                                            message: VALIDATIONS_TEXT.PAYPAL_EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <PaypalFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.paypal_email && <small className='text-start d-block text-danger mt-1'>{errors.paypal_email.message}</small>} */}


                        <FloatingLabel className="mt-20" controlId="commission" label="Commission Payout % *">
                            <Form.Control
                                onKeyDown={(e) => (CHARACTER_ARRAY_FOR_COMMISSION_PAYOUT.includes(e.key) && e.preventDefault())}
                                maxLength={VALIDATIONS.COMMISSION_PAYOUT}
                                type="number"
                                placeholder="Commission Payout % *"
                                {...register("commission_payout",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.COMMISSION_PAYOUT,
                                            message: VALIDATIONS_TEXT.COMMISSION_PAYOUT_MAX_LENGTH
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.COMMISSION_PAYOUT_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <DollarFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.commission_payout && <small className='text-start d-block text-danger mt-1'>{errors.commission_payout.message}</small>}

                        <div className='bio-input mt-20'>
                            <div className="icon-wrapper">
                                <BioFill className='icon' />
                            </div>

                            <Form.Control
                                className='pb-0'
                                as="textarea"
                                maxLength={VALIDATIONS.BIO_MAX}
                                placeholder="Bio"
                                {...register("bio",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.BIO_MAX,
                                            message: VALIDATIONS_TEXT.BIO_MAX
                                        },
                                        required: {
                                            value: false,
                                            message: VALIDATIONS_TEXT.BIO_REQUIRED
                                        },
                                    })
                                }
                            />
                            {errors.bio && <small className='text-start d-block text-danger mt-1'>{errors.bio.message}</small>}
                        </div>
                        {errors.bio && <small className='text-start d-block text-danger mt-1'>{errors.bio.message}</small>}

                        <Button disabled={isDisabled} type="submit" className='btn-solid my-5'>
                            {isDisabled ? <Loader /> : "UPDATE PROFILE"}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default BrandEditProfileForm