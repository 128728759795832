import React, { useContext, useRef, useState, useCallback } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { ChevronRight, Times } from '../../constants/svgs'
import { TiTimes } from "react-icons/ti"
import SchedulePostPopup from '../popup/SchedulePostPopup'
import { IMAGE_FILE_FORMAT, VALIDATIONS, VALIDATIONS_TEXT, VIDEO_FILE_FORMAT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify'
import { error, success } from '../../constants/msg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AddProductContext, UserData, UserRole } from "../../App"
import { useEffect } from 'react'
import { FaCropAlt } from 'react-icons/fa'
import { BsCrop, BsPlayCircleFill } from 'react-icons/bs'
import ImageCropPopup from '../popup/ImageCropPopup'
import { FILE_TYPE } from '../../constants/app-constants'
import { constant, roles } from '../../utils/constants'
import { UploadFileToS3 } from '../../utils/helper'
import { AddPost } from '../../services/posts/posts'
import Loader from '../Loader/Loader'
import { generateVideoThumbnails, importFileandPreview } from "@rajesh896/video-thumbnails-generator";
import { useMemo } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import { defaultStyle } from '../../utils/helper'
import { GetUserFollowedBrands } from '../../services/users/users'

const CreatePostForm = () => {
    window.history.replaceState({}, "") // clear location state on reload
    const { register, handleSubmit, getValues, control, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const location = useLocation()
    const navigate = useNavigate()
    const fileRef = useRef()
    const [isDisabled, setIsDisabled] = useState(false)
    const [fileError, setFileError] = useState(false)
    const [schedulePostPopup, setSchedulePostPopup] = useState(false)
    const [imgCropPopup, setImgCropPopup] = useState(false)
    const [addProduct, setAddProduct] = useContext(AddProductContext)
    const [handleChangeLoading, setHandleChangeLoading] = useState(false)
    const [taggedSuggestions, setTaggedSuggestions] = useState([])
    const [mentions, setMentions] = useState([])
    const [descriptionText, setDescriptionText] = useState("")
    const [userRole, setUserRole] = useContext(UserRole)
    const [plainText, setPlainText] = useState('')

    const [file, setFile] = useState({
        file: null,
        original_file: null,
        file_url: null,
        original_file_url: null,
        file_type: null
    })

    const uploadImageToS3 = async (selectedFile) => {
        /*  Check file type and set dirName A/C to it 
            If file type == image than set dirName = "images" else if file type == "video" than set dirName = "videos"
        */
        const dirName = file.file_type === FILE_TYPE.image ? constant.IMAGE_DIR_NAME : constant.VIDEO_DIR_NAME
        try {
            const res = await UploadFileToS3(selectedFile, dirName)
            return res
        }
        catch (e) {
            error(e.message)
        }
    }

    const removeSelectedPost = (index) => {
        addProduct.splice(index, 1)
        setAddProduct([...addProduct])
    }

    const returnDataCreateForApi = (uploadedImage, uploadedThumbnail, isSchedule, published_at, published_time) => {
        // Make data compatible for api
        const data = {}
        const images = []
        const products = []

        images.push({
            path: uploadedImage.url,
            mime_type: uploadedImage.mime_type.split("/")[1],
        })
        addProduct.forEach((data) => {
            products.push(data.id)
        })
        data.images = images
        data.products = products
        data.description = plainText
        data.mentions = mentions

        if (uploadedThumbnail) {    // for video only (if uploaded media is video)
            images.push({
                path: uploadedThumbnail.url,
                mime_type: uploadedThumbnail.mime_type.split("/")[1],
            })
        }

        if (isSchedule) {
            const mergeDateTime = published_at + " " + published_time
            const publishedDateTime = new Date(mergeDateTime)

            data.published_at = publishedDateTime
            data.published_time = published_time
        }

        return data
    }

    const resetFieldsAndState = () => {
        setFile({
            file: null,
            original_file: null,
            file_url: null,
            original_file_url: null,
            file_type: null
        })

        reset({
            description: ""
        })
    }

    const base64UrlToFile = (dataUrl, fileName) => {
        let arr = dataUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    }

    const createPost = async (isSchedule, published_at, published_time) => {
        /*
        Check if file is not selected than set error "Please select a file" else
        Upload file on S3 and append url in data than send data to api.
        After successfull upload empty file state and file-input value and addProduct state and reset description field
    */
        const givenDateTime = `${published_at} ${published_time}`
        const currentDateTime = new Date();
        const givenDateTimeObj = new Date(givenDateTime);

        if (givenDateTimeObj < currentDateTime) {
            error("Please select a valid date and time")
        }

        else if (!file.file) {
            setFileError(true)
        }
        else {
            /*
                Check if uploaded file is in supported format 'IMAGE_FILE_FORMAT array of supported format for image'
                'VIDEO_FILE_FORMAT array of supported format for video'
                If uploaded file format is not supported than show error else continue
            */
            if (!IMAGE_FILE_FORMAT.includes((file.file.type.split("/")[1])) && !VIDEO_FILE_FORMAT.includes((file.file.type.split("/")[1]))) {
                error("File format is not supported")
            }
            else {
                setIsDisabled(true)
                try {
                    let uploadedThumbnail = null
                    const uploadedImage = await uploadImageToS3(file.file)

                    if (VIDEO_FILE_FORMAT.includes((file.file.type.split("/")[1]))) {            // if video is uploaded than run only
                        const [splited] = file.file_url.split(";")                              // split base64 string
                        const ext = splited.split("/")[1].trim()                               // get extension name of thumbnail image
                        const thumbnailFile = base64UrlToFile(file.file_url, `thumb.${ext}`)   // convert generated base64 string to file
                        uploadedThumbnail = await uploadImageToS3(thumbnailFile)               // upload video thumbnail image 
                    }

                    const postData = returnDataCreateForApi(uploadedImage, uploadedThumbnail, isSchedule, published_at, published_time)
                    const res = await AddPost(postData)
                    setAddProduct([])
                    setDescriptionText("")
                    setPlainText("")
                    fileRef.current.value = null
                    resetFieldsAndState()
                    setIsDisabled(false)
                    success(res.data.message)
                    setTimeout(() => {
                        navigate("/home")
                    }, 1500)
                }
                catch (e) {
                    setIsDisabled(false)
                    error(e.response.data.message)
                }
            }
        }
    }

    const handleChange = async (myFile) => {
        /*
            on every onChange check if uploaded file is video than generate thumbnail
            and save generated thumbnail in state else don't genereate thumbnail only
            create URL to create preview
        */
        let thumbnail = null
        const type = myFile.type.split("/")[0]  // get file type

        if (type === "video") {
            setHandleChangeLoading(true)
            thumbnail = await generateVideoThumbnails(myFile, 1)    // generate base64 String (image)
            setHandleChangeLoading(false)
        }

        setFile({
            file: myFile,
            original_file: myFile,
            file_url: thumbnail ? thumbnail[1] : URL.createObjectURL(myFile),
            original_file_url: thumbnail ? thumbnail[1] : URL.createObjectURL(myFile),
            file_type: myFile.type.split("/")[0]
        })
        setFileError(false)
    }

    const onSubmit = async (data) => {
        await createPost(false, null, null)
    }

    useEffect(() => {
        if (location.state) {
            const data = location.state.data

            if (data.fileData.file) {
                setFile(data.fileData)
            }
            if (data.descriptionText) {
                setDescriptionText(data.descriptionText)
                reset({ description: data.descriptionText })
            }
            if (data.plainText) {
                setPlainText(data.plainText)
            }
            if (data.mentions && data.mentions.length > 0) {
                setMentions(data.mentions)
            }
        }

        const getUserFollowedBrands = async () => {
            // Fetch brands that user followed.... here we use it to show suggestion for tagged
            try {
                let followed_brands = []
                let params = {}
                const res = await GetUserFollowedBrands(params)
                res.data.data.forEach((item) => {
                    followed_brands.push({
                        image_url: item?.brand_owner ? item?.brand_owner?.image_url : item?.shopper?.image_url,
                        id: item?.id,
                        display: item?.name
                    })
                })
                setTaggedSuggestions(followed_brands)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (userRole == roles.shopper) {
            getUserFollowedBrands()
        }
    }, [userRole])

    return (
        <>
            <SchedulePostPopup createPost={createPost} isDisabled={isDisabled} schedulePostPopup={schedulePostPopup} setSchedulePostPopup={setSchedulePostPopup} />
            <ImageCropPopup base64UrlToFile={base64UrlToFile} file={file} setFile={setFile} imgCropPopup={imgCropPopup} setImgCropPopup={setImgCropPopup} />
            <ToastContainer />
            <div className={`create-edit-post-form ${addProduct.length > 0 && "h-auto"}`}>
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>CREATE POST</h3>
                    <div className='text-end'>
                        <Link to="/home">
                            <Times />
                        </Link>
                    </div>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`box text-center ${!file.file && "pt-40 pb-40"}`}>
                        <label className="img-label d-block w-100" htmlFor="post-profile">
                            <input
                                ref={fileRef}
                                className='d-none'
                                type="file"
                                accept=".png, .jpg, .jpeg, .mp4"
                                id="post-profile"
                                onChange={(e) => handleChange(e.target.files[0])}
                            />
                            {
                                file.file ?
                                    <div className="full-image">
                                        {
                                            handleChangeLoading ?
                                                <div className="d-flex align-items-center justify-content-center h-100"><Loader /></div> :
                                                <>
                                                    <img className='h-100 w-100' src={file.file_url} alt="" />
                                                    <Button className="crop-btn" onClick={() => setImgCropPopup(true)}><BsCrop /></Button>
                                                </>
                                        }
                                    </div>
                                    :
                                    <div className="post-profile-wrapper">
                                        <img src={Assets.PostUploadPlaceholder} alt="" />
                                    </div>
                            }
                        </label>
                    </div>
                    {fileError && <small className='text-start d-block text-danger mt-1'>{VALIDATIONS_TEXT.FILE_REQUIRED}</small>}

                    <div className='my-4 form-control p-3'>
                        <Controller
                            control={control}
                            name="description"
                            rules={{
                                maxLength: {
                                    value: VALIDATIONS.DESCRIPTION_MAX,
                                    message: VALIDATIONS_TEXT.DESCRIPTION_MAX,
                                },
                            }}
                            render={({ field }) => (
                                <MentionsInput
                                    {...field}
                                    spellCheck={false}
                                    maxLength={VALIDATIONS.DESCRIPTION_MAX}
                                    className='mention-input'
                                    placeholder="Write a description...."
                                    value={descriptionText}
                                    onChange={(e, newValue, newPlainTextValue, mentions) => {
                                        field.onChange(newPlainTextValue)
                                        setMentions(mentions?.map((item) => item?.id))
                                        setDescriptionText(e.target.value)
                                        setPlainText(newPlainTextValue)
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={taggedSuggestions}
                                        displayTransform={(id, display) => `@${display}`}
                                        markup='@[__display__](__id__)'
                                    />
                                </MentionsInput>
                            )}
                        />
                        {errors.description && <small className='text-start d-block text-danger mt-1'>{errors.description.message}</small>}
                    </div>

                    <div className="added-product-wrapper">
                        <NavLink to="/add-post" state={{ fileData: file, descriptionText: descriptionText, plainText: plainText, mentions: mentions, route: "/create-post" }}>
                            <div className='d-flex align-item-center justify-content-between border-bottom pb-3'>
                                <h5>ADD PRODUCT</h5>
                                <div>
                                    <ChevronRight />
                                </div>
                            </div>
                        </NavLink>

                        <div className="wrapper d-flex align-items-center flex-wrap pt-3">
                            <NavLink to="/add-post" state={{ fileData: file, descriptionText: descriptionText, plainText: plainText, mentions: mentions, route: "/create-post" }} className={`${addProduct.length <= 0 ? "d-none" : "d-block"}`}>
                                <div className='add-post-box mb-3 me-3 d-flex align-items-center justify-content-center'>
                                    <AiOutlinePlus className='icon' />
                                </div>
                            </NavLink>

                            {
                                addProduct.length > 0 &&
                                addProduct?.map((data, index) => (
                                    <div key={index} className='img-wrapper mb-3 me-3'>
                                        <div className='icon-times-wrapper d-flex align-items-center justify-content-center' onClick={() => removeSelectedPost(index)}>
                                            <TiTimes className="icon" />
                                        </div>
                                        <img onError={(e) => e.target.src = Assets.GeneralPlaceholder} src={data.image_url} alt="" />
                                    </div>
                                ))
                            }
                        </div>

                        <div className="mt-4">
                            <div className={`${addProduct.length <= 0 ? "d-none" : "d-block"}`}>
                                <Row>
                                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                                        <Button disabled={isDisabled} type="submit" className="btn-transparent me-2">
                                            {isDisabled ? <Loader /> : "POST NOW"}
                                        </Button>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Button className="btn-solid" onClick={() => setSchedulePostPopup(true)}>POST LATER</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Form>
            </div >
        </>

    )
}

export default CreatePostForm