import React, { useEffect, useState, useContext } from 'react'
import { Navbar, Nav, Container, Button, Offcanvas, Form } from "react-bootstrap"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Assets from "../../constants/images"
import { BsBellFill } from 'react-icons/bs'
import { RiMessage2Fill } from "react-icons/ri"
import { MdAddToPhotos } from "react-icons/md"
import ShopperProfilePopup from "../../components/popup/ShopperProfilePopup"
import BrandProfilePopup from "../../components/popup/BrandProfilePopup"
import NotificationPopup from "../../components/popup/NotificationPopup"
import MessagePopup from "../../components/popup/MessagePopup"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { BellFill, DropDownArrow, MessageFillGreen, MessageIconFill, SearchOutline } from '../../constants/svgs'
import { UserRole, UserData } from '../../App'
import { ROLES } from '../../constants/app-constants'
import SearchPopup from '../../components/popup/SearchPopup'
import { error } from '../../constants/msg'
import { GetNotifications } from '../../services/notifications/notifications'

const DashboardNavbar = () => {
    const navigate = useNavigate()
    const [searchPopup, setSearchPopup] = useState(false)
    const [profilePopup, setProfilePopup] = useState(false)
    const [notificationPopup, setNotificationPopup] = useState(false)
    const [notificationAlert, setNotificationAlert] = useState(false)
    const [messagePopup, setMessagePopup] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [count, setCount] = useState(0)
    const [messageAlert, setMessageAlert] = useState(false)

    const userProfilePopup = {
        [ROLES.brand]: <BrandProfilePopup profilePopup={profilePopup} setProfilePopup={setProfilePopup} />,
        [ROLES.shopper]: <ShopperProfilePopup profilePopup={profilePopup} setProfilePopup={setProfilePopup} />
    }

    const handleBrandNavigation = () => {
        if (userRole == ROLES.brand) {
            return "d-none"
        }
        else {
            return "d-block";
        }
    }

    useEffect(() => {
        const getNotifications = async () => {
            // fetch notifications of user
            let counter = 0
            try {
                const params = {}
                const res = await GetNotifications(params)
                let data = res.data.data.data
                data.forEach((data) => {  // Map thorugh notification array and check if notification is not read than increment counter by 1
                    if (!data?.read && data.from_user) {
                        counter += 1
                    }
                })
                // setCount(counter)
                setNotificationAlert(counter == 0 ? false : true)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        // call notification api after every 3 minutes to check if there is new notification
        getNotifications()
    }, [notificationPopup])

    return (
        <header className="header">
            {userProfilePopup[userRole]}

            <NotificationPopup notificationPopup={notificationPopup} setNotificationPopup={setNotificationPopup} setNotificationAlert={setNotificationAlert} count={count} setCount={setCount} />
            <MessagePopup messagePopup={messagePopup} setMessagePopup={setMessagePopup} messageAlert={messageAlert} setMessageAlert={setMessageAlert} />
            <SearchPopup searchPopup={searchPopup} setSearchPopup={setSearchPopup} />

            <Navbar key="xl" expand="xl" className="py-3">
                <Container fluid>
                    <NavLink to="/home">
                        <img className='d-none d-sm-block' src={Assets.LogoHorizontal} alt="" />
                        <img className='logo-small d-block d-sm-none img-fluid' src={Assets.Logo} alt="" />
                    </NavLink>
                    <div className='d-flex align-items-center justify-content-between d-xl-none'>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center" onClick={() => setSearchPopup(true)}>
                            <SearchOutline className="icon" />
                        </div>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center" onClick={() => setMessagePopup(true)}>
                            <div className={`notification-count ${messageAlert ? 'd-flex' : 'd-none'}`}></div>
                            <MessageFillGreen className="icon" />
                        </div>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center" onClick={() => setNotificationPopup(true)}>
                            <div className={`notification-count ${notificationAlert ? 'd-flex' : 'd-none'}`}></div>
                            <BellFill className="icon" />
                        </div>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center">
                            <img src={userData?.brand_owner ? userData?.brand_owner?.image_url : userData?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" onClick={() => setProfilePopup(true)} />
                        </div>
                        <Navbar.Toggle onClick={() => setMenuOpen(true)} aria-controls={`offcanvasNavbar-expand-xl`} />
                    </div>

                    <Navbar.Offcanvas
                        show={menuOpen}
                        onHide={() => setMenuOpen(false)}
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="end"
                        className="dashboard-sidebar"
                    >
                        <Offcanvas.Header className='justify-content-end' closeButton></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="align-items-xl-center justify-content-xl-start flex-grow-1 ms-xl-5 ps-xl-5">
                                <NavLink onClick={() => setMenuOpen(false)} to="/home" className="py-xl-2 mx-xl-4">Home</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/brands" className={`py-xl-2 mx-xl-4 ${handleBrandNavigation()}`}>Brands</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/about" className="py-xl-2 mx-xl-4">About</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/contact" className="py-xl-2 mx-xl-4">Contact</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/extension" className={`py-xl-2 mx-xl-4}`}>Extension</NavLink>

                            </Nav>
                            <div className="d-flex flex-column flex-xl-row align-items-xl-center">
                                <div className="me-xxl-20 me-xl-3">
                                    <Link to="/create-post" onClick={() => setMenuOpen(false)} className="btn-solid create-post-btn">+ CREATE POST</Link>
                                </div>
                                <div className="me-3 me-xxl-20 icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center" onClick={() => setSearchPopup(true)}>
                                    <SearchOutline className="icon" />
                                </div>
                                <div className="me-3 me-xxl-20 icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center" onClick={() => setMessagePopup(true)}>
                                    <MessageFillGreen className="icon" />
                                </div>
                                <div className="me-3 me-xxl-20 icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center" onClick={() => setNotificationPopup(true)}>
                                    <div className={`notification-count ${notificationAlert ? 'd-flex' : 'd-none'}`}></div>
                                    <BellFill className="icon" />
                                </div>
                                <div onClick={() => setProfilePopup(true)} className="icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center">
                                    <img src={userData?.brand_owner ? userData?.brand_owner?.image_url : userData?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                </div>
                                <div className='d-none d-xl-block ms-3' onClick={() => setProfilePopup(true)}>
                                    <DropDownArrow />
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    )
}

export default DashboardNavbar