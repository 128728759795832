import React, { useContext, useState } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { UserData, UserRole } from '../../App'
import Assets from '../../constants/images'
import { success, error } from '../../constants/msg'
import { DeleteComment } from '../../services/comments/comments'
import { EmptyLocalStorage } from '../../services/localStorage/localStorage'
import { DeleteAccount } from '../../services/users/users'
import Loader from '../Loader/Loader'

const DeleteCommentPopup = ({ selectedComment, comments, setComments, deleteCommentPopup, setDeleteCommentPopup }) => {
    const [isDisabled, setIsDisabled] = useState(false)

    const deleteComment = async () => {
        // Delete comment and remove the comment from comment array
        setIsDisabled(true)
        try {
            const res = await DeleteComment(selectedComment.id)
            const temp = [...comments]
            temp.splice(selectedComment.index, 1)   // remove the deleted comment from array
            setComments(temp)
            setDeleteCommentPopup(false)
            success(res.data.message)
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup delete-comment-popup'
                show={deleteCommentPopup}
                onHide={() => setDeleteCommentPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>DELETE ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, that you want to delete <br />
                                    your comment?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={() => deleteComment()}>
                                    {isDisabled ? <Loader /> : "DELETE COMMENT"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setDeleteCommentPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteCommentPopup