import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { firebaseCredential } from "../utils/constants";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: firebaseCredential.apiKey,
    authDomain: firebaseCredential.authDomain,
    databaseURL: firebaseCredential.databaseURL,
    projectId: firebaseCredential.projectId,
    storageBucket: firebaseCredential.storageBucket,
    messagingSenderId: firebaseCredential.messagingSenderId,
    appId: firebaseCredential.appId,
    measurementId: firebaseCredential.measurementId
}

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth()