import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate, redirect  } from 'react-router-dom'
import { FcGoogle } from "react-icons/fc"
import { CgFacebook } from "react-icons/cg"
import { useForm } from 'react-hook-form'
import { GOOGLE_AUTH, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { error, success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { LockFill, MessageIconFill } from '../../constants/svgs'
import Loader from '../Loader/Loader'
import { GetFirebaseToken, Login, ResendOtp, SocialLogin } from '../../services/auth/auth'
import { SetTokenLocalStorage, SetAuthUserLocalStorage, SetUserRoleLocalStorage, GetUserRoleLocalStorage } from "../../services/localStorage/localStorage"
import { constant } from '../../utils/constants'
import { useContext } from 'react'
import { UserData, UserRole } from '../../App'
import { ROLES } from '../../constants/app-constants'
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import SelectRolePopup from '../popup/SelectRolePopup'
import { IsUserAlreadyRegistered } from '../../services/users/users'
import { signInWithCustomToken } from 'firebase/auth'
import { auth } from '../../firebase'

const Signin = () => {
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const [selectRolePopup, setSelectRolePopup] = useState({
        isShow: false,
        data: null,
    })
    const [showPassword, setShowPassword] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const handleSetUserRole = (user) => {
        if (user.is_brand == 0) {
            SetUserRoleLocalStorage(ROLES.shopper)
            setUserRole(ROLES.shopper)
        }
        else if (user.is_brand == 1) {
            SetUserRoleLocalStorage(ROLES.brand)
            setUserRole(ROLES.brand)
        }
    }

    const onSubmit = async (data) => {
        /*
            Business logic for Signin 
            Hit Signin Api then set token, user and user-role detail inside localStorage and UserContext
        */
        setIsDisabled(true)
        try {
            data.device_type = constant.DEVICE_TYPE
            const res = await Login(data)
            await signInToFirebase(res?.data?.data?.user?.access_token)

            const brand_id = res?.data?.data?.user?.brand_owner?.id;
            const is_domain_verify = res?.data?.data?.user?.brand_owner?.is_domain_verify;
            const website_url = res?.data?.data?.user?.brand_owner?.website_url;

            if (res.data.data.user.email_verified === 0) {
                await ResendOtp({ email: res.data.data.user.email })
                setIsDisabled(false)
                success("Please verify your email")
                setTimeout(() => {
                    navigate("/email-verification", { state: { email: res.data.data.user.email, type: "register", user: res.data.data.user } })
                }, 1500)
            }
            else {
                if (is_domain_verify === 0) {
                    SetTokenLocalStorage(res.data.data.user.access_token)
                    SetAuthUserLocalStorage(res.data.data.user)
                    setUserData(res.data.data.user)
                    handleSetUserRole(res.data.data.user)
                    setIsDisabled(false)
                    navigate("/generate-token", {
                        state: {
                            brand_id: brand_id,
                            website_url: website_url
                        }
                    })
                }
                else {
                    SetTokenLocalStorage(res.data.data.user.access_token)
                    SetAuthUserLocalStorage(res.data.data.user)
                    setUserData(res.data.data.user)
                    handleSetUserRole(res.data.data.user)
                    setIsDisabled(false)
                    navigate("/home")
                }
            }
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    const signInToFirebase = async (token) => {
        const res = await GetFirebaseToken(token)
        const response = await signInWithCustomToken(auth, res.data.data.token)
    }

    const isUserAlreadyRegistered = async (client_id) => {
        try {
            const res = await IsUserAlreadyRegistered(client_id)
            if (res.data.success) {
                return true

            }
            else {
                return false
            }
        }
        catch (e) {
            error(e.response.data.message)
            return
        }
    }

    const socialLogin = async (data) => {
        try {
            const res = await SocialLogin(data)
            await signInToFirebase(res?.data?.data?.user?.access_token)
            const resData = res.data.data
            SetTokenLocalStorage(resData.user.access_token)
            SetAuthUserLocalStorage(resData.user)
            setUserData(resData.user)
            handleSetUserRole(resData.user)

            if (resData?.user?.brand_owner) {
                if(resData?.brand_owner?.is_domain_verify == 0){
                    navigate("/generate-token")
                }
                else{
                    navigate("/profile-setup",{ replace: true })
                }
            }
            else {
                navigate("/home")
            }

        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const onGoogleLogin = async (provider, response) => {
        if (response) {
            const data = {
                platform: provider,
                client_id: response?.sub,
                token: response.access_token,
                email: response.email,
                full_name: response.name,
                profile_picture: response.picture,
                device_type: "web",
            }
            const isUserAlreadyExist = await isUserAlreadyRegistered(response?.sub)
            if (isUserAlreadyExist) {
                await socialLogin(data)
            }
            else {
                setSelectRolePopup({ isShow: true, data: data })
            }
        }
    }

    const onFacebookLogin = async (provider, response) => {
        if (response) {
            const data = {
                platform: provider,
                client_id: response.userID,
                token: response.accessToken,
                email: response.email,
                full_name: response.name,
                profile_picture: response.picture.data.url,
                device_type: "web",
            }

            const isUserAlreadyExist = await isUserAlreadyRegistered(response?.userID)
            if (isUserAlreadyExist) {
                await socialLogin(data)
            }
            else {
                setSelectRolePopup({ isShow: true, data: data })
            }
        }
    }

    return (
        <div className='auth-card signin-form'>
            <ToastContainer />
            <SelectRolePopup selectRolePopup={selectRolePopup} setSelectRolePopup={setSelectRolePopup} />

            <div>
                <h3 className='pb-30 border-bottom mb-30'>SIGN IN</h3>
                <p className='text-black'>Transforming shopping into an experience.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    <FloatingLabel controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                    <FloatingLabel className="mt-20" controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}


                    <div className='text-end mt-20 mb-40'>
                        <NavLink className="link" to="/forgot-password">FORGOT PASSWORD</NavLink>
                    </div>

                    <Button type="submit" className='btn-solid'>
                        {
                            isDisabled ? <Loader /> : "SIGN IN"
                        }
                    </Button>

                    <div className="line-text">
                        <hr />
                        <span className="text">Or Sign with</span>
                    </div>

                    <LoginSocialGoogle
                        isOnlyGetCode
                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        scope="openid profile email"
                        access_type="offline"
                        onResolve={({ provider, data }) => {
                            onGoogleLogin(provider, data)
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <Button className='mb-30 btn-transparent'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <FcGoogle />
                                <span className="ms-2">Sign in with Google</span>
                            </div>
                        </Button>
                    </LoginSocialGoogle>

                    <LoginSocialFacebook
                        isOnlyGetCode
                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                        scope={'email, public_profile'}
                        fieldsProfile={'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'}
                        onResolve={({ provider, data }) => {
                            onFacebookLogin(provider, data)
                        }}
                        onReject={(err) => {
                            console.log(err)
                        }}
                    >
                        <Button className='mb-30 btn-solid bg-blue'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <CgFacebook />
                                <span className="ms-2 text-white">Sign in with Facebook</span>
                            </div>
                        </Button>
                    </LoginSocialFacebook>

                    <span className="mb-20 d-block">Don't have an account? <NavLink className="link" to="/select-role">SIGN UP</NavLink></span>
                </div>
            </Form>
        </div>
    )
}

export default Signin