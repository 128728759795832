import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { MessageFill, Times } from '../../constants/svgs'
import MessageThreadCard from '../dashboard/MessageThreadCard'
import { db } from '../../firebase/index';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { UserData } from '../../App';
import { MdMessage } from 'react-icons/md'

const MessagePopup = ({ messagePopup, setMessagePopup, setMessageAlert }) => {
    const [userData, setUserData] = useContext(UserData)
    const [userThreads, setUserThreads] = useState([])
    const [searchText, setSearchText] = useState("")
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        setMessagePopup(false)
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }

    const userThreadSearchFilter = userThreads?.filter((value) => {
        return value?.channel_user_name?.toLowerCase()?.includes(searchText?.toLowerCase());
    })

    useEffect(() => {
        const fetchUsersThread = async () => {
            const chatsQuery = query(
                collection(db, "chats"),
                where('users', 'array-contains-any', [userData?.id])
            );

            await onSnapshot(chatsQuery, async (querySnapshot) => {
                let array = []
                const data = querySnapshot.docs.map((e) => {
                    let chatData = {
                        ...e.data(),
                        id: e?.id
                    }
                    return chatData
                })

                for (let i = 0; i < data.length; i++) {
                    const [userId] = data[i]?.users?.filter((item) => item != userData?.id)
                    const docRef = doc(db, "users", userId?.toString())
                    const userDoc = await getDoc(docRef);
                    const user = userDoc.data()

                    array.push({
                        id: user?.id,
                        channel_user_name: user?.name,
                        channel_user_profile_image: user?.image,
                        message: data[i]?.last_message,
                        created_at: data[i]?.created_at,
                        message_count: data[i]?.message_count,
                        channel_id: data[i]?.id,
                    })


                    if (data[i]?.message_count > 0) {
                        console.log("=-=-=-=-")
                        if (setMessageAlert) {
                            setMessageAlert(true)
                            break;
                        }
                    }
                    else{
                        setMessageAlert(false)
                    }
                }
                setUserThreads(array)
            });
        }

        fetchUsersThread()
    }, [])

    return (
        <Modal
            className='theme-popup message-popup'
            show={messagePopup}
            onHide={() => setMessagePopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex text-center mb-20 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>MESSAGES <span>({userThreads?.length})</span></h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setMessagePopup(false)}>
                            <Times />
                        </div>
                    </div>

                    {
                        userThreads?.length <= 0 ?
                            <div className='no-msg-box'>
                                <MdMessage className='msg-icon' />
                                <span className='no-msg-text'>No message here</span>
                            </div>
                            :
                            <>
                                <div className='notification-container'>
                                    <div className='search-wrapper'>
                                        <Form.Control
                                            value={searchText}
                                            onChange={(e) => handleSearch(e)}
                                            type="text"
                                            placeholder="Search"
                                            className="search-input"
                                            aria-label="Search"
                                            onKeyDown={(e) => e.keyCode === 13 && setSearchText("")}
                                        />
                                    </div>
                                </div>

                                <div className="message-wrapper">
                                    {
                                        userThreadSearchFilter?.map((item, index) => (
                                            <NavLink onClick={() => setMessagePopup(false)} style={{ textDecoration: "none" }} key={index} to={`/view-all-messages/${item?.channel_id}`}>
                                                <MessageThreadCard data={item} />
                                            </NavLink>
                                        ))
                                    }
                                </div>

                                <div className="view-all-box text-center pt-3">
                                    <Link to="/view-all-messages" onClick={() => setMessagePopup(false)}>VIEW ALL MESSAGES</Link>
                                </div>
                            </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MessagePopup
