import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink, Link, Navigate, useNavigate } from "react-router-dom";
import { AddFolderPopupContext } from "../../App";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import Assets from "../../constants/images";
import { Times } from "../../constants/svgs";
import { ToastContainer } from "react-toastify";
import { success, error } from "../../constants/msg";
import Loader from "../Loader/Loader";
import { AddFolder } from "../../services/folders/folder";
import StatsCard from "../dashboard/StatsCard";
import { TopUp } from "../../services/transaction/transaction";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { constant } from '../../utils/constants'
import { UserData } from '../../App'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import TopupCard from "../dashboard/TopupCard";

const TopupPopup = ({ topupPopup, setTopupPopup, fetchData }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [userData, setUserData] = useContext(UserData)
    const [amount, setAmount] = useState(0);
    const navigate = useNavigate();
    const { register, handleSubmit, reset, atch, formState: { errors }, } = useForm({ mode: "onChange" });

    const onSubmit = async (data) => {
        // setIsDisabled(true);
        // try {
        // 	const res = await TopUp(data);
        // 	success(res?.data?.message);
        // 	setIsDisabled(false);
        // 	setTopupPopup(false);
        // } catch (e) {
        // 	error(e.response.data.message);
        // 	setIsDisabled(false);
        // }
    }

    useEffect(() => {
        // Reset input when popup is open or close
        reset({
            amount: "",
        })
    }, [topupPopup])

    const handleSkip = () => {
        setTopupPopup(false)
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className="theme-popup topup-popup"
                show={topupPopup}
                onHide={() => {
                    setTopupPopup(false)
                    setAmount(0)
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <TopupCard handleSkip={handleSkip} fetchData={fetchData} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TopupPopup;
