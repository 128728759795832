import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const PostDetailCardSkeleton = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className='post-detail-card'>
            <div className='text-center border-bottom mb-30 pb-30'>
                <Skeleton width={200} />
            </div>

            <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <Skeleton width={50} height={50} circle={true} />

                    <div className="ms-2 mt-0-2">
                        <Skeleton count={1} width={80} height={10} />

                        <div className="show-more-less">
                            <Skeleton count={1.5} width={200} height={15} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='post-img-wrapper mt-20'>
                <div className='post-img mb-20'>
                    <Skeleton count={1} style={{ width: "100%", height: "100%", borderRadius:"10px" }} />
                </div>

                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={1}
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        350: {
                            slidesPerView: 3,
                        },
                        576: {
                            slidesPerView: 4,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 4,
                        },
                        1200: {
                            slidesPerView: 4,
                        },
                        1300: {
                            slidesPerView: 5,
                        },
                    }}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-bottom"
                >
                    {
                        Array(3).fill(null)?.map((data, index) => (
                            <SwiperSlide key={index} className="ms-2">
                                <Skeleton count={1} width={100} height={60} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>

            <div className="mt-30">
                <Skeleton count={1} width={150} height={25} />
            </div>
        </div >
    )
}

export default PostDetailCardSkeleton