import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import PostCommentCard from '../components/dashboard/PostCommentCard'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const SchedulePostDetailPage = () => {
    const location = useLocation()
    const [blockUserPopup, setBlockUserPopup] = useState(false)

    const [data, setData] = useState({
        id: 1,
        name: "Elizabeth Thomas",
        profile_image: Assets.Avatar1,
        time: "2 Days ago",
        caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
        cover_image: Assets.PostImageLarge1,
        price: "$1.0",
        shop_image: [
            {
                img: Assets.PostImageSm1,
            },
            {
                img: Assets.PostImageSm2,
            },
            {
                img: Assets.PostImageSm4,
            },
            {
                img: Assets.PostImageSm5
            }
        ]
    })

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="/schedule-post" link3Text="Schedule Post" link4="#" link4Text="Post Details" />
            <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />

            <div className="pages post-detail-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostDetailCard data={data} setData={setData} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
                        </Col>

                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostCommentCard />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SchedulePostDetailPage