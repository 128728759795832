import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { error, success } from '../../constants/msg'
import { GetReportTypes, Report } from "../../services/report/report"

const ReportPopup = ({ data, reportTo, reportPopup, setReportPopup }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [reportTypes, setReportTypes] = useState([])

    useEffect(() => {
        const getReportTypes = async () => {
            // Get report types
            try {
                const res = await GetReportTypes()
                setReportTypes(res.data.data.data)
            }
            catch (e) {
                error(e.response.data.message)
                setIsDisabled(false)
            }
        }

        getReportTypes()
    }, [])

    const report = async (reporTypeId) => {
        // Report user/post ===> reportTo = we are going to report user/post
        setIsDisabled(true)
        try {
            const reportData = {
                report_type_id: reporTypeId,
                instance_type: reportTo,
                instance_id: data?.id,
                description: ""
            }
            setReportPopup(false)
            const res = await Report(reportData)
            success(res.data.message)
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup report-user-popup'
                show={reportPopup}
                onHide={() => setReportPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper pb-0">
                        <div className='text-center pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>Report USER</h3>
                        </div>

                        <div className='content-container text-center'>
                            <ul className={`${reportTypes.length <= 0 && "mb-0"}`}>
                                {
                                    reportTypes.length <= 0 ?
                                        <li className="pt-20">
                                            No report type found
                                        </li>
                                        :
                                        reportTypes?.map((data, index) => (
                                            <li key={index} className="border-bottom pt-20 pb-20">
                                                <Button disabled={isDisabled} className="p-0 btn-transparent" onClick={() => report(data?.id)}>{data?.name}</Button>
                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReportPopup