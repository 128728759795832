import React, { useState, useContext } from 'react'
import { Button, Dropdown, Row, Col } from 'react-bootstrap'
import { DotsHorizontal } from '../../constants/svgs'
import Assets from "../../constants/images"
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { ShareOutline, MessageOutline, HeartOutline, HeartFill, FavouriteOutline, FavouriteFill } from '../../constants/svgs'
import ShowMore from 'react-show-more';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { IMAGE_FILE_FORMAT, INSTANCE_TYPE, POSTS_STATUS, REPORT_INSTANCE_TYPE, STATUS } from '../../constants/app-constants';
import { AddRemovePostFromFavourite, AddRemovePostReaction, GetPosts, GetSchedulePosts, GetSinglePost, SharePost } from '../../services/posts/posts';
import { error, success } from '../../constants/msg';
import { useEffect } from 'react';
import PostDetailCardSkeleton from '../skeleton/PostDetailCardSkeleton';
import { ToastContainer } from 'react-toastify'
import BlockUserPopup from '../popup/BlockUserPopup';
import { FilterDateContext, UserData } from '../../App';
import DeletePostPopup from '../popup/DeletePostPopup';
import ReportPopup from '../popup/ReportPopup';
import Loader from '../Loader/Loader';
import { BsPlayCircleFill } from 'react-icons/bs';
import { handleLineBreakAndTagHighlghts } from '../../utils/helper';

const PostDetailCardListWithFilter = ({ link, isFromStats, isLoading, data, setData, setSelectedPostUser, setSelectedPost, setReportPopup, setDeletePostPopup, setBlockUserPopup, currentPage, setCurrentPage, totalPage, isLoadMore, isScheduled, sharePostPopup, setSharePostPopup, shareableLink, setShareableLink }) => {
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserData)
    const [filterDate, setFilterDate] = useContext(FilterDateContext)
    const [showMore, setShowMore] = useState(false)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const handleLike = async (postId, index) => {
        /* 
          By default post is not liked 
          OnClick we change liked = true if post is not liked else we change liked = false
        */
        let temp = [...data]
        temp[index].is_reacted = !temp[index].is_reacted
        setData(temp)

        try {
            const reactionData = {
                instance_id: postId,
                instance_type: INSTANCE_TYPE.post,
                reaction: temp[index].is_reacted ? STATUS.like : STATUS.dislike
            }
            await AddRemovePostReaction(reactionData)

        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const handleFavourite = async (postId, index) => {
        /* 
          By default post is not saved 
          OnClick we change saved = true if post is not saved else we change saved = false
        */

        const temp = [...data]
        temp[index].is_favourite = !temp[index].is_favourite
        setData(temp)
        try {
            const favouriteData = {
                post_id: postId,
                action: temp[index].is_favourite ? STATUS.addToFavourite : STATUS.removeFromFavourite,
            }
            await AddRemovePostFromFavourite(favouriteData)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const showMenuForOwnPost = (data) => {
        if (data?.user?.id == userData?.id) {
            return "d-block"
        }
        else {
            return "d-none"
        }
    }

    const sharePost = async (id) => {
        try {
            const data = {
                post_id: id,
            }
            const res = await SharePost(data)
            success(res.data.message)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const hideMenuForOwnPost = (data) => {
        if (data?.user?.id == userData?.id) {
            return "d-none"
        }
        else {
            return "d-block"
        }
    }

    const returnFormatedTime = (date) => {
        if (date == "an hour ago") {
            return "1 hour ago"
        }
        else if (date == "a day ago") {
            return "1 day ago"
        }
        else if (date == "a minute ago") {
            return "1 minute ago"
        }
        else {
            return date
        }
    }

    const getPublishedDateTime = (post) => {
        if (isScheduled || post?.is_published === "NO") {
            const dateTime = moment(post?.converted_date).format('[Schedule for] ddd, DD MMM YYYY [at] hh:mm A')
            return dateTime
        }
        else {
            const date = moment.utc(post?.created_at).fromNow()
            return returnFormatedTime(date)
        }
    }

    const handleNavigate = (user) => {
        if (user?.brand_owner) {
            if (user?.brand_owner?.user_id == userData?.id) {
                navigate("/my-profile")
            }
            else {
                navigate(`/brand/${user?.brand_owner?.user_id}`)
            }
        }
        else {
            if (user?.shopper?.user_id == userData?.id) {
                navigate("/my-profile")
            }
            else {
                navigate(`/user/${user?.shopper?.user_id}`)
            }
        }
    }

    return (
        <>
            {
                isLoading ?
                    <Row className='justify-content-center'>
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostDetailCardSkeleton />
                        </Col>
                    </Row>
                    :
                    data.length <= 0 ?
                        <div className='text-center'>{isFromStats ? filterDate?.isSet ? `No post available for ${filterDate.displayDateWithDay}` : "No post available" : "No post available"}</div>
                        :
                        data.map((post, index) => (
                            <Row key={index} className='justify-content-center'>
                                <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                    <div className='post-detail-card'>
                                        <div className='text-center border-bottom mb-30 pb-30'>
                                            <h3>{post?.user?.brand_owner ? post?.user?.brand_owner?.brand_name : post?.user?.shopper?.full_name}</h3>
                                        </div>

                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <div className="profile-wrapper cursor-pointer">
                                                    <img onClick={() => handleNavigate(post?.user)} src={post?.user?.brand_owner ? post?.user?.brand_owner?.image_url : post?.user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                                </div>

                                                <div className="ms-2 mt-0-2">
                                                    <span className="name d-block cursor-pointer" onClick={() => handleNavigate(post?.user)}><b>{post?.user?.brand_owner ? post?.user?.brand_owner?.brand_name : post?.user?.shopper?.full_name}</b></span>
                                                    <small className="time">{getPublishedDateTime(post)}</small>
                                                    <div className="show-more-less">
                                                        {
                                                            post?.description?.length > 80 ?
                                                                <>
                                                                    {
                                                                        showMore ?
                                                                            <p dangerouslySetInnerHTML={{ __html: handleLineBreakAndTagHighlghts(post?.description, post?.mentions) }} />
                                                                            :
                                                                            <p className='d-inline' dangerouslySetInnerHTML={{ __html: handleLineBreakAndTagHighlghts(post?.description?.substring(0, 80), post?.mentions) }} />
                                                                    }
                                                                    <span onClick={() => setShowMore(!showMore)}>{showMore ? " show less" : "...show more"}</span>
                                                                </>
                                                                :
                                                                <p dangerouslySetInnerHTML={{ __html: handleLineBreakAndTagHighlghts(post?.description, post?.mentions) }} />
                                                        }
                                                        <span className="price">{post?.price}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <DotsHorizontal />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="text-center">
                                                        <Dropdown.Item
                                                            className={`border-bottom ${hideMenuForOwnPost(post)}`}
                                                            onClick={() => {
                                                                setSelectedPost(post)
                                                                setReportPopup(true)
                                                            }}
                                                        >
                                                            Report Post
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className={`border-bottom ${showMenuForOwnPost(post)}`}
                                                            onClick={() => navigate(`/edit-post/${post?.id}`)}
                                                        >
                                                            Edit Post
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className={`border-bottom ${showMenuForOwnPost(post)}`}
                                                            onClick={() => {
                                                                setSelectedPost(post)
                                                                setDeletePostPopup(true)
                                                            }}
                                                        >
                                                            Delete Post
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className={`border-bottom ${hideMenuForOwnPost(post)}`}
                                                            onClick={() => {
                                                                setSelectedPostUser(post?.user)
                                                                setBlockUserPopup(true)
                                                            }}
                                                        >
                                                            Block User
                                                        </Dropdown.Item>

                                                        <Dropdown.Item className="">Cancel</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <div className='post-img-wrapper mt-20'>
                                            <Link to={`${link}/${post?.id}`}>
                                                {
                                                    post?.attachments.length == 1 ?
                                                        <div className='post-img'>
                                                            <img src={post?.attachments[0]?.attachment_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                                        </div>
                                                        :
                                                        <div className='post-img'>
                                                            <div className='video-thumbnail w-100 h-100'>
                                                                <BsPlayCircleFill className="icon" />
                                                                <img className='w-100' src={post?.attachments[1]?.attachment_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                                            </div>
                                                        </div>
                                                }
                                            </Link>

                                            <div className="mt-20 mb-20">
                                                <span className="shop-now">SHOP NOW</span>
                                            </div>

                                            <Swiper
                                                onSwiper={setThumbsSwiper}
                                                loop={post?.products.length >= 5 ? true : false}
                                                spaceBetween={10}
                                                slidesPerView={1}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 2,
                                                    },
                                                    350: {
                                                        slidesPerView: 3,
                                                    },
                                                    576: {
                                                        slidesPerView: 4,
                                                    },
                                                    768: {
                                                        slidesPerView: 4,
                                                    },
                                                    992: {
                                                        slidesPerView: 4,
                                                    },
                                                    1200: {
                                                        slidesPerView: 4,
                                                    },
                                                    1300: {
                                                        slidesPerView: 5,
                                                    },
                                                }}
                                                freeMode={true}
                                                watchSlidesProgress={true}
                                                modules={[FreeMode, Navigation, Thumbs]}
                                                className="swiper-bottom"
                                            >
                                                {
                                                    post?.products?.map((data, index) => (
                                                        <SwiperSlide key={index}>
                                                            {
                                                                userData?.brand_owner ?
                                                                    <img src={data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                                                    :
                                                                    <a href={data?.product_url + `?post_id=${post?.id}`}  target="_blank" className="d-block h-100 w-100">
                                                                        <img src={data?.image_url} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                                                    </a>
                                                            }
                                                        </SwiperSlide>
                                                    ))
                                                }
                                            </Swiper>
                                        </div>

                                        <div className="mt-30 border-top d-flex align-items-center justify-content-between">
                                            <div className="mt-20 d-flex align-items-center">
                                                <label htmlFor={`heart-${index}`}>
                                                    <div className='icon-wrapper me-4'>
                                                        <input onClick={e => handleLike(post?.id, index)} id={`heart-${index}`} className='d-none' type="checkbox" />
                                                        {
                                                            post?.is_reacted ?
                                                                <HeartFill /> :
                                                                <HeartOutline />
                                                        }
                                                        <span className='ms-2'></span>
                                                    </div>
                                                </label>

                                                <div className='icon-wrapper ms-2 me-4'>
                                                    <MessageOutline />
                                                    <span className='ms-2'></span>
                                                </div>

                                                <div
                                                    className='icon-wrapper ms-2'
                                                    onClick={() => {
                                                        setSharePostPopup(true)
                                                        setShareableLink(window.location.href)
                                                    }}
                                                >
                                                    <ShareOutline />
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor={`wishlist-${index}`}>
                                                    <div className='icon-wrapper'>
                                                        <input value={post?.is_favourite} onClick={e => handleFavourite(post?.id, index)} id={`wishlist-${index}`} className='d-none' type="checkbox" />
                                                        {
                                                            post?.is_favourite ?
                                                                <FavouriteFill /> :
                                                                <FavouriteOutline />
                                                        }
                                                        <span className=''></span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ))
            }
            <Row>
                <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
                    {
                        isLoadMore ?
                            <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                    }
                </Col>
            </Row>
        </>
    )
}

export default PostDetailCardListWithFilter