import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { UserData, UserRole } from '../../App'
import { ROLES } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { CalendarFill, DotsHorizontal, EyeFillWhite, FavouriteFillWhite } from '../../constants/svgs'

const ViewMyProfileCard = () => {
    const [userData, setUserData] = useContext(UserData)

    return (
        <div className='view-profile-card view-myprofile-card'>
            <div className="wrapper p-4 text-center">
                <div className="profile-wrapper mx-auto">
                    <img src={userData?.brand_owner ? userData?.brand_owner?.image_url : userData?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                </div>
                <div className="mt-2">
                    <h2 className='text-capitalize'>{userData?.brand_owner ? userData?.brand_owner?.brand_name : userData?.shopper?.full_name}</h2>
                    <span>{userData?.brand_owner ? userData?.brand_owner?.address : userData?.shopper?.address}</span>
                    <span>{userData?.email}</span>
                </div>
                <div className="mt-2 d-flex justify-content-center align-items-center">
                    <Link to="/edit-profile" className="btn-solid follow-btn me-2">EDIT PROFILE</Link>

                    <Link to="/schedule-post">
                        <div className='icon-wrapper bg-deep-grey me-2'>
                            <CalendarFill />
                        </div>
                    </Link>

                    <Link to="/my-favourites">
                        <div className='icon-wrapper bg-deep-grey'>
                            <FavouriteFillWhite />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ViewMyProfileCard