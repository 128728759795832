import React, { useContext, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import { GetBlockedUsers } from '../../services/users/users'
import UnblockAlertPopup from '../popup/UnblockAlertPopup'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../Loader/Loader'
import BlockedUserCardSkeleton from '../skeleton/BlockedUserCardSkeleton'
import { NavLink, useNavigate } from 'react-router-dom'
import { UserData } from '../../App'

const BlockedUserCard = () => {
    const [userData, setUserData] = useContext(UserData)
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedUser, setSelectedUser] = useState();
    const [unblockPopup, setUnblockPopup] = useState(false)
    const [blockedUserData, setBlockedUserData] = useState([])
    const navigate = useNavigate()

    const getBlockUsers = async () => {
        // fetch users blocked by me
        try {
            const params = {
                page: currentPage,
            }
            const res = await GetBlockedUsers(params)
            setCurrentPage(currentPage + 1)
            setBlockedUserData([...blockedUserData, ...res.data.data.data])
            if (currentPage == res.data.data.last_page) {
                setHasMore(false)
            }
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const handleNavigate = (user) => {
        if (user?.brand_owner) {
            if (user?.brand_owner?.user_id == userData?.id) {
                navigate("/my-profile")
            }
            else {
                navigate(`/brand/${user?.brand_owner?.user_id}`)
            }
        }
        else {
            if (user?.shopper?.user_id == userData?.id) {
                navigate("/my-profile")
            }
            else {
                navigate(`/user/${user?.shopper?.user_id}`)
            }
        }
    }

    return (
        <>
            <UnblockAlertPopup unblockPopup={unblockPopup} setUnblockPopup={setUnblockPopup} selectedUser={selectedUser} setSelectedUser={setSelectedUser} blockedUserData={blockedUserData} setBlockedUserData={setBlockedUserData} />
            <ToastContainer />
            <div className="block-user-card">
                <div className='text-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>BLOCKED USERS</h3>
                </div>

                <div className="text-center">
                    <p>
                        Once you block someone, that person can no longer
                        see things you post on your profile, tag you, start
                        a conversation with you, or follow you as a friend.
                    </p>
                </div>

                <div className="box-wrapper mt-30 border-top">
                    <InfiniteScroll
                        pageStart={currentPage}
                        threshold={200}
                        useWindow={false}
                        loadMore={getBlockUsers}
                        hasMore={hasMore}
                        loader={<div className='mt-2'><BlockedUserCardSkeleton /></div>}
                    >
                        {
                            (blockedUserData.length <= 0 && !isLoading) ?
                                <div className='text-center mt-30'>
                                    <img width={50} height={50} src={Assets.NoBlockedUserImage} />
                                    <h6 className='mt-2'>No Blocked Users</h6>
                                </div>
                                :
                                blockedUserData?.map((data, index) => (
                                    <div key={index} className="box border-bottom py-3 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center cursor-pointer" onClick={() => handleNavigate(data?.user)}>
                                            <div className="profile-wrapper me-2">
                                                <img src={data?.user?.brand_owner ? data?.user?.brand_owner?.image_url : data?.user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                            </div>
                                            <h6 className='text-capitalize'>{data?.user?.brand_owner ? data?.user?.brand_owner?.brand_name : data?.user?.shopper?.full_name}</h6>
                                        </div>

                                        <div>
                                            <Button className="btn-solid btn-unblock" onClick={() => {
                                                setUnblockPopup(true)
                                                setSelectedUser({
                                                    id: data?.user.id,
                                                    index: index,
                                                    name: data?.user?.brand_owner ? data?.user?.brand_owner?.brand_name : data?.user?.shopper?.full_name
                                                })
                                            }}>UNBLOCK</Button>
                                        </div>
                                    </div>
                                ))
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default BlockedUserCard