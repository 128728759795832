import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success, error } from "../../constants/msg"
import { LockFill } from '../../constants/svgs'
import { ChangePassword } from '../../services/auth/auth'
import Loader from '../Loader/Loader'

const ChangePasswordForm = () => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const password = watch("password")

    const resetFields = () => {
        reset({
            current_password: "",
            password: "",
            password_confirmation: ""
        })
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const res = await ChangePassword(data)
            success(res.data.message)
            setIsDisabled(false)
            resetFields()
        }
        catch (e) {
            error(e.response.data.errors[0].message)
            setIsDisabled(false)
        }
    }

    return (
        <div className="change-password-form h-100">
            <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                <h3 className='w-100 d-inline-block'>CHANGE PASSWORD</h3>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <span className="d-block text-center">
                    Enter your new password make different from previous
                </span>
                <div className='mt-30'>
                    <FloatingLabel controlId="current-password" label="Current Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showCurrentPassword ? "text" : "password"}`}
                            placeholder="Current Password *"
                            {...register("current_password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.CURRENT_PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                            {
                                showCurrentPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.current_password && <small className='text-start d-block text-danger mt-1'>{errors.current_password.message}</small>}

                    <FloatingLabel className="mt-20" controlId="new-password" label="New Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showNewPassword ? "text" : "password"}`}
                            placeholder="New Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.NEW_PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowNewPassword(!showNewPassword)}>
                            <VscEye className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}

                    <FloatingLabel className="mt-20" controlId="confirm-new-password" label="Confirm New Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showConfirmPassword ? "text" : "password"}`}
                            placeholder="Confirm New Password *"
                            {...register("password_confirmation",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.CONFIRM_NEW_PASSWORD_REQUIRED
                                    },
                                    validate: (value) =>
                                        value === password || "The Passwords do not match"
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            <VscEye className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.password_confirmation && <small className='text-start d-block text-danger mt-1'>{errors.password_confirmation.message}</small>}

                    <Button type="submit" className='btn-solid mt-40 mb-40'>
                        {isDisabled ? <Loader /> : "CHANGE PASSWORD"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ChangePasswordForm