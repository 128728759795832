import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from "react-bootstrap"
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import PagesSkeleton from "../components/skeleton/PagesSkeleton"
import { GetPageBySlug } from '../services/pages/pages'
import parse from 'html-react-parser';
import { error, success } from '../constants/msg'
import { ToastContainer } from 'react-toastify';
import { PAGES } from '../constants/app-constants'
import DeleteAccountPopup from '../components/popup/DeleteAccountPopup'

const DeleteAccountPage = () => {
    const [deleteAccountPopup, setDeleteAccountPopup] = useState(false)

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Delete Account" link3="" link3Text="" link4="" link4Text="" />
            <DeleteAccountPopup deleteAccountPopup={deleteAccountPopup} setDeleteAccountPopup={setDeleteAccountPopup} />

            <ToastContainer />

            <div className="pages delete-account-page">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="wrapper">
                                <div className="mt-20">
                                    <h2><b>Delete Account Alert</b></h2>
                                    <p className="mt-1">
                                        Are you sure you want to delete your account?
                                        Please read how account deletion will affect your
                                        account information and subsciption.
                                    </p>
                                </div>

                                <div className="mt-20">
                                    <h4>Account Data</h4>
                                    <p className="mt-1">
                                        Deleting your account will remove personal
                                        information, posts, checklists, spaces, item and
                                        all other data which is related to this account will
                                        be deleted permanently from our database.
                                    </p>
                                </div>

                                <div className="mt-20">
                                    <h4>Account Subscription</h4>
                                    <p className="mt-1">
                                        Please note: Deleting account will delete your
                                        peronal information and data only. Amount for
                                        monhtly subscription purchase will be applied to
                                        your iTunes account Monthly subscription will
                                        automatically renew every month unless
                                        cancelled with in 24-hours before the end of the
                                        current period. You  can cancel subscription from
                                        your iTunes account settings.
                                    </p>
                                </div>

                                <div className="mt-20">
                                  <Button style={{background:"black", fontSize:"14px", padding:"10px 14px"}} onClick={()=>setDeleteAccountPopup(true)} clasName="solid-button">Delete Account</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DeleteAccountPage