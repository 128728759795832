import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import PostCommentCard from '../components/dashboard/PostCommentCard'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const MyFavouritesPostPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-favourites" link2Text="My Favourites" link3="#" link3Text="Post Details" link4="" link4Text="" />
            <div className="pages post-detail-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostDetailCard isScheduled={false} />
                        </Col>

                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostCommentCard />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default MyFavouritesPostPage
