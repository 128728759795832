import React, { useState, useEffect, useContext } from 'react'
import { Modal, Form, Button, } from "react-bootstrap"
import { HiFolder } from 'react-icons/hi'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Skeleton from 'react-loading-skeleton'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { UserData } from '../../App'
import { PRIVACY } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { error } from '../../constants/msg'
import { Times, LogoutFill, DeleteAccountFill, RecentActivityFill, StatsBarFill, BlockUserFill, SettingFill, BellFill, LockFill, TermsAndConditionFill, PrivacyFill, EyeFill, GlobeFill, WalletFill, SchedulePostFill } from '../../constants/svgs'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { UpdateUser, UpdateUserPrivacy } from '../../services/users/users'
import DeleteAccountPopup from "../popup/DeleteAccountPopup"
import LogoutPopup from './LogoutPopup'

const BrandProfilePopup = ({ profilePopup, setProfilePopup }) => {
    const navigate = useNavigate()
    const [deleteAccountPopup, setDeleteAccountPopup] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const [checked, setChecked] = useState(false)

    const handlePrivacyOnChange = async (e) => {
        // As state is not update suddenly therefore we revert the logic of checking the button      
        try {
            setChecked(!checked)
            const privacy = !checked ? PRIVACY.private : PRIVACY.public
            await UpdateUserPrivacy({ privacy: privacy, website_url: userData?.brand_owner?.website_url }) // if login user is brand_owner than only add this param
            const temp = userData
            temp.privacy = privacy
            SetAuthUserLocalStorage(temp)
            setUserData(temp)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        // setChecked to true when account is private else false when page load
        let status = userData?.privacy === PRIVACY.private ? true : false
        setChecked(status)
    }, [])

    useEffect(() => {
        setProfilePopup(false)
    }, [navigate])

    return (
        <>
            <DeleteAccountPopup deleteAccountPopup={deleteAccountPopup} setDeleteAccountPopup={setDeleteAccountPopup} />
            <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />

            <Modal
                className='theme-popup profile-popup'
                show={profilePopup}
                onHide={() => setProfilePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>MY PROFILE</h3>
                            <div className='times-icon text-end' onClick={() => setProfilePopup(false)}>
                                <Times />
                            </div>
                        </div>

                        <div className='content-container text-center'>
                            <div className="profile-wrapper mx-auto">
                                <img src={userData?.brand_owner?.image_url} alt="" onError={e => e.target.src = Assets.ProfilePlaceholder} />
                            </div>
                            <div className='mt-2'>
                                <h2 className='text-capitalize'>{userData?.brand_owner?.brand_name}</h2>
                                <span className="d-block">{userData?.brand_owner?.address}</span>
                                <span className="d-block">{userData?.brand_owner?.category?.name}</span>
                                <span className="d-block">{userData?.email}</span>
                                <span className="d-block">{userData?.brand_owner?.website_url}</span>
                            </div>
                            <div className="mt-20 mb-20">
                                <NavLink to="/my-profile">View Profile</NavLink>
                            </div>
                        </div>

                        <div className='link-container'>
                            <ul>
                                <li className='mt-2 mb-2 pb-2 pt-2 border-top border-bottom'>
                                    <NavLink to="/wallet">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <WalletFill />
                                            </div>

                                            <div>
                                                <span>Wallet</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 mb-2 pb-2 border-bottom'>
                                    <NavLink to="/change-domain">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <GlobeFill />
                                            </div>

                                            <div>
                                                <span>Domain Verification</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 mb-2 pb-2 border-bottom'>
                                    <NavLink to="/statistics-and-analytics">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <StatsBarFill />
                                            </div>

                                            <div>
                                                <span>Statistics & Analytics</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 mb-2 pb-2 border-bottom'>
                                    <NavLink to="/recent-activity">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <RecentActivityFill />
                                            </div>

                                            <div>
                                                <span>Recent Activity</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 mb-2 pb-2 border-bottom'>
                                    <NavLink to="/schedule-post">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <SchedulePostFill />
                                            </div>

                                            <div>
                                                <span>Schedule Posts</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 mb-2 pb-2 border-bottom'>
                                    <NavLink to="/blocked-users">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <BlockUserFill />
                                            </div>

                                            <div>
                                                <span>Blocked Users</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 mb-2 pb-2 border-bottom d-none d-md-block'>
                                    <NavLink to="/settings/change-password">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <SettingFill />
                                            </div>

                                            <div>
                                                <span>Settings</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <div className="d-block d-md-none">
                                    <li className='mt-2 mb-2 pb-2 border-bottom'>
                                        <NavLink to="/settings/change-password">
                                            <div className="d-flex align-items-center">
                                                <div className="icon-wrapper me-3">
                                                    <LockFill />
                                                </div>

                                                <div>
                                                    <span>Change Password</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className='mt-2 mb-2 pb-2 border-bottom'>
                                        <NavLink to="/settings/terms-and-conditions">
                                            <div className="d-flex align-items-center">
                                                <div className="icon-wrapper me-3">
                                                    <TermsAndConditionFill />
                                                </div>

                                                <div>
                                                    <span>Terms And Conditions</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className='mt-2 mb-2 pb-2 border-bottom'>
                                        <NavLink to="/settings/privacy-policy">
                                            <div className="d-flex align-items-center">
                                                <div className="icon-wrapper me-3">
                                                    <PrivacyFill />
                                                </div>

                                                <div>
                                                    <span>Privacy Policy</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className='mt-2 mb-2 pb-2 border-bottom'>
                                        <NavLink to="/settings/collection-privacy">
                                            <div className="d-flex align-items-center">
                                                <div className="icon-wrapper me-3">
                                                    <HiFolder className='folder-icon' />
                                                </div>

                                                <div>
                                                    <span>Collection Privacy</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className='mt-2 mb-2 pb-2 border-bottom'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="icon-wrapper me-3">
                                                    <EyeFill />
                                                </div>
                                                <div className=''>
                                                    <span className='d-inline-block'>Privacy <span className='w-300'>(Public/Private)</span></span>
                                                </div>
                                            </div>

                                            <div className='text-end'>
                                                <Form.Check
                                                    checked={checked}
                                                    onChange={(e) => handlePrivacyOnChange(e)}
                                                    className="p-0"
                                                    type="switch"
                                                    id="custom-switch"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </div>

                                <li className='mt-2 mb-2 pb-2 border-bottom'>
                                    <NavLink to="/delete-account">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-wrapper me-3">
                                                <DeleteAccountFill />
                                            </div>

                                            <div>
                                                <span>Delete Account</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className='mt-2 cursor pb-2 border-bottom'>
                                    <div className="d-flex align-items-center"
                                        onClick={() => {
                                            setProfilePopup(false)
                                            setLogoutPopup(true)
                                        }}
                                    >
                                        <div className="icon-wrapper me-3">
                                            <LogoutFill />
                                        </div>

                                        <div>
                                            <span>Logout</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BrandProfilePopup