import React, { useContext, useState, useEffect, useReducer, useRef } from 'react'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import SearchPopup from '../components/popup/SearchPopup'
import { Alert, Button, Col, Container, Dropdown, Form, Row, ToastContainer } from 'react-bootstrap'
import MessageThreadCard from '../components/dashboard/MessageThreadCard'
import { db } from '../firebase/index';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, queryEqual, updateDoc, where } from 'firebase/firestore'
import { UserData } from '../App';
import Assets from '../constants/images'
import { NavLink, useParams } from 'react-router-dom'
import ReportPopup from '../components/popup/ReportPopup'
import { REPORT_INSTANCE_TYPE, VALIDATIONS_TEXT } from '../constants/app-constants'
import { DotsHorizontal, MessageCameraFill, SendFill } from '../constants/svgs'
import { useForm } from 'react-hook-form'
import { FaTimes } from 'react-icons/fa'
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../firebase/index"
import { GetFirebaseToken } from '../services/auth/auth'
import MessageBox from '../components/dashboard/MessageBox'
import { MdMessage } from 'react-icons/md'
import { UploadFileToS3, generateUUID } from '../utils/helper'
import { constant, s3Credential } from '../utils/constants'
import { error } from '../constants/msg'
import DeleteChatPopup from '../components/popup/DeleteChatPopup'
import BlockUserChatPopup from '../components/popup/BlockUserChatPopup'
import UnblockUserChatPopup from '../components/popup/UnblockUserChatPopup'
import ImageViewPopup from '../components/popup/ImageViewPopup'

const ViewAllMessagePage = () => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const { slug } = useParams()
    const messagesWrapperRef = useRef(null)
    const [searchPopup, setSearchPopup] = useState(false)
    const [userThreads, setUserThreads] = useState([])
    const [channelMessages, setChannelMessages] = useState([])
    const [userData, setUserData] = useContext(UserData)
    const [searchText, setSearchText] = useState("")
    const [blockUserChatPopup, setBlockUserChatPopup] = useState(false)
    const [unblockUserChatPopup, setUnblockUserChatPopup] = useState(false)
    const [reportPopup, setReportPopup] = useState(false)
    const [file, setFile] = useState(null)
    const [selectedChatUser, setSelectedChatUser] = useState(null)
    const [deleteChatPopup, setDeleteChatPopup] = useState(false)
    const [lastMSgId, setLastMsgId] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [imageViewPopup, setImageViewPopup] = useState(false)
    const [value, setValue] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }

    const uploadImageToS3 = async (imgFile) => {
        if (imgFile) {
            try {
                const { url } = await UploadFileToS3(imgFile, constant.IMAGE_DIR_NAME)
                return s3Credential.s3EndPoint + url
            }
            catch (e) {
                error(e.message)
            }
        }
        else {
            return ""
        }
    }

    const validateInput = (value) => {
        if (file) {
            return true
        }
        else {
            if (value.trim().length === 0) {
                return "Please enter a value.";
            }
            if (/\s{2,}(?!.)/.test(value)) {
                return "Consecutive spaces or tabs followed by an empty string are not allowed.";
            }
            return true;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (!event.shiftKey) {
                event.preventDefault();
                handleSubmit(onSubmit)();
            } else {
                setValue(value + '\n');
            }
        }
    }

    const onSubmit = async (data) => {
        const uuid = generateUUID();
        const channel_id = selectedChatUser?.channel_id
        const chatRef = doc(db, 'chats', channel_id)
        const messagesRef = collection(doc(db, 'chats', channel_id), 'thread');

        const image = await uploadImageToS3(file)
        let message_count = selectedChatUser?.message_count
        const newMessage = {
            id: uuid,
            created_at: new Date(),
            image_url: image,
            sender_id: userData?.id,
            text: data?.message,
            type: image ? 20 : 10,
            video_url: ""
        }

        setFile(null)
        setValue("")
        reset({ message: "", file: null })
        await addDoc(messagesRef, newMessage)
        await updateDoc(chatRef, {
            last_message: image ? "Image" : data?.message,
            updated_at: new Date(),
            message_count: ++message_count
        });

    }

    const userThreadSearchFilter = userThreads?.filter((value) => {
        return value?.channel_user_name?.toLowerCase()?.includes(searchText?.toLowerCase());
    })

    useEffect(() => {
        const fetchUsersThread = async () => {
            const chatsQuery = query(
                collection(db, "chats"),
                where('users', 'array-contains-any', [userData?.id]),
            );

            await onSnapshot(chatsQuery, async (querySnapshot) => {
                let array = []
                const data = querySnapshot.docs.map((e) => {
                    let chatData = {
                        ...e.data(),
                        id: e?.id
                    }
                    return chatData
                })

                for (let i = 0; i < data.length; i++) {
                    const [userId] = data[i]?.users?.filter((item) => item != userData?.id)
                    const docRef = doc(db, "users", userId?.toString())
                    const userDoc = await getDoc(docRef);
                    const user = userDoc.data()

                    array.push({
                        id: user?.id,
                        deleted_messages_ids: data[i]?.deleted_messages_ids,
                        channel_user_name: user?.name,
                        channel_user_profile_image: user?.image,
                        message: data[i]?.last_message,
                        created_at: data[i]?.created_at,
                        updated_at: data[i]?.updated_at,
                        message_count: data[i]?.message_count,
                        channel_id: data[i]?.id,
                        channel_user_email: user?.email,
                        blocked_by: data[i]?.blocked_by
                    })
                }
                setUserThreads(array)
            });
        }

        fetchUsersThread()
    }, [])

    useEffect(() => {
        const channel_id = selectedChatUser?.channel_id
        const getChannelMessages = async () => {
            const messagesRef = collection(doc(db, 'chats', `${channel_id}`), 'thread');
            const is_msg_deleted = selectedChatUser?.deleted_messages_ids?.filter((item) => item?.deleted_messages_ids != "" && item?.id == userData?.id)

            if (is_msg_deleted?.length > 0) {
                const q = query(messagesRef, where("id", "==", is_msg_deleted[0]?.deleted_message_id))
                const querySnapshot = await getDocs(q)
                const [singleData] = querySnapshot.docs.map((e) => e.data())

                const sortedMessagesQuery = query(messagesRef, orderBy('created_at'), where('created_at', '>', singleData?.created_at));
                await onSnapshot(sortedMessagesQuery, async (querySnapshot) => {
                    const data = querySnapshot.docs.map((e) => {
                        return {
                            date: {
                                seconds: e?.data()?.created_at?.seconds,
                                nanoseconds: e?.data()?.created_at?.nanoseconds,
                            },
                            profile_image: selectedChatUser?.channel_user_profile_image,
                            message: e?.data()?.text,
                            sender_id: e?.data()?.sender_id,
                            image_url: e?.data()?.image_url,
                            message_id: e?.data()?.id,
                        }
                    })
                    setChannelMessages(data)
                    setLastMsgId(data[data.length - 1])
                })
            }
            else {
                const sortedMessagesQuery = query(messagesRef, orderBy('created_at'));
                await onSnapshot(sortedMessagesQuery, async (querySnapshot) => {
                    const data = querySnapshot.docs.map((e) => {
                        return {
                            date: {
                                seconds: e?.data()?.created_at?.seconds,
                                nanoseconds: e?.data()?.created_at?.nanoseconds,
                            },
                            profile_image: selectedChatUser?.channel_user_profile_image,
                            message: e?.data()?.text,
                            sender_id: e?.data()?.sender_id,
                            image_url: e?.data()?.image_url,
                            message_id: e?.data()?.id,
                        }
                    })
                    setChannelMessages(data)
                    setLastMsgId(data[data.length - 1])
                })
            }
        }

        if (selectedChatUser) {
            getChannelMessages()
        }
    }, [selectedChatUser])

    useEffect(() => {
        // move to bottom
        if (selectedChatUser) {
            messagesWrapperRef.current.scrollTop = messagesWrapperRef.current.scrollHeight;
        }
    }, [selectedChatUser, channelMessages])

    useEffect(() => {
        const [id1, id2] = slug?.split("-")
        const ids = id2 + "-" + id1

        if (userThreads && slug) {
            for (let i = 0; i < userThreads?.length; i++) {
                if (userThreads[i]?.channel_id == slug) {
                    setSelectedChatUser(userThreads[i])
                    break;
                }
                else if (userThreads[i]?.channel_id == ids) {
                    setSelectedChatUser(userThreads[i])
                }
            }
        }
    }, [userThreads, slug])


    console.log(selectedChatUser)

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Messages" link3="" link3Text="" link4="" link4Text="" />
            <SearchPopup searchPopup={searchPopup} setSearchPopup={setSearchPopup} />
            <BlockUserChatPopup blockUserChatPopup={blockUserChatPopup} setBlockUserChatPopup={setBlockUserChatPopup} selectedChatUser={selectedChatUser} />
            <UnblockUserChatPopup unblockUserChatPopup={unblockUserChatPopup} setUnblockUserChatPopup={setUnblockUserChatPopup} selectedChatUser={selectedChatUser} />
            <ReportPopup data={selectedChatUser} reportTo={REPORT_INSTANCE_TYPE.user} reportPopup={reportPopup} setReportPopup={setReportPopup} />
            <DeleteChatPopup deleteChatPopup={deleteChatPopup} setDeleteChatPopup={setDeleteChatPopup} selectedChatUser={selectedChatUser} setSelectedChatUser={setSelectedChatUser} lastMSgId={lastMSgId} />
            <ImageViewPopup imageViewPopup={imageViewPopup} setImageViewPopup={setImageViewPopup} data={selectedImage} />
            <ToastContainer />


            <div className={`pages view-all-message-page`}>
                <Container fluid>
                    <Row className={selectedChatUser?.blocked_by?.includes(userData?.id) ? "d-block" : "d-none"}>
                        <Col>
                            <Alert variant="secondary">You can't reply to this conversation anymore</Alert>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={selectedChatUser ? 5 : 5} lg={selectedChatUser ? 4 : 4} xl={selectedChatUser ? 3 : 4} className={`mb-4 mb-xl-0`}>
                            <div className="thread-container">
                                <h3 className='w-100 d-inline-block text-center mb-20 pb-30 border-bottom'>MESSAGES</h3>
                                {
                                    userThreads?.length <= 0 ?
                                        <div className='no-msg-box'>
                                            <MdMessage className='msg-icon' />
                                            <span className='no-msg-text'>No message here</span>
                                        </div>
                                        :
                                        <>
                                            <div className='search-wrapper'>
                                                <Form.Control
                                                    value={searchText}
                                                    onChange={(e) => handleSearch(e)}
                                                    type="text"
                                                    placeholder="Search"
                                                    className="search-input"
                                                    aria-label="Search"
                                                    onKeyDown={(e) => e.keyCode === 13 && setSearchText("")}
                                                />
                                            </div>

                                            <div className="thread-wrapper">
                                                {
                                                    userThreadSearchFilter?.map((item, index) => (
                                                        <MessageThreadCard key={index} data={item} setSelectedChatUser={setSelectedChatUser} />
                                                    ))
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                        </Col>

                        <Col md={selectedChatUser ? 7 : 7} lg={selectedChatUser ? 8 : 8} xl={selectedChatUser ? 6 : 8} className={`mb-4 mb-xl-0 ${userThreads?.length > 0 ? 'd-block' : 'd-none'}`}>
                            <div className="message-container">
                                {
                                    !selectedChatUser ?
                                        <div className='no-msg-box'>
                                            <MdMessage className='msg-icon' />
                                            <span className='no-msg-text'>No message here</span>
                                        </div>
                                        :
                                        <>
                                            <div className="d-flex align-items-center mb-20 pb-30 border-bottom">
                                                <h5 className='w-100 d-inline-block text-center'>{selectedChatUser?.channel_user_name}</h5>
                                                <Dropdown className='icon-wrapper bg-white'>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <DotsHorizontal />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="text-center">
                                                        <Dropdown.Item
                                                            className={`border-bottom`}
                                                            onClick={() => {
                                                                setDeleteChatPopup(true)
                                                            }}
                                                        >
                                                            Delete Chat
                                                        </Dropdown.Item>


                                                        <Dropdown.Item
                                                            className={`border-bottom`}
                                                            onClick={() => {
                                                                setReportPopup(true)
                                                            }}
                                                        >
                                                            Report User
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                            className={`border-bottom`}
                                                            onClick={() => {
                                                                if (selectedChatUser?.blocked_by?.includes(userData?.id)) {
                                                                    setUnblockUserChatPopup(true)
                                                                }
                                                                else {
                                                                    setBlockUserChatPopup(true)
                                                                }
                                                            }}
                                                        >
                                                            {selectedChatUser?.blocked_by?.includes(userData?.id) ? " Unblock User" : "Block User"}
                                                        </Dropdown.Item>

                                                        <Dropdown.Item className="">Cancel</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                            <div ref={messagesWrapperRef} className={`message-wrapper ${file ? "with-file" : ''}`}>
                                                {
                                                    channelMessages?.map((item, index) => (
                                                        <MessageBox key={index} data={item} userData={userData} setSelectedImage={setSelectedImage} setImageViewPopup={setImageViewPopup} />
                                                    ))
                                                }
                                            </div>

                                            <div className={file ? "file-box d-block" : "d-none"}>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className="file-name">{file?.name}</span>
                                                    <span
                                                        onClick={() => {
                                                            setFile(null)
                                                            reset({ file: null })
                                                        }}
                                                    >
                                                        <FaTimes />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="input-wrapper">
                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="box d-flex align-items-center">
                                                        <label className="icon-wrapper" htmlFor="file">
                                                            <input
                                                                disabled={selectedChatUser?.blocked_by?.includes(userData?.id)}
                                                                type="file"
                                                                id="file"
                                                                className='d-none msg-input'
                                                                accept='.jpg, .jpeg, .png'
                                                                {...register('file', {
                                                                    required: {
                                                                        value: false,
                                                                        message: VALIDATIONS_TEXT.MEDIA_REQUIRED
                                                                    },
                                                                    onChange: (e) => setFile(e.target.files[0])
                                                                })}
                                                            />
                                                            <div className="camera-icon-box">
                                                                <MessageCameraFill />
                                                            </div>
                                                        </label>

                                                        <Form.Control
                                                            value={value}
                                                            onKeyDown={handleKeyDown}
                                                            disabled={selectedChatUser?.blocked_by?.includes(userData?.id)}
                                                            className='bg-grey'
                                                            as="textarea"
                                                            rows={0}
                                                            placeholder="Write your message here..."
                                                            {...register('message', {
                                                                required: {
                                                                    value: file ? false : true
                                                                },
                                                                onChange: (e) => setValue(e.target.value),
                                                                validate: validateInput
                                                            })}
                                                        />

                                                        <Button disabled={selectedChatUser?.blocked_by?.includes(userData?.id)} type="submit" className="btn-solid">
                                                            <SendFill className='icon' />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </>
                                }
                            </div>
                        </Col>

                        <Col lg={selectedChatUser ? 12 : 12} xl={3} className={`mb-4 mb-xl-0 ${selectedChatUser ? 'd-block' : 'd-none'}`}>
                            <div className="profile-container">
                                <h3 className='w-100 d-inline-block text-center mb-20 pb-30 border-bottom'>PROFILE</h3>
                                <div>
                                    <div className="img-wrapper">
                                        <img src={selectedChatUser?.channel_user_profile_image ? selectedChatUser?.channel_user_profile_image : Assets.ProfilePlaceholder} onError={(e) => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                    </div>
                                    <h2 className='text-capitalize'>{selectedChatUser?.channel_user_name}</h2>
                                    <small className='mb-2'>{selectedChatUser?.channel_user_email}</small>
                                    <NavLink className="mt-20 d-block" to={`/user/${selectedChatUser?.id}`}>View Profile</NavLink>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ViewAllMessagePage