import React from 'react'
import EditPostForm from '../components/forms/EditPostForm'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const EditPostPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Edit Post" link3="" link3Text="" link4="" link4Text=""  />
            <div className='pages create-post-page'>
                <div className="d-flex align-items-center justify-content-center h-100 pb-3">
                    <EditPostForm/>
                </div>
            </div>
        </>
    )
}

export default EditPostPage