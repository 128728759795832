import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TabsSkeleton = () => {
    return (
        <>
            {
                Array(4).fill(null)?.map((data, index) => (
                    <ul key={index} className="nav nav-pills" id="pills-tab" role="tablist">
                        <li key={index} className="nav-item" role="presentation">
                            <button className='nav-link'>
                                <Skeleton count={1} width={80} />
                            </button>
                        </li>
                    </ul>
                ))
            }
        </>
    )
}

export default TabsSkeleton