import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FilterDateContext } from '../../App';
import moment from 'moment';
import { POSTS_STATUS } from '../../constants/app-constants';

const MonthFilterPopup = ({ activeTab, monthFilterPopup, setMonthFilterPopup, setSchedulePostPageData, setSchedulePostPageCurrentPage, filterNow, setFilterNow, setIsLoading }) => {
    const [value, onChange] = useState(new Date());
    const [filterDate, setFilterDate] = useContext(FilterDateContext)

    const filter = () => {
        if (activeTab && activeTab == POSTS_STATUS.published) {
            setFilterDate({
                displayDate: moment(Array.isArray(value) ? value[0] : value).format("MMM YYYY").toString(),
                displayDateWithDay: moment(Array.isArray(value) ? value[0] : value).format("DD MMM YYYY").toString(),
                actualDate: moment(Array.isArray(value) ? value[0] : value).format("YYYY-MM-DD").toString(),
                date_from: moment(Array.isArray(value) ? value[0] : value).format("YYYY-MM-DD").toString(),
                date_to: moment(Array.isArray(value) ? value[1] : value).format("YYYY-MM-DD").toString(),
                isSet: true
            })
        }
        else {
            setFilterDate({
                displayDate: moment(Array.isArray(value) ? value[0] : value).format("MMM YYYY").toString(),
                displayDateWithDay: moment(Array.isArray(value) ? value[0] : value).format("DD MMM YYYY").toString() + " to " + moment(Array.isArray(value) ? value[1] : value).format("DD MMM YYYY").toString(),
                actualDate: moment(Array.isArray(value) ? value[0] : value).format("YYYY-MM-DD").toString(),
                date_from: moment(Array.isArray(value) ? value[0] : value).format("YYYY-MM-DD").toString(),
                date_to: moment(Array.isArray(value) ? value[1] : value).format("YYYY-MM-DD").toString(),
                isSet: true
            })
        }
        setMonthFilterPopup(false)
        if (setSchedulePostPageData) {
            setIsLoading(true)
            setFilterNow(!filterNow)
            setSchedulePostPageData([])
            setSchedulePostPageCurrentPage(1)
        }
    }

    const clearFilter = () => {
        setFilterDate({
            displayDate: moment(new Date()).format("MMM YYYY").toString(),
            displayDateWithDay: moment(new Date()).format("DD MMM YYYY").toString(),
            actualDate: moment(new Date()).format("YYYY-MM-DD").toString(),
            isSet: false
        })
        onChange(new Date())
        if (setSchedulePostPageData) {
            setIsLoading(true)
            setFilterNow(!filterNow)
            setSchedulePostPageData([])
            setSchedulePostPageCurrentPage(1)
        }
    }

    useEffect(() => {
        clearFilter()
    }, [activeTab])

    return (
        <Modal
            className='theme-popup calendar-popup'
            show={monthFilterPopup}
            onHide={() => setMonthFilterPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>SELECT MONTH</h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setMonthFilterPopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div className="calendar-wrapper">
                        <Calendar returnValue={activeTab ? activeTab == POSTS_STATUS.published ? "start" : "range" : "start"} selectRange={activeTab ? activeTab == POSTS_STATUS.published ? false : true : false} onChange={onChange} value={value} />
                        <div className='border-top'>
                            <Button onClick={() => filter()} className="mt-40 mb-10 btn-solid w-100">FILTER</Button>
                            <Button className={`mb-10 btn-transparent w-100 ${filterDate?.isSet ? "d-block" : "d-none"}`}
                                onClick={() => {
                                    clearFilter()
                                    setMonthFilterPopup(false)
                                }}
                            >
                                CLEAR FILTER
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MonthFilterPopup
