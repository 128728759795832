import React, { useEffect } from 'react'
import { GetAuthUserLocalStorage, GetTokenLocalStorage } from "../services/localStorage/localStorage"
import { Route, Navigate, Outlet, useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';

const ProtectedRoute = () => {
    const token = GetTokenLocalStorage()
    const user = GetAuthUserLocalStorage()
    const navigate = useNavigate()
    const location = useLocation()

    return (
        token ? <Outlet /> : <Navigate to="/" />
    )
}

export default ProtectedRoute