import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { useForm } from 'react-hook-form'
import { ROLES, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { SetUserRoleLocalStorage } from '../../services/localStorage/localStorage'
import { UserRole } from '../../App'

const SelectRoleForm = () => {
    const navigate = useNavigate()
    const [userRole, setUserRole] = useContext(UserRole)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        const { role } = data
        SetUserRoleLocalStorage(role)
        setUserRole(role)
        navigate("/signup")
    }

    return (
        <div className='auth-card select-role-form'>
            <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='border-bottom pb-3 mb-4'>
                        <h4>Welcome to <b>The Link Front</b></h4>
                        <p>Transforming shopping into an experience.</p>
                    </div>

                    <div className="text-center logo-wrapper">
                        <img src={Assets.Logo} />
                    </div>

                    <div>
                        <h3 className='mt-3 mb-30'>SELECT YOUR ROLE</h3>
                        <div className="wrapper mb-20">
                            <Form.Check
                                value={ROLES.shopper}
                                name="role"
                                type="radio"
                                id="shopping"
                                {...register("role",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ROLE_REQUIRED
                                        },
                                    })
                                }
                            />
                            <label htmlFor="shopping">SHOPPING AND <br /> CREATING CONTENT</label>
                        </div>

                        <div className="wrapper">
                            <Form.Check
                                value={ROLES.brand}
                                name="role"
                                type="radio"
                                id="brand-owner"
                                {...register("role",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ROLE_REQUIRED
                                        },
                                    })
                                }
                            />
                            <label htmlFor="brand-owner">Brand Owner</label>
                        </div>
                        {errors.role && <small className='text-start d-block text-danger mt-1'>{errors.role.message}</small>}


                        <Button type="submit" className='mb-30 mt-40 btn-solid'>GET STARTED</Button>
                        <span className="d-block">Already have an account? <NavLink className="link" to="/signin">SIGN IN</NavLink></span>
                    </div>
                </Form>
            </div>
        </div >
    )
}

export default SelectRoleForm