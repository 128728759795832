import React from 'react'
import { Modal } from 'react-bootstrap'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'

const ImageViewPopup = ({ imageViewPopup, setImageViewPopup, data }) => {
    return (
        <Modal
            className='theme-popup add-folder-popup'
            show={imageViewPopup}
            onHide={() => setImageViewPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex align-items-center text-center mb-5 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>View</h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setImageViewPopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div>
                        <img className='w-100' src={data} onError={(e) => e.target.src = Assets.GeneralPlaceholder} alt="" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImageViewPopup