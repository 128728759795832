import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import StatsCard from "../components/dashboard/StatsCard"
import { POPUP_TYPE, POSTS_STATUS } from '../constants/app-constants'
import Assets from '../constants/images'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import PostDetailCardAsList from '../components/dashboard/PostDetailCardAsList'
import DashboardBreadCrumbSkeleton from '../components/skeleton/DashboardBreadCrumbSkeleton'

const BrandPostListPage = () => {
    const [userProfile, setUserProfile] = useState(null)

    return (
        <>
            {
                userProfile ?
                    <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/brands" link2Text="Brands" link3={`/brand/${userProfile?.id}`} link3Text={userProfile?.shopper ? userProfile?.shopper?.full_name : userProfile?.brand_owner?.brand_name} link4="#" link4Text="Posts" />
                    :
                    <DashboardBreadCrumbSkeleton />
            }
            <div className='pages post-list-page'>
                <div className='pb-4'>
                    <Container fluid>
                        <PostDetailCardAsList setUserProfile={setUserProfile} isFromStats={false} isScheduled={false} activeTab={POSTS_STATUS.published} link="/brand/post" />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default BrandPostListPage