import React, { useContext, useState } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { UserData, UserRole } from '../../App'
import Assets from '../../constants/images'
import { success, error } from '../../constants/msg'
import { DeleteComment } from '../../services/comments/comments'
import { EmptyLocalStorage } from '../../services/localStorage/localStorage'
import { DeletePost } from '../../services/posts/posts'
import { DeleteAccount } from '../../services/users/users'
import Loader from '../Loader/Loader'
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, writeBatch } from 'firebase/firestore'
import { db } from '../../firebase'

const DeleteChatPopup = ({ deleteChatPopup, setDeleteChatPopup, selectedChatUser, setSelectedChatUser, lastMSgId }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const navigate = useNavigate()

    const deleteChat = async () => {
        //Delete chat and redirect user to home page 
        setIsDisabled(true)
        const channel_id = selectedChatUser?.channel_id
        const chatDocRef = doc(db, 'chats', channel_id)
        const threadCollectionRef = collection(doc(db, 'chats', channel_id), 'thread');

        try {
            const chatDoc = await getDoc(chatDocRef)
            if (chatDoc.exists()) {
                const data = chatDoc.data()
                const deleted_statuses = data?.deleted_statuses?.map((status) => {
                    if (status?.id == userData?.id) {
                        return {
                            id: userData?.id,
                            is_deleted: true
                        }
                    }
                    else {
                        return status
                    }
                })

                const deleted_messages_ids = data?.deleted_messages_ids?.map((msg) => {
                    if (msg?.id == userData?.id) {
                        return {
                            id: userData?.id,
                            deleted_message_id: lastMSgId?.message_id
                        }
                    }
                    else {
                        return msg
                    }
                })

                // await updateDoc(chatDocRef, { deleted_statuses, deleted_messages_ids, last_message: "No message yet", message_count: 0, created_at: new Date() })
                const threadQuery = query(threadCollectionRef);
                const threadDocs = await getDocs(threadQuery);
              
                const batch = writeBatch(db);
                threadDocs.forEach((doc) => {
                  batch.delete(doc.ref);
                });
              
                await batch.commit();
                await deleteDoc(chatDocRef)

                setSelectedChatUser(null)
                setIsDisabled(false)
                setDeleteChatPopup(false)
                success("Chat deleted successfully")
            }
        }
        catch (e) {
            setIsDisabled(false)
            error(e)
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup delete-comment-popup'
                show={deleteChatPopup}
                onHide={() => setDeleteChatPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>DELETE ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, that you want to delete <br />
                                    this chat?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={() => deleteChat()}>
                                    {isDisabled ? <Loader /> : "DELETE CHAT"}
                                </Button>
                                <Button className="w-100 btn-transparent" onClick={() => setDeleteChatPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteChatPopup