import React, { useState, useContext, useEffect } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { AddFolderPopupContext } from '../../App'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import { ToastContainer } from 'react-toastify';
import { success, error } from '../../constants/msg'
import Loader from '../Loader/Loader'
import { AddFolder } from '../../services/folders/folder'
import { constant, transactions } from '../../utils/constants'
import { UserData } from '../../App'
import { Payouts } from "../../services/transaction/transaction";
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'

const WithdrawlPopup = ({ withdrawlPopup, setWithdrawlPopup, fetchData }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserData)

    const { register, handleSubmit, reset, atch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        // Reset input when popup is open or close
        reset({
            amount: ""
        })
    }, [withdrawlPopup])

    const onSubmit = async (data) => {
        setIsDisabled(true);
        try {
            const datanew = {
                type: transactions.PAY_OUTS,
                amount: data.amount
            }
            const res = await Payouts(datanew);
            success(res?.data?.message);

            const temp = userData;
            temp.wallet = parseInt(userData.wallet) - parseInt(data.amount);
            SetAuthUserLocalStorage(temp)
            setUserData(temp)
            fetchData()
            setIsDisabled(false);
            setWithdrawlPopup(false);
        } catch (e) {
            error(e.response.data.message);
            setIsDisabled(false);
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup withdrawl-popup'
                show={withdrawlPopup}
                onHide={() => setWithdrawlPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='d-flex align-items-center text-center mb-5 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>Add bank account</h3>
                            <div className="text-end times-icon-wrapper" onClick={() => setWithdrawlPopup(false)}>
                                <Times />
                            </div>
                        </div>

                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='content-container text-center'>
                                    <div className="text-start">
                                        <label className="mb-1">Enter Withdraw Amount *</label>
                                        <Form.Control
                                            className="py-3"
                                            type="number"
                                            inputMode='number'
                                            placeholder="Enter Withdraw Amount *"
                                            {...register("amount",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.AMOUNT_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                        {errors.amount && <small className='text-start d-block text-danger mt-1'>{errors.amount.message}</small>}
                                    </div>

                                    <div className="mt-40">
                                        <Button type="submit" className="w-100 btn-solid mb-2">
                                            {isDisabled ? <Loader /> : "withdraw amount"}
                                        </Button>
                                        <Button className="w-100 btn-transparent" onClick={() => setWithdrawlPopup(false)}>CANCEL</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WithdrawlPopup