import React, { useEffect, useState, useRef, useContext } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { CHARACTER_ARRAY_FOR_NUMBER_INPUT, SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success, error } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { BrandFill, ClockFill, DollarFill, GlobeFill, LocationFill, LockFill, MessageIconFill, PaypalFill, UserFill } from '../../constants/svgs'
import BusinessHourPopup from '../popup/BusinessHourPopup'
import moment from 'moment'
import Select from 'react-select';
import { constant } from '../../utils/constants'
import { UploadFileToS3 } from '../../utils/helper'
import Loader from '../Loader/Loader'
import { Register } from "../../services/auth/auth"
import { GetCategories } from '../../services/brands/brand'
import { UserData } from '../../App'
import { GetUser, UpdateUser } from '../../services/users/users'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'

const SignupBrandForm = ({ isSetupProfile }) => {
    const { register, handleSubmit, watch, setValue, reset, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const imageRef = useRef()
    const navigate = useNavigate()
    const location = useLocation()
    const [isDisabled, setIsDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [businessHoursPopup, setBusinessHoursPopup] = useState(false);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [timeError, setTimeError] = useState(false)
    const [options, setOptions] = useState([])
    const [file, setFile] = useState({
        file: null,
        file_url: null
    })
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const password = watch("password")
    const [userData, setUserData] = useContext(UserData)
    const [locationState, setLocationState] = useState(location?.state || null)

    const uploadImageToS3 = async (imgFile) => {
        try {
            const { url } = await UploadFileToS3(imgFile, constant.IMAGE_DIR_NAME)
            return url
        }
        catch (e) {
            error(e.message)
        }
    }

    const getFormattedTime = (startTime, endTime) => {
        //  convert and return date object like 12:00pm to 10:00pm
        const start_time = moment(startTime).format("hh:mm a")
        const end_time = moment(endTime).format("hh:mm a")
        return start_time + " to " + end_time
    }

    const onSubmit = async (data) => {
        // convert start and end time into timestamp than compare
        // also compare that the start and end time should not be empty

        if (((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime))) {
            setTimeError(true)  // if error goes here
        }
        else {
            /*
                Business logic for Signup 
                First upload image to aws then append url in data and send it to api for signup 
                After successfull signup send email with useNavigate to account verification page
            */
            setIsDisabled(true)
            try {
                data.role = "brand_owner"
                data.device_type = constant.DEVICE_TYPE
                data.category_id = data.brand_category.value
                data.business_hours = getFormattedTime(startTime, endTime)
                delete data.password_confirmation

                if (file.file) {
                    const url = await uploadImageToS3(file.file)
                    data.profile_picture = url
                }

                if (isSetupProfile) {
                    const res = await UpdateUser(userData?.id, data)
                    const response = await GetUser(userData?.id)
                    setUserData(response?.data?.data?.user)
                    SetAuthUserLocalStorage(response?.data?.data?.user)
                    imageRef.current.value = null
                    success(res.data.message)
                    setIsDisabled(false)
                    setTimeout(() => {
                        navigate("/generate-token")
                    }, 1500)
                }
                else {
                    const res = await Register(data);
                    imageRef.current.value = null
                    success(res.data.message)
                    setIsDisabled(false)
                    setTimeout(() => {
                        navigate("/email-verification", { state: { email: data.email, type: "register", user: null } })
                    }, 1500)
                }
            }
            catch (e) {
                error(e.response.data.message)
                setIsDisabled(false)
            }
        }
    }

    useEffect(() => {
        const fetchCategories = async () => {
            /*
                Fetch categories append in array and set their state
            */
            try {
                const categories = []
                const { data } = await GetCategories()
                data.data.forEach(category => categories.push({
                    label: category.name,
                    value: category.id
                }))
                setOptions(categories)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }
        fetchCategories()
    }, [])

    useEffect(() => {
        if (locationState) {
            setFile({ ...file, file_url: locationState?.user?.brand_owner?.profile_picture })
            reset({
                email: locationState?.user?.email
            })
        }
    }, [locationState])

    console.log(locationState,"locationState")

    return (
        <div className='auth-card signup-form'>
            <ToastContainer />
            <BusinessHourPopup setTimeError={setTimeError} businessHoursPopup={businessHoursPopup} setBusinessHoursPopup={setBusinessHoursPopup} startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} />

            <div>
                <h3 className='pb-30 border-bottom mb-30'>{isSetupProfile ? "SETUP PROFILE" : "SIGN UP"}</h3>
                <p className='text-black'>
                    {
                        isSetupProfile ?
                            "Setup your profile to start transforming shopping into an experience." :
                            "Transforming shopping into an experience."
                    }
                </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    <label className='profile-img-wrapper' htmlFor="profile">
                        <input
                            className="d-none"
                            type="file" id="profile"
                            name="profile"
                            accept="image/*"
                            ref={imageRef}
                            onChange={(e) => (
                                setFile({
                                    file: e.target.files[0],
                                    file_url: URL.createObjectURL(e.target.files[0])
                                })
                            )}
                        />
                        <img crossorigin="anonymous" src={file.file_url ? file.file_url : Assets.ProfileUploadPlaceholder} alt="" />
                    </label>
                    <p className='mt-2'>Upload profile picture</p>
                </div>

                <div className='mt-30'>
                    <FloatingLabel controlId="username" label="User Name *">
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="User Name *"
                            {...register("username",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.USER_NAME_REQUIRED
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9]*$/,
                                        message: VALIDATIONS_TEXT.USER_NAME_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <UserFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.username && <small className='text-start d-block text-danger mt-1'>{errors.username.message}</small>}

                    <FloatingLabel className="mt-20" controlId="name" label="Brand Name *">
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="Brand name *"
                            {...register("brand_name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.BRAND_NAME_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <UserFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.brand_name && <small className='text-start d-block text-danger mt-1'>{errors.brand_name.message}</small>}

                    <div className="react-select">
                        <div className="icon-wrapper">
                            <BrandFill className='icon' />
                        </div>

                        <Controller
                            control={control}
                            name="brand_category"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                },
                            }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder="Brand Category *"
                                    className="mt-20 form-control"
                                    classNamePrefix="react-select-container"
                                    isSearchable={false}
                                    options={options}
                                />
                            )}
                        />
                    </div>
                    {errors.brand_category && <small className='text-start d-block text-danger mt-1'>{errors.brand_category.message}</small>}

                    <FloatingLabel className="mt-20" controlId="businessHours" label="Business Hours *" onClick={() => setBusinessHoursPopup(true)}>
                        <Form.Control
                            value={(startTime && endTime) && `${moment(startTime).format("hh:mm a")} - ${moment(endTime).format("hh:mm a")}`}
                            type="text"
                            placeholder="Business Hours *"
                            readOnly
                        />
                        <div className="icon-wrapper">
                            <ClockFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {timeError && <small className='text-start d-block text-danger mt-1'>{(!startTime || !endTime) ? "Business hours is required" : "Opening time should be less than closing time"}</small>}

                    <FloatingLabel className="mt-20" controlId="websiteUrl" label="Website URL *">
                        <Form.Control
                            type="text"
                            placeholder="Website URL *"
                            {...register("website_url",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.WEBSITE_URL_REQUIRED
                                    },
                                    pattern: {
                                        value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                        message: VALIDATIONS_TEXT.INVALID_WEBSITE_URL
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <GlobeFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.website_url && <small className='text-start d-block text-danger mt-1'>{errors.website_url.message}</small>}

                    {/* <FloatingLabel className="mt-20" controlId="address" label="Address *">
                        <Form.Control
                            maxLength={VALIDATIONS.ADDRESS_MAX}
                            type="text"
                            placeholder="Address *"
                            {...register("address",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.ADDRESS_MAX,
                                        message: VALIDATIONS_TEXT.ADDRESS_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LocationFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.address && <small className='text-start d-block text-danger mt-1'>{errors.address.message}</small>} */}

                    <FloatingLabel className={`mt-20`} controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: isSetupProfile ? false : true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                    {/* <FloatingLabel className="mt-20" controlId="paypal_email" label="PayPal Email Address">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="PayPal Email Address"
                            {...register("paypal_email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: false,
                                        message: VALIDATIONS_TEXT.PAYPAL_EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <PaypalFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.paypal_email && <small className='text-start d-block text-danger mt-1'>{errors.paypal_email.message}</small>} */}

                    <FloatingLabel className={`mt-20 ${isSetupProfile ? 'd-none' : 'd-block'}`} controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: isSetupProfile ? false : true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}

                    <FloatingLabel className={`mt-20 ${isSetupProfile ? 'd-none' : 'd-block'} `} controlId="confirm-password" label="Confirm Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showConfirmPassword ? "text" : "password"}`}
                            placeholder="Confirm Password *"
                            {...register("password_confirmation",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: isSetupProfile ? false : true,
                                        message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                    },
                                    validate: (value) =>
                                        value === password || "The Passwords do not match"
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {
                                showConfirmPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password_confirmation && <small className='text-start d-block text-danger mt-1'>{errors.password_confirmation.message}</small>}

                    <FloatingLabel className="mt-20" controlId="commission_payout" label="Commission Payout % *">
                        <Form.Control
                            onKeyDown={(e) => (CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key)) && e.preventDefault()}
                            type="number"
                            placeholder="Commission Payout % *"
                            {...register("commission_payout",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.COMMISSION_PAYOUT,
                                        message: VALIDATIONS_TEXT.COMMISSION_PAYOUT_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.COMMISSION_PAYOUT_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <DollarFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.commission_payout && <small className='text-start d-block text-danger mt-1'>{errors.commission_payout.message}</small>}

                    <Form.Check className={`mt-20 ${isSetupProfile ? "d-none" : "d-flex align-items-center"}`} type="checkbox" label={
                        <div className='small-title'>
                            <span>I agree with
                                <NavLink to="/terms-and-conditions"> Terms and Conditions </NavLink>
                                and
                                <NavLink to="/privacy-policy"> Privacy Policy</NavLink>
                            </span>
                        </div>
                    } />

                    <Button disabled={isDisabled} type="submit" className='mt-40 mb-30 btn-solid'>
                        {isDisabled ? <Loader /> : isSetupProfile ? "CONTINUE" : "SIGN UP"}
                    </Button>
                    <span className={`mb-20 ${isSetupProfile ? "d-none" : "d-block"}`}>Already have an account? <NavLink className="link" to="/signin">SIGN IN</NavLink></span>
                </div>
            </Form >
        </div >
    )
}

export default SignupBrandForm