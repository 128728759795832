import React, { useState } from 'react'
import BlockedUserCard from '../components/dashboard/BlockedUserCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import Skeleton from 'react-loading-skeleton'

const BlockedUserPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Blocked Users" link4="" link4Text="" />
            
            <div className="pages block-user-page">
                <div className="d-flex align-items-center justify-content-center h-100 pb-4">
                    <BlockedUserCard />
                </div>
            </div>
        </>
    )
}

export default BlockedUserPage