import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Row, Col, Button } from 'react-bootstrap'

const SelectedImageCardSkeleton = () => {
    return (
        <>
            {
                Array(6).fill(null)?.map((data, index) => (
                    <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                        <div className='select-img-card mt-3'>
                            <Skeleton style={{ borderRadius: "10px", width:"100%" }} height={260} />
                        </div>
                    </Col>
                ))
            }
        </>
    )
}

export default SelectedImageCardSkeleton