import React from 'react'
import DashboardNavbar from './DashboardNavbar'

const DashboardLayout = ({children}) => {
    return (
        <section className='dashboard-layout'>
            <DashboardNavbar />
            {children}
        </section>
    )
}

export default DashboardLayout