import React from 'react'
import moment from 'moment'
import Assets from '../../constants/images'
import { MdDownloadForOffline } from "react-icons/md"
import { saveAs } from "file-saver"
import { error } from '../../constants/msg'

const MessageBox = ({ data, userData, setSelectedImage, setImageViewPopup }) => {
    const downloadFile = async (url) => {
        try {
            saveAs(url)
        }
        catch (e) {
            error(e)
        }
    }

    const returnProfileImage = (data) => {
        if (data?.sender_id == userData?.id) {
            if (userData?.shopper) {
                return userData?.shopper?.image_url
            }
            else {
                return userData?.brand_owner?.image_url
            }
        }
        else {
            return data?.profile_image
        }
    }

    return (
        <div className={`message-box mb-20 d-block w-100 ${data?.sender_id == userData?.id ? "my-message" : "others-message"}`}>
            <div className='wrapper d-flex align-items-center'>
                <div className="profile-img-wrapper">
                    <img src={returnProfileImage(data) || Assets.ProfilePlaceholder} alt="" onError={(e) => e.target.src = Assets.ProfilePlaceholder} />
                </div>

                <div className={`message-chat-box ${data?.sender_id == userData?.id ? "me" : "other"} `}>
                    <div className={`mb-2 ${data?.image_url ? "d-block" : "d-none"}`}>
                        <div className="img-wrapper" onClick={() => {
                            setSelectedImage(data?.image_url)
                            setImageViewPopup(true)
                        }}>
                            <img src={data?.image_url} alt="" onError={(e) => e.target.src = Assets.GeneralPlaceholder} />
                        </div>
                    </div>
                    <small style={{ wordBreak: "break-all" }}>{data?.message}</small>
                </div>
            </div>
        </div>
    )
}

export default MessageBox