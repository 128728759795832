import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { TiTimes } from 'react-icons/ti'
import Assets from "../../constants/images"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import SelectImageCard from "../../components/dashboard/SelectImageCard"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { AddProductContext } from '../../App'
import { GetProductsByFolder } from '../../services/products/products'
import { error } from '../../constants/msg'
import { GetFolders } from '../../services/folders/folder'
import TabsSkeleton from '../skeleton/TabsSkeleton'
import SelectedImageCardSkeleton from '../skeleton/SelectedImageCardSkeleton'
import Loader from '../Loader/Loader'

const AddedPostCard = ({ prevData, folders, isFolderLoading, setIsFolderLoading, selectedFolder, setSelectedFolder }) => {
    const navigate = useNavigate();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [addProduct, setAddProduct] = useContext(AddProductContext)
    const [products, setProducts] = useState([])
    const removeSelectedPost = (index) => {
        addProduct.splice(index, 1)
        setAddProduct([...addProduct])
    }

    useEffect(() => {
        const getProductsByFolder = async () => {
            /*  Send folder id in api and get products by folder 
                Automatically call every every time when folder is switched 
            */
            setIsLoading(true)
            if (currentPage > 1) {
                setIsLoadMore(true)
            }
            try {
                const params = {
                    page: currentPage
                }
                const res = await GetProductsByFolder(selectedFolder.id, params)
                setProducts(res.data.data.data)
                setTotalPage(res.data.data.last_page)
                setIsLoading(false)
                setIsLoadMore(false)
                setIsFolderLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsLoadMore(false)
                setIsFolderLoading(false)
                error(e.response.data.message)
            }
        }

        if (selectedFolder.id) {
            getProductsByFolder()
        }
    }, [selectedFolder.id])

    return (
        <div className="added-post-card">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                {
                    isFolderLoading ?
                        <TabsSkeleton />
                        :
                        folders.length <= 0 ?
                            <div className='mt-4'><h6>No Folder Available</h6></div>
                            :
                            folders?.map((data, index) => (
                                <li key={index} className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${index == selectedFolder.index && "active"}`}
                                        id={`pills-${index}`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-${index}`}
                                        aria-selected="true"
                                        onClick={() => {
                                            setSelectedFolder({
                                                index: index,
                                                id: data?.id
                                            })
                                        }}
                                    >
                                        {data?.name}
                                    </button>
                                </li>
                            ))
                }
            </ul>

            <div className='center-box p-3 mt-3 d-flex flex-column flex-lg-row align-items-lg-center justify-content-between'>
                <div className='me-1'>
                    <span>SELECTED IMAGES</span>
                </div>

                <div className="swipper-wrapper ms-0 ms-lg-3">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        breakpoints={{
                            200: {
                                slidesPerView: 2,
                            },
                            300: {
                                slidesPerView: 3,
                            },
                            640: {
                                slidesPerView: 5,
                            },
                            768: {
                                slidesPerView: 6,
                            },
                            992: {
                                slidesPerView: 8,
                            },
                            1200: {
                                slidesPerView: 10,
                            },
                            1300: {
                                slidesPerView: 10,
                            },
                        }}
                    >
                        {
                            addProduct?.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <div className='img-wrapper me-2'>
                                        <div className='icon-times-wrapper d-flex align-items-center justify-content-center' onClick={() => removeSelectedPost(index)}>
                                            <TiTimes className="icon" />
                                        </div>
                                        <img src={data.image_url} onError={(e) => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>

                <div className={`${addProduct.length > 0 ? 'd-block' : 'd-none'}`}>
                    <Button onClick={() => navigate(prevData.route, { state: { data: prevData } })} className="text-center d-block ms-lg-auto mt-3 mt-lg-0 w-100 btn-solid done-btn">DONE</Button>
                </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
                {
                    folders.length > 0 &&
                    folders?.map((folder, index) => (
                        <div key={index} className={`tab-pane fade show ${index == selectedFolder.index ? 'active' : 'fade'}`} id={`pills-${index}`} role="tabpanel" aria-labelledby={`pills-${index}-tab`}>
                            <Row>
                                {
                                    isLoading ?
                                        <SelectedImageCardSkeleton />
                                        :
                                        products.length <= 0 ?
                                            <div className='mt-4 no-product-wrapper'><img src={Assets.NoProductImage} /></div>
                                            :
                                            products?.map((data, index) => (
                                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                                    <SelectImageCard data={data} />
                                                </Col>
                                            ))
                                }
                                <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
                                    {
                                        isLoadMore ?
                                            <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AddedPostCard