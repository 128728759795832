import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import StatsCard from "../components/dashboard/StatsCard"
import { CalendarIconFill } from '../constants/svgs'
import { FilterDateContext, UserData, UserRole } from '../App'
import MonthFilterPopup from '../components/popup/MonthFilterPopup'
import Assets from '../constants/images'
import { POSTS_STATUS, ROLES } from '../constants/app-constants'
import BlockedUserCard from '../components/dashboard/BlockedUserCard'
import DemographicsCard from '../components/dashboard/DemographicsCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import RollUpCard from '../components/dashboard/RollUpCard'
import PostDetailCardAsList from '../components/dashboard/PostDetailCardAsList'
import { error } from '../constants/msg'
import { SetAuthUserLocalStorage } from '../services/localStorage/localStorage'
import { GetUser } from '../services/users/users'

const StatisticsAndAnalyticsPage = () => {
    const [userRole, setUserRole] = useContext(UserRole)
    const [blockUserPopup, setBlockUserPopup] = useState(false)
    const [filterDate, setFilterDate] = useContext(FilterDateContext)
    const [monthFilterPopup, setMonthFilterPopup] = useState(false)
    const [activeTab, setActiveTab] = useState(POSTS_STATUS.published)
    const [userData, setUserData] = useContext(UserData)
    const [currUser, setCurrUser] = useState(null)

    const ShopperTabs = () => {
        return (
            <Tabs
                defaultActiveKey={POSTS_STATUS.published}
                className="theme-tabs"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
            >
                <Tab eventKey={POSTS_STATUS.published} title="PUBLISHED POSTS">
                    <div className='mt-4'>
                        <PostDetailCardAsList isFromStats={true} isScheduled={false} activeTab={activeTab} link="/statistics-and-analytics/post" />
                    </div>
                </Tab>

                <Tab eventKey={POSTS_STATUS.scheduled} title="SCHEDULED POSTS">
                    <div className='mt-4'>
                        <PostDetailCardAsList isFromStats={true} isScheduled={true} activeTab={activeTab} link="/statistics-and-analytics/post" />
                    </div>
                </Tab>

                <Tab eventKey={POSTS_STATUS.rollup} title="ROLLUP">
                    <div className='mt-4'>
                        <RollUpCard activeTab={activeTab} />
                    </div>
                </Tab>
            </Tabs>
        )
    }

    const BrandTabs = () => {
        return (
            <Tabs
                defaultActiveKey={POSTS_STATUS.published}
                className="theme-tabs"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
            >
                <Tab eventKey={POSTS_STATUS.published} title="PUBLISHED POSTS">
                    <div className='mt-4'>
                        <PostDetailCardAsList isFromStats={true} isScheduled={false} activeTab={activeTab} link="/statistics-and-analytics/post" />
                    </div>
                </Tab>

                <Tab eventKey={POSTS_STATUS.scheduled} title="SCHEDULED POSTS">
                    <div className='mt-4'>
                        <PostDetailCardAsList isFromStats={true} isScheduled={true} activeTab={activeTab} link="/statistics-and-analytics/post" />
                    </div>
                </Tab>

                <Tab eventKey={POSTS_STATUS.demographics} title="DEMOGRAPHICS">
                    <div className='mt-4'>
                        <DemographicsCard />
                    </div>
                </Tab>
            </Tabs>
        )
    }

    const customTab = {
        [ROLES.shopper]: <ShopperTabs />,
        [ROLES.brand]: <BrandTabs />,
    }

    useEffect(() => {
        const getUser = async () => {
            try {
                const res = await GetUser(userData?.id)
                setUserData(res.data.data.user)
                SetAuthUserLocalStorage(res.data.data.user)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getUser()
    }, [])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Statistics & Analytics" link4="" link4Text="" />
            <MonthFilterPopup activeTab={activeTab} monthFilterPopup={monthFilterPopup} setMonthFilterPopup={setMonthFilterPopup} />
            <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />

            <div className='pages statistics-and-analytics-page'>
                <div className='pb-4'>
                    <Container fluid>
                        <Row>
                            <Col xs={12} className="text-end mb-3">
                                <div className='folder-box' onClick={() => setMonthFilterPopup(true)}>
                                    <span className="me-3">{filterDate.displayDate}</span>
                                    <CalendarIconFill />
                                </div>
                            </Col>

                            <Col xs={12}>
                                <StatsCard dollarData={userData?.brand_owner ? userData?.total_commission_spent : userData?.total_commission} showBtn={false} text1="Total amount of" text2="commission earned" lineBreak={true} />
                            </Col>

                            <Col xs={12}>
                                {customTab[userRole]}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default StatisticsAndAnalyticsPage
