import React from 'react'
import ChangeDomainCard from '../components/dashboard/ChangeDomainCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ChangeDomainPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Domain Verification" link3="" link3Text="" link4="" link4Text="" />
            
            <div className='pages change-domain-page'>
                <div className="d-flex align-items-center justify-content-center h-100 pb-3">
                    <ChangeDomainCard />
                </div>
            </div>
        </>
    )
}

export default ChangeDomainPage