import React, { useContext, useEffect, useState } from "react";
import Assets from "../../constants/images";
import { GetDemographics } from "../../services/demographics/demographics";
import { error, success } from "../../constants/msg";
import { UserData } from "../../App";
import { Button, Col, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";
import RollupCardSkeleton from "../skeleton/RollupCardSkeleton";
import { POSTS_STATUS, ROLLUP_STATUS } from "../../constants/app-constants";

const RollUpCard = ({activeTab}) => {
  const [userData, setUserData] = useContext(UserData)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState([])

  useEffect(() => {
    const getRollupData = async () => {
      if (currentPage > 1) {
        setIsLoadMore(true)
      }
      try {
        const params = {
          paginate: 1,
          page: currentPage,
          post_user_id: userData?.id
        }
        const res = await GetDemographics(params)
        setTotalPage(res.data.data.last_page)
        setData(data.concat(res.data.data.data))
        setIsLoading(false)
        setIsLoadMore(false)
      }
      catch (e) {
        setIsLoading(false)
        setIsLoadMore(false)
        error(e.response.data.message)
      }
    }
    if (activeTab == POSTS_STATUS.rollup){
      getRollupData()
    }
  }, [userData, currentPage])

  return (
    <>
      {
        isLoading ?
          <div>
            <RollupCardSkeleton />
          </div>
          :
          data.length <= 0 ?
            <div className='text-center'>No post available</div>
            :
            data?.map((item, index) => (
              <div key={index} className="mb-20">
                <div className="rollup-card">
                  <div className='text-center border-bottom mb-30 pb-30'>
                    <h3>{item?.brand_user?.brand_owner ? item?.brand_user?.brand_owner?.brand_name : item?.brand_user?.shopper?.full_name}</h3>
                  </div>

                  <div className="sub-name-status-div">
                    <div className="d-flex align-items-center">
                      <div className="profile-wrapper">
                        <img src={item?.brand_user?.brand_owner ? item?.brand_user?.brand_owner?.image_url : item?.brand_user?.shopper?.image_url} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                      </div>
                      <h4 className="ms-3">{item?.brand_user?.brand_owner ? item?.brand_user?.brand_owner?.brand_name : item?.brand_user?.shopper?.full_name}</h4>
                    </div>
                    <div className="status-div">
                      <h4 className={`${item?.status === ROLLUP_STATUS.sold ? "color-green" : "color-orange"} ms-3`}>{item?.status === ROLLUP_STATUS.sold ? "Sold" : "Pending"}</h4>
                    </div>
                  </div>

                  <div className="rollup-card-details">
                    <div className="main-rollup-card-img">
                      <img src={item?.product?.image_url} onError={(e) => (e.target.src = Assets.GeneralPlaceholder)} alt="" />
                    </div>

                    <div className="rollup-card-content ms-0 mt-3 mt-sm-0 ms-sm-4">
                      <h4 className="mb-1">{item?.product?.title?.slice(0, 25)}...</h4>
                      <ul>
                        <li>{item?.brand?.company_name}({item?.product?.currency})</li>
                        <li>{`Est. Comm: ${item?.est_commission }`}</li>
                        <li>{`Price: ${item?.product?.price}`}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))
      }
      <Row>
        <Col xs={12} className={`${currentPage == totalPage ? "d-none" : "d-block"}`}>
          {
            isLoadMore ?
              <div className='mt-4'><Loader /></div> : <Button onClick={() => setCurrentPage(currentPage + 1)} className="btn-solid btn-load-more mx-auto mt-4">Load More</Button>
          }
        </Col>
      </Row>
    </>

  );
};

export default RollUpCard;
