import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { error, success } from '../../constants/msg';

const SharePostPopup = ({ sharePostPopup, setSharePostPopup, shareableLink, setShareableLink }) => {
    const copyToClipboard = async () => {
        try {
            navigator.clipboard.writeText(shareableLink)
            success("Copied to clipboard")
        }
        catch (e) {
            error("Something went wrong")
        }
    }

    return (
        <>
            <Modal
                className='theme-popup share-popup'
                show={sharePostPopup}
                onHide={() => setSharePostPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-20 pb-20 border-bottom'>
                            <h3 className='w-100 d-inline-block'>SHARE POST</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div className="wrapper d-flex align-items-center justify-content-sm-between flex-wrap">
                                <FacebookShareButton url={shareableLink} className='mb-2 mx-1' children={<FacebookIcon />} />
                                <LinkedinShareButton url={shareableLink} className='mb-2 mx-1' children={<LinkedinIcon />} />
                                <PinterestShareButton url={shareableLink} className='mb-2 mx-1' children={<PinterestIcon />} />
                                <TelegramShareButton url={shareableLink} className='mb-2 mx-1' children={<TelegramIcon />} />
                                <TwitterShareButton url={shareableLink} className='mb-2 mx-1' children={<TwitterIcon />} />
                                <WhatsappShareButton url={shareableLink} className='mb-2 mx-1' children={<WhatsappIcon />} />
                            </div>

                            <div className='mt-3 copy-wrapper d-flex align-items-center justify-content-between'>
                                <Form.Control className="p-0 input-share d-block w-100" value={shareableLink} type="text" readOnly />
                                <Button className="btn-copy" onClick={copyToClipboard}>Copy</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SharePostPopup