import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PagesSkeleton = () => {
    return (
        <div className="page-content-wrapper mb-4 mb-md-0 ">
            <div className='page-content'>
                <Skeleton style={{ width: "100%" }} count={6.5} height={15} />
            </div>
        </div>
    )
}

export default PagesSkeleton