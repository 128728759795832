import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Times } from '../../constants/svgs'
import Loader from '../Loader/Loader'

const RecentSearchList = ({ isLoading, recentSearchData, clearUserSearch, setSearchText }) => {
    return (
        <div className='suggestion-box'>
            <div className='title-div'>
                <h6>Recent Search</h6>
                <hr />
            </div>
            <div className="suggest-list pt-2">
                <ul>
                    {
                        isLoading ?
                            <li className='text-center'><Loader /></li>
                            :
                            recentSearchData?.length <= 0 ?
                                <li className='text-center'>No resent searches</li>
                                :
                                recentSearchData?.map((search, index) => (
                                    <li key={index} className="d-flex align-items-center justify-content-between">
                                        <h6 className='d-block w-100 text-capitalize' onClick={()=>setSearchText(search?.keywords)}>{search?.keywords}</h6>
                                        <div className='time-icon-wrapper' onClick={() => clearUserSearch(search?.id, index)}>
                                            <Times />
                                        </div>
                                    </li>
                                ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default RecentSearchList