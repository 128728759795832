import React from 'react'
import { GetTokenLocalStorage } from "../services/localStorage/localStorage"
import { Route, Navigate, Outlet, useNavigate } from "react-router";

const PublicRoute = () => {
    const token = GetTokenLocalStorage()

    return (
        !token ? <Outlet/> : <Navigate to="/home" />
    )
}

export default PublicRoute