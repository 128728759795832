import React, { useContext, useEffect, useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { VALIDATIONS_TEXT } from '../../constants/app-constants'
import { GlobeFill } from '../../constants/svgs'
import ChangeDomainPopup from '../popup/ChangeDomainPopup'
import { UserData } from '../../App'

const ChangeDomainCard = () => {
    const { register, handleSubmit, setValue, clearErrors, reset, control, watch, formState: { errors } } = useForm({ mode: "onChange" });
    const [changeDomainPopup, setChangeDomainPopup] = useState(false)
    const [userData, setUserData] = useContext(UserData)

    useEffect(() => {
        reset({
            website_url: userData?.brand_owner?.website_url || ""
        })
    }, [])

    return (
        <>
            <ChangeDomainPopup changeDomainPopup={changeDomainPopup} setChangeDomainPopup={setChangeDomainPopup} asLink={true} />

            <div className='auth-card change-domain-card'>
                <div className='text-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>DOMAIN VERIFICATION</h3>
                </div>

                <div className='content-wrapper'>
                    <small className='text-dark-grey'>Website URL</small>
                    <FloatingLabel className="mt-1" controlId="websiteUrl">
                        <Form.Control
                            readOnly
                            type="text"
                            {...register("website_url",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.WEBSITE_URL_REQUIRED
                                    },
                                    pattern: {
                                        value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                        message: VALIDATIONS_TEXT.INVALID_WEBSITE_URL
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <GlobeFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.website_url && <small className='text-start d-block text-danger mt-1'>{errors.website_url.message}</small>}

                    <div className="box-wrapper mt-40">
                        <div className='box'>
                            <h6 className="mb-1">Product Param Index</h6>
                            <p className="text-dark-grey">{userData?.brand_owner?.product_slug_index || "-"}</p>
                        </div>

                        <div className='box'>
                            <h6 className="mb-1">Cart Page Name</h6>
                            <p className="text-dark-grey">{userData?.brand_owner?.cart_page || "-"}</p>
                        </div>

                        <div className='box'>
                            <h6 className="mb-1">Cart Page Param</h6>
                            <p className="text-dark-grey">{userData?.brand_owner?.cart_page || "-"}</p>
                        </div>

                        <div className='box'>
                            <h6 className="mb-1">Thank You Page Name</h6>
                            <p className="text-dark-grey">{userData?.brand_owner?.thank_you_page || "-"}</p>
                        </div>

                        <div className='box'>
                            <h6 className="mb-1">Thank You Page Param Index</h6>
                            <p className="text-dark-grey">{userData?.brand_owner?.thank_you_page_index || "-"}</p>
                        </div>

                        <div className='box'>
                            <h6 className="mb-1">Product Param Index</h6>
                            <p className="text-dark-grey">{userData?.brand_owner?.product_slug_index || "-"}</p>
                        </div>
                    </div>

                    <Button className="btn-solid w-100 mt-40 mb-40" onClick={() => setChangeDomainPopup(true)}>Change domain</Button>
                </div>
            </div>
        </>
    )
}

export default ChangeDomainCard