import React from 'react'
import CreatePostForm from '../components/forms/CreatePostForm'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const CreatePostPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Create Post" link3="" link3Text="" link4="" link4Text="" />
            <div className='pages create-post-page'>
                <div className="d-flex align-items-center justify-content-center h-100 pb-3">
                    <CreatePostForm />
                </div>
            </div>
        </>
    )
}

export default CreatePostPage