import React, { useEffect, useContext } from 'react'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import PublicRoute from './PublicRoute'
import ProtectedRoute from './ProtectedRoute'
import AuthLayout from '../layouts/authentication/AuthLayout'
import SplashForm from '../components/authentication/SplashForm'
import SelectRoleForm from '../components/authentication/SelectRoleForm'
import SignupShopperForm from '../components/authentication/SignupShopperForm'
import SignupBrandForm from '../components/authentication/SignupBrandForm'
import SigninForm from '../components/authentication/SigninForm'
import ForgotPasswordForm from '../components/authentication/ForgotPasswordForm'
import ResetPasswordForm from '../components/authentication/ResetPasswordForm'
import VerificationCodeForm from '../components/authentication/VerificationCodeForm'
import PasswordVerificationForm from '../components/authentication/PasswordVerificationForm'
import DashboardLayout from '../layouts/dashboard/DashboardLayout'
import HomePage from '../pages/HomePage'
import CreatePostPage from '../pages/CreatePostPage'
import AddPostPage from '../pages/AddPostPage'
import PostDetailPage from '../pages/PostDetailPage'
import ViewProfilePage from '../pages/ViewProfilePage'
import EditProfilePage from '../pages/EditProfilePage'
import CreatePostForm from '../components/forms/CreatePostForm'
import BlockedUserPage from '../pages/BlockedUserPage'
import ChangePasswordPage from '../pages/ChangePasswordPage'
import SettingSidebar from '../components/dashboard/SettingSidebar'
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import BrandPage from '../pages/BrandPage'
import BrandDetailPage from '../pages/BrandDetailPage'
import AboutPage from '../pages/AboutPage'
import ContactPage from '../pages/ContactPage'
import ViewMyProfileCard from '../components/dashboard/ViewMyProfileCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import StatisticsAndAnalyticsPage from '../pages/StatisticsAndAnalyticsPage'
import RecentActivityPage from '../pages/RecentActivityPage'
import MyFavouritesPage from '../pages/MyFavouritesPage'
import EditPostPage from '../pages/EditPostPage'
import ViewMyProfilePage from '../pages/ViewMyProfilePage'
import BrandPostDetailPage from '../pages/BrandPostDetailPage'
import MyFavouritesPostPage from '../pages/MyFavouritesPostPage'
import RecentActivityPostPage from '../pages/RecentActivityPostPage'
import SchedulePostPage from '../pages/SchedulePostPage'
import SchedulePostDetailPage from '../pages/SchedulePostDetailPage'
import BrandPostListPage from '../pages/BrandPostListPage'
import ProfilePostListPage from '../pages/ProfilePostListPage'
import MyProfilePostDetailPage from '../pages/MyProfilePostDetailPage'
import { ROLES } from "../constants/app-constants"
import { UserData, UserRole } from "../App"
import EditPostForm from '../components/forms/EditPostForm'
import RollUpCard from '../components/dashboard/RollUpCard'
import ErrorPage from '../pages/ErrorPage'
import ShopperPostListPage from '../pages/ShopperPostListPage'
import StatisticsAndAnalyticsPostDetailPage from '../pages/StatisticsAndAnalyticsPostDetailPage'
import CollectionPrivacyPage from '../pages/CollectionPrivacyPage'
import DeleteAccountPage from '../pages/DeleteAccountPage'
import PaymentManagementPage from '../pages/PaymentManagementPage'
import WalletPage from '../pages/WalletPage'
import GenerateTokenForm from '../components/forms/GenerateTokenForm'
import VerifyDomainForm from '../components/forms/VerifyDomainForm'
import CodeVerifyForm from '../components/forms/CodeVerifyForm'
import DomainInformationForm from '../components/forms/DomainInformationForm'
import ChangeDomainPage from '../pages/ChangeDomainPage'
import DomainVerifyRoute from './DomainVerifyRoute'
import TopupForm from '../components/forms/TopupForm'
import ViewAllMessagePage from '../pages/ViewAllMessagePage'
import ExtensionPage from "../pages/ExtensionPage";

const Navigation = () => {
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate, location])

    // Signup component which i have to show on signup A/C to user role
    const signUp = {
        [ROLES.brand]: <SignupBrandForm isSetupProfile={false} />,
        [ROLES.shopper]: <SignupShopperForm />,
    }

    return (
        <Routes>
            {/* ************** PUBLIC OR AUTHENTICATION ROUTES ************** */}
            <Route element={<PublicRoute />}>
                <Route path='/' element={<AuthLayout children={<SplashForm />} />}></Route>
                <Route path='/select-role' element={<AuthLayout children={<SelectRoleForm />} />}></Route>
                <Route path='/signup' element={<AuthLayout children={signUp[userRole]} />}></Route>
                <Route path='/signin' element={<AuthLayout children={<SigninForm />} />}></Route>
                <Route path='/forgot-password' element={<AuthLayout children={<ForgotPasswordForm />} />}></Route>
                <Route path='/reset-password' element={<AuthLayout children={<ResetPasswordForm />} />}></Route>
                <Route path='/email-verification' element={<AuthLayout children={<VerificationCodeForm />} />}></Route>
                <Route path='/password-verification' element={<AuthLayout children={<PasswordVerificationForm />} />}></Route>

                <Route path='/terms-and-conditions' element={<AuthLayout children={<TermsAndConditionsPage showBreadcrumb={false} />} />}></Route>
                <Route path='/privacy-policy' element={<AuthLayout children={<PrivacyPolicyPage showBreadcrumb={false} />} />}></Route>

            </Route>


            {/* ************** PROTECTED OR DASHBOARD ROUTES ************** */}
            <Route element={<ProtectedRoute />}>
                <Route path='/home' element={<DashboardLayout children={<HomePage />} />}></Route>
                <Route path='/payment-management' element={<DashboardLayout children={<PaymentManagementPage />} />}></Route>
                <Route path='/create-post' element={<DashboardLayout children={<CreatePostPage />} />}></Route>
                <Route path='/edit-post/:slug' element={<DashboardLayout children={<EditPostPage />} />}></Route>
                <Route path='/add-post' element={<DashboardLayout children={<AddPostPage />} />}></Route>
                <Route path='/statistics-and-analytics' element={<DashboardLayout children={<StatisticsAndAnalyticsPage />} />}></Route>
                <Route path='/wallet' element={<DashboardLayout children={<WalletPage />} />}></Route>
                <Route path='/statistics-and-analytics/post/:slug' element={<DashboardLayout children={<StatisticsAndAnalyticsPostDetailPage />} />}></Route>
                <Route path='/post/:slug' element={<DashboardLayout children={<PostDetailPage />} />}></Route>
                <Route path='/user/:slug' element={<DashboardLayout children={<ViewProfilePage />} />}></Route>
                <Route path='/edit-profile' element={<DashboardLayout children={<EditProfilePage />} />}></Route>
                <Route path='/my-profile' element={<DashboardLayout children={<ViewMyProfilePage />} />}></Route>
                <Route path='/my-profile/post/:slug' element={<DashboardLayout children={<MyProfilePostDetailPage />} />}></Route>
                <Route path='/my-profile/user/:user_slug/posts/:post_slug' element={<DashboardLayout children={<ProfilePostListPage />} />}></Route>
                <Route path='/user/:user_slug/posts/:post_slug' element={<DashboardLayout children={<ShopperPostListPage />} />}></Route>
                <Route path='/brand/post/:slug' element={<DashboardLayout children={<BrandPostDetailPage />} />}></Route>
                <Route path='/brand/:user_slug/posts/:post_slug' element={<DashboardLayout children={<BrandPostListPage />} />}></Route>
                <Route path='/brands' element={<DashboardLayout children={<BrandPage />} />}></Route>
                <Route path='/brand/:slug' element={<DashboardLayout children={<BrandDetailPage />} />}></Route>
                <Route path='/about' element={<DashboardLayout children={<AboutPage />} />}></Route>
                <Route path='/contact' element={<DashboardLayout children={<ContactPage />} />}></Route>
                <Route path='/extension' element={<DashboardLayout children={<ExtensionPage />} />}></Route>
                <Route path='/my-favourites' element={<DashboardLayout children={<MyFavouritesPage />} />}></Route>
                <Route path='/my-favourites/post/:slug' element={<DashboardLayout children={<MyFavouritesPostPage />} />}></Route>
                <Route path='/recent-activity' element={<DashboardLayout children={<RecentActivityPage />} />}></Route>
                <Route path='/recent-activity/post/:slug' element={<DashboardLayout children={<RecentActivityPostPage />} />}></Route>
                <Route path='/schedule-post' element={<DashboardLayout children={<SchedulePostPage />} />}></Route>
                <Route path='/schedule-post/:slug' element={<DashboardLayout children={<SchedulePostDetailPage />} />}></Route>
                <Route path='/blocked-users' element={<DashboardLayout children={<BlockedUserPage />} />}></Route>
                <Route path='/settings/change-password' element={<DashboardLayout children={<ChangePasswordPage children={<SettingSidebar />} />} />}></Route>
                <Route path='/settings/collection-privacy' element={<DashboardLayout children={<CollectionPrivacyPage children={<SettingSidebar />} />} />}></Route>
                <Route path='/settings/terms-and-conditions' element={<DashboardLayout children={<TermsAndConditionsPage showBreadcrumb={true} children={<SettingSidebar />} />} />}></Route>
                <Route path='/settings/privacy-policy' element={<DashboardLayout children={<PrivacyPolicyPage showBreadcrumb={true} children={<SettingSidebar />} />} />}></Route>
                <Route path='/delete-account' element={<DashboardLayout children={<DeleteAccountPage />} />}></Route>
                <Route path='/view-all-messages' element={<DashboardLayout children={<ViewAllMessagePage />} />}></Route>
                <Route path='/view-all-messages/:slug' element={<DashboardLayout children={<ViewAllMessagePage />} />}></Route>
               
                <Route path='/profile-setup' element={<AuthLayout children={<SignupBrandForm isSetupProfile={true} />} />}></Route>
               </Route>


            {/* ************** PROTECTED OR DOMAIN VERIFICATION ROUTES ************** */}
            
            <Route element={<ProtectedRoute />}>
                <Route path='/change-domain' element={<DashboardLayout children={<ChangeDomainPage />} />}></Route>
                <Route path='/generate-token' element={userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 1 ? <DashboardLayout children={<GenerateTokenForm withBreadcrumb={true} />} /> : <AuthLayout children={<GenerateTokenForm withBreadcrumb={false} />} />}></Route>
                <Route path='/verify-domain' element={userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 1 ? <DashboardLayout children={<VerifyDomainForm withBreadcrumb={true} />} /> : <AuthLayout children={<VerifyDomainForm withBreadcrumb={false} />} />}></Route>
                <Route path='/domain-information' element={userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 1 ? <DashboardLayout children={<DomainInformationForm withBreadcrumb={true} />} /> : <AuthLayout children={<DomainInformationForm withBreadcrumb={false} />} />}></Route>
                <Route path='/verify-code' element={userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 1 ? <DashboardLayout children={<CodeVerifyForm withBreadcrumb={true} />} /> : <AuthLayout children={<CodeVerifyForm withBreadcrumb={false} />} />}></Route>
                <Route path='/topup' element={userData?.brand_owner && userData?.brand_owner?.is_domain_verify == 1 ? <DashboardLayout children={<TopupForm withBreadcrumb={true} />} /> : <AuthLayout children={<TopupForm withBreadcrumb={false} />} />}></Route>
            </Route>


            {/* ******* NOTE: PLACE ALL ROUTES BEFORE ERROR ROUTE ******* */}
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
}

export default Navigation