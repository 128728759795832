import React, { useContext, useEffect, useState } from 'react'
import Assets from '../../constants/images'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { UserData } from '../../App'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase'

const MessageThreadCard = ({ data, setSelectedChatUser }) => {
    const [userData, setUserData] = useContext(UserData)

    const updateMsgCount = async () => {
        try {
            const channel_id = userData?.id + "-" + data?.id
            console.log(channel_id, "channel_id")
            const chatRef = doc(db, 'chats', channel_id)

            await updateDoc(chatRef, {
                message_count: 0
            })
        }
        catch (e) {
            console.log("error", e)
        }
    }

    return (
        <div className="user-thread box border-bottom mt-3 pb-3">
            <NavLink to={`/view-all-messages/${userData?.id}-${data?.id}`} onClick={() => {
                updateMsgCount()
                if (setSelectedChatUser) {
                    setSelectedChatUser(data)
                }
            }}>
                <div className="d-flex align-items-center">
                    <div className="profile-wrapper me-2">
                        <img src={data?.channel_user_profile_image ? data?.channel_user_profile_image : Assets.ProfilePlaceholder} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                    </div>

                    <div className="custom-position text-end w-100">
                        <small>{moment.unix(data?.created_at?.seconds).fromNow()}</small>
                        <div className="text-start">
                            <h6 className='text-capitalize'>{data?.channel_user_name}</h6>
                            <div className="d-flex align-items-center justify-content-between">
                                <p className='wrap text-grey'>{data?.message}</p>
                                {/* <div className="count">{data?.message_count}</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default MessageThreadCard