import React, { useEffect, useContext, useState } from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { UserData, UserRole } from '../../App'
import Assets from '../../constants/images'
import { EmptyLocalStorage } from '../../services/localStorage/localStorage'
import { DeleteAccount } from '../../services/users/users'
import Loader from '../Loader/Loader'
import { Controller, useForm } from 'react-hook-form'
import { success, error } from '../../constants/msg'

const DeleteAccountPopup = ({ deleteAccountPopup, setDeleteAccountPopup }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [isDisabled, setIsDisabled] = useState(false)
    const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const deleteAccount = async () => {
        // Delete user account and clear localStorage and Contexts
        setIsDisabled(true)

        try {
            const res = await DeleteAccount(userData.id)
            setIsDisabled(false)
            success(res.data.message)
            setDeleteAccountPopup(false)

            setTimeout(() => {
                setUserData(null)
                setUserRole(null)
                EmptyLocalStorage()
                navigate("/")
            }, 1500);
        }
        catch (e) {
            error(e.response.data.message)
            setIsDisabled(false)
        }
    }

    const onSubmit = (data) => {
        if (data.delete_confirm == "PERMANENTLY DELETE") {
            deleteAccount()
        }
        else {
            error("Please type 'PERMANENTLY DELETE'")
        }
    }

    useEffect(() => {
        reset({ delete_confirm: "" })
    }, [deleteAccountPopup])

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup delete-account-popup'
                show={deleteAccountPopup}
                onHide={() => setDeleteAccountPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='text-center mb-30 pb-30 border-bottom'>
                                <h3 className='w-100 d-inline-block'>DELETE ALERT</h3>
                            </div>

                            <div className='content-container text-center'>
                                <div>
                                    <p>
                                        Are you sure, that you want to delete <br />
                                        your The Link Front account?
                                    </p>
                                </div>

                                <div className="mt-20 text-start">
                                    <label htmlFor="reason">Enter <span>PERMANENTLY DELETE</span> to confirm:</label>
                                    <Form.Control
                                        className="mt-2"
                                        id="reason"
                                        placeholder="Enter here"
                                        {...register("delete_confirm",
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Required"
                                                },
                                            })
                                        }
                                    />
                                    {errors.delete_confirm && <small className='text-start d-block text-danger mt-1'>{errors.delete_confirm.message}</small>}
                                </div>

                                <div className="mt-5">
                                    <Button type="submit" className="w-100 btn-solid mb-2">
                                        {isDisabled ? <Loader /> : "DELETE ACCOUNT"}
                                    </Button>

                                    <Button className="w-100 btn-transparent" onClick={() => setDeleteAccountPopup(false)}>CANCEL</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteAccountPopup