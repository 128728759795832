import React, { useEffect, useContext, useState } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import SelectImageCard from "../components/dashboard/SelectImageCard"
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import AddFolderPopup from "../components/popup/AddFolderPopup"
import { POPUP_TYPE } from '../constants/app-constants'
import { useLocation } from 'react-router-dom'
import { AddProductContext } from "../App"
import { GetFolders } from '../services/folders/folder'
import { error } from '../constants/msg'

const AddPostPage = () => {
    const location = useLocation()
    const [isFolderLoading, setIsFolderLoading] = useState(true)
    const [folders, setFolders] = useState([])
    const [selectedFolder, setSelectedFolder] = useState({
        id: "",
        index: 0
    });

    useEffect(() => {
        const getFolders = async () => {
            // get folders of user
            try {
                const res = await GetFolders()
                setFolders(res.data.data.data)
                setSelectedFolder({
                    id: res.data.data.data[0].id,
                    index: 0
                })
                setIsFolderLoading(false)
            }
            catch (e) {
                setIsFolderLoading(false)
                error(e.response.data.message)
            }
        }
        getFolders()
    }, [])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Create Post" link3="" link3Text="" link4="" link4Text="" popupType={POPUP_TYPE.folder} folders={folders} setFolders={setFolders} setSelectedFolder={setSelectedFolder} setIsFolderLoading={setIsFolderLoading} />
            <div className='pages add-post-page'>
                <div className="pb-4">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <AddedPostCard prevData={location.state} folders={folders} isFolderLoading={isFolderLoading} setIsFolderLoading={setIsFolderLoading} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default AddPostPage