import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DemographicsCardSkeleton = () => {
    return (
        <>
            {
                Array(5).fill(null)?.map((data, index) => (
                    <div key={index} className="box border-bottom py-3 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="me-2">
                                <Skeleton width={40} height={40} circle={true} />
                            </div>
                            <Skeleton count={1} width={80} />
                        </div>

                        <div>
                            <Skeleton count={1} width={75}  />
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default DemographicsCardSkeleton