import React, { useState, useContext, useEffect } from 'react'
import { UserData, UserRole } from '../../App'
import { error } from '../../constants/msg'
import { DollarRounded } from '../../constants/svgs'
import { GetUser } from '../../services/users/users'
import Skeleton from 'react-loading-skeleton'
import { Button } from 'react-bootstrap'
import WithdrawlPopup from '../popup/WithdrawlPopup'
import { roles } from '../../utils/constants'
import TopupPopup from '../popup/TopupPopup'

const StatsCard = ({fetchData, dollarData, showBtn, text1, text2, lineBreak }) => {
    const [userRole, setUserRole] = useContext(UserRole)
    const [userData, setUserData] = useContext(UserData)
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [withdrawlPopup, setWithdrawlPopup] = useState(false)
    const [topupPopup, setTopupPopup] = useState(false)

    const handleClick = () => {
        if (userRole === roles.shopper) {
            setWithdrawlPopup(true)
        }
        else {
            setTopupPopup(true)
        }
    }

    useEffect(() => {
        const getProfile = async () => {
            // fetch profile of current login user
            try {
                const res = await GetUser(userData.id)
                setData(res.data.data.user)
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                error(e.response.data.message)
            }
        }

        getProfile()
    }, [])

    return (
        <>
            <WithdrawlPopup withdrawlPopup={withdrawlPopup} setWithdrawlPopup={setWithdrawlPopup} fetchData={fetchData} />
            <TopupPopup topupPopup={topupPopup} setTopupPopup={setTopupPopup} fetchData={fetchData} />

            <div className='stats-card'>
                <div className="wrapper p-4">
                    <div className="box d-flex justify-content-center align-items-center p-3 ">
                        <div className="icon-wrapper d-flex align-items-center justify-content-center me-3">
                            <DollarRounded />
                        </div>
                        <div>
                            <span>{text1} {lineBreak && <br />} {text2}</span>
                            {
                                isLoading ? <div className='m-0 p-0'><Skeleton count={1} height={22} width={80} /></div> : <h1>${dollarData || 0}</h1>
                            }
                        </div>
                    </div>

                    <div className={showBtn === true ? "mt-30 d-block" : "d-none"}>
                        <Button onClick={handleClick} className="btn-solid">
                            {userRole === roles.shopper ? "Withdraw" : "Top Up"}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatsCard